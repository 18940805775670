import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { DatesService } from '../../services/dates.service';

@Component({
  selector: 'app-select-month',
  templateUrl: './select-month.component.html',
  styleUrls: ['./select-month.component.scss']
})
export class SelectMonthComponent implements OnInit {

	@Input('date') date : Date;

	@Input('confirm') confirm : Function;
	@Input('close') close : Function;
	@Input('errorMessage') errorMessage : string;

	@Input() loading : boolean;
	@Output() loadingChange = new EventEmitter<boolean>();

	setLoading(value: boolean) {
		this.loading = value;
		this.loadingChange.emit(this.loading);
	}


	form : FormGroup;


	years : string[] = [];
	monthsNames : string[] = [];


	constructor(private formBuilder: FormBuilder,
				private datesService: DatesService) {

	}

	ngOnInit() {

		this.form = this.formBuilder.group({
			month: [this.date.getMonth(), [Validators.required]],
			year: [this.date.getFullYear().toString(), [Validators.required]]
		});

		this.monthsNames = this.datesService.months;

		const currentYear = (new Date()).getFullYear();

		for (var i = 1990; i <= currentYear; i++)
			this.years.push(i.toString());

	}


	yearChanged(event) {

		this.form.get('year').setValue(this.years[event]);
	}

	monthChanged(event) {

		this.form.get('month').setValue(event);
	}


	save() {

		if (this.form.invalid)
		{
			Object.keys(this.form.controls).forEach(key => {
				this.form.controls[key].markAsTouched();
			});
			
			return;
		}

		var newDate = new Date();

		newDate.setFullYear(parseInt(this.form.get('year').value));
		newDate.setMonth(this.form.get('month').value);

		this.confirm(newDate);

		this.close();
	}

}
