<mat-card class="custum-card">

	<h1>ORDENES DE PAGO</h1>

	<!-- <app-navbar class="full-width" bgcolor="primary"></app-navbar> -->


	<div class="custum-table" fxLayout="column">
			

			<br *ngIf="loading" />


			<p class="empty-text" *ngIf="!loading && dataSource.data?.length < 1">
				No hay órdenes de pago generadas
			</p>

			<mat-spinner *ngIf="loading" fxFlexAlign="center" color="primary" diameter="40"></mat-spinner>


			<table [ngClass]="{'hidden': loading || dataSource.data?.length < 1}" mat-table matSort 
				[dataSource]="dataSource" class="full-width" multiTemplateDataRows>

				<ng-container matColumnDef="nombre_fiscal">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</th>
					<td mat-cell *matCellDef="let order">
						{{order?.purchasesData[0].nombre_fiscal}}
					</td>
				</ng-container>

				<ng-container matColumnDef="date">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
					<td mat-cell *matCellDef="let order">
						{{stringDate(order?.date)}}
					</td>
				</ng-container>

				<ng-container matColumnDef="purchases">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Facturas</th>
					<td mat-cell *matCellDef="let order">
						{{order?.purchases.length}}
					</td>
				</ng-container>

				<ng-container matColumnDef="amount">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Monto</th>
					<td mat-cell *matCellDef="let order">
						{{moneda}} {{numbersService.fixedNumber(order?.amount)}}
					</td>
				</ng-container>

				<ng-container matColumnDef="status">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
					<td mat-cell *matCellDef="let order">
						<span class="redChip" *ngIf="ordersService.orderStatus(order) == 'Pago pendiente'">
							{{ordersService.orderStatus(order)}}
						</span>
						<span class="orangeChip" *ngIf="ordersService.orderStatus(order) == 'Pago en revisión'">
							{{ordersService.orderStatus(order)}}
						</span>
						<span class="greenChip" *ngIf="ordersService.orderStatus(order) == 'Pagado'">
							{{ordersService.orderStatus(order)}}
						</span>
						<span class="greyChip"
							*ngIf="ordersService.orderStatus(order) != 'Pago pendiente' && ordersService.orderStatus(order) != 'Pago en revisión' && ordersService.orderStatus(order) != 'Pagado'">
							{{ordersService.orderStatus(order)}}
						</span>
						
						
					</td>
				</ng-container>


				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let order" class="last-column">

						<app-icon (click)="goToPage($event, order?.id)" icon="search_primary" size="16" class="clickable"></app-icon>
					</td>
				</ng-container>



				<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row *matRowDef="let order; columns: displayedColumns;" class="element-row"></tr>

			</table>


			<mat-paginator fxFlexAlign="end" [ngClass]="{'hidden': loading || dataSource.data?.length < 1}" [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="pageSize" [pageIndex]="page"></mat-paginator>

	</div>

</mat-card>