import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivationEnd } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { AuthService, AuthUserData, LoginStatus } from '../services/auth.service';
import { User, UserData } from '../classes/user';
import { ValidatorsService } from '../services/validators.service';
import { UsersService } from '../services/users.service';
import { RouterService } from '../services/router.service';
import { ApiService } from '../services/api.service';
import { Subscription } from 'rxjs';
import { Page } from '../adapters/page';
import { SnackbarService } from '../services/snackbar.service';



@Component({
	selector: 'app-signup',
	templateUrl: './signup.component.html',
	styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

	year: number = (new Date()).getFullYear();

	method: string;
	register: boolean = true;
	loading: boolean = true;;
	validPass: boolean = false
	form: FormGroup;
	disabled: boolean = true;
	passForm: FormGroup
	firstPass: string = '';
	secondPass: string = '';
	param: string = 'validatePass'
	show: any = { password: false, repeat: false };
	errorCodig: boolean = false;


	constructor(private formBuilder: FormBuilder,
		private validatorsService: ValidatorsService,
		private usersService: UsersService,
		private api: ApiService,
		private routerService: RouterService,
		public authService: AuthService,
		private page: Page,
		private snackbar: SnackbarService) {

		this.page.actionBarHidden = true;

		this.loading = true;

		this.router.events.subscribe(event => {

			if (event instanceof ActivationEnd) {
				if (event.snapshot.url && event.snapshot.url.length > 0) {
					this.method = event.snapshot.url[0].path;
				}
				else {
					this.method = 'signup';
				}

				this.createForm();
			}
		});
	}

	get router() { return this.routerService.router; };

	ngOnInit() {

	}
	passwordValidator(control: AbstractControl): { [key: string]: boolean } | any {
		const value: string = control.value;
		const hasNumber = /\d/.test(value);
		const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(value)
		if (!hasNumber || !hasSymbol) {
			this.register = true;
			return { 'validatePass': true }
		}
		this.register=false
		return null
	}

	createForm() {

		if (this.method == 'signup') {
			this.form = this.formBuilder.group({
				code: ['', [Validators.required]],
				email: ['', [Validators.required, Validators.email]],
				username: ['', [Validators.required, this.validatorsService.textPattern]],
				securePass:['',[Validators.required, this.passwordValidator.bind(this)]],
				repeat: ['', [Validators.required]]
			});

			this.form.get('repeat').setValidators([
				Validators.required,
				this.validatorsService.checkPasswords(this.form.get('securePass'))
			]);
		}
		else {
			this.form = this.formBuilder.group({
				code: ['', [Validators.required]]
			});
		}

		this.loading = false;
	}


	toggleShow(event, field: string) {

		if (event.stopPropagation) event.stopPropagation();
		if (event.preventDefault) event.preventDefault();

		this.show[field] = this.show[field] != true;
	}
	validatePass() {
		try {
			this.validPass = false
			if (this.firstPass.length < 8 && this.firstPass.length > 1) {
				console.log("almenis 8")
				return "contraseña de al menos 8 caracteres"
			}
			if (this.firstPass.length >= 8) {
				this.validPass = true;
				return ""
			}
		} catch (error) {
			console.log(error)
		}
	}
	matchPass() {
		try {
			console.log("matchPass", this.firstPass, this.secondPass)
		if (this.firstPass == this.secondPass && this.secondPass.length >= 8 && this.register == false) {
			this.disabled = false
			return "contraseñas válidas"
		}else if(this.firstPass != this.secondPass && this.secondPass.length > 7 ) {
			this.disabled = true
			return "contraseñas inválidas"
		}
		} catch (error) {
			console.log(error)
		}
	}



	ngOnDestroy() {

	}


	save() {

		console.log("save 1")

		if (this.form.invalid) {
			console.log("invalid", this.form)
			Object.keys(this.form.controls).forEach(key => {
				this.form.controls[key].markAsTouched();
			});

			return;
		}
		console.log("save 11111")
		this.loading = true;


		const code = this.form.get('code').value;


		this.api.companyAnonymous(code).then(result => {
			console.log("save 2", result)

			if (!result) {
				this.form.get('code').setErrors({ invalid: true });
				this.errorCodig = true
				this.loading = false;
				return;
			}

			if (this.method == 'signup') {
				console.log("save 3")
				this.formSignup();
				return;
			}
			else {
				this.facebookSignUp()
				return;
			}

		}).catch(reason => {
			console.log("error code", reason)
			this.form.get('code').setErrors({ invalid: true });
			this.errorCodig = true;
			this.loading = false;

			//this.authService.errorMessage = "Código de cliente es invalido";
			//this.authService.loginStatus = LoginStatus.Error;

			return;
		});
	}


	formSignup() {
		console.log("save 44")
		this.authService.emailSignUp(
			this.form.get('username').value + '@multigroup-sa.web.app',
			this.form.get('securePass').value,
			false
		).then((result) => {
			console.log("save 5")
			this.complete();
			return

		}).catch(reason => {

			this.loading = false;
			return
		});
	}


	facebookSignUp() {

		if (this.authService.user == null) {
			this.authService.errorMessage = "No iniciaste sesión";
			this.authService.loginStatus = LoginStatus.Error;
		}

		const user: User = { ...this.authService.user };

		user.code = this.form.get('code').value;

		this.usersService.set(user, true).then(result => {

			this.authService.userBS.next(user);

			//this.router.navigate(['/']);

		}).catch(reason => {

			this.loading = false;

		});
	}





	complete(userData?: AuthUserData) {

		var user: UserData;

		console.log("hola")

		if (userData) {
			user = { ...userData, code: this.form.get('code').value, role: "pagos" };
			console.log("hola11", user)
		}
		else {
			user = {
				email: this.form.get('email').value,
				username: this.form.get('username').value,
				code: this.form.get('code').value
			}
			console.log("hola22", user)
		}

		this.authService.register(user).then(result => {

			if (result == "success") {
				this.loading = false;
				this.snackbar.show("Usuario Creado Correctamente");
				this.router.navigate(['/']);
			}
			else {
				this.loading = false;
			}

		}).catch(reason => {

			this.loading = false;
		});

		return
	}
}
