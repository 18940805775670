import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {

	@Input('icon') icon : string;
	@Input('size') size : number = 24;

	@Input('sizeUnit') sizeUnit : string = 'px';

	constructor() {

	}

	ngOnInit() {

	}

}
