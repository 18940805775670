
<div class="home" fxLayout="column">


	<!-- <app-navbar class="full-width" bgcolor="primary"></app-navbar> -->


	<div ngClass.gt-xs="content" ngClass.xs="full-width" fxLayout="column" fxLayoutAlign="start center">

		<mat-card fxLayout="column">

			<div class="profile-header" fxLayout="column" fxLayoutAlign="center center">

				<div class="photo-box" fxLayout="column" fxLayoutAlign="center center">

					<div class="photo" fxLayout="row" fxLayoutAlign="center center" [ngStyle]="{'background-image' : (!uploading && photoUrl != '' && photoUrl != null ? 'url('+photoUrl+')' : 'none')}">
					</div>

					<mat-spinner *ngIf="uploading" class="spinner" diameter="40" color="primary"></mat-spinner>

					<button *ngIf="!uploading" mat-icon-button (click)="imageInputClick()" fxLayout="column" fxLayoutAlign="center center">
						<mat-icon>edit</mat-icon>
					</button>

					<input #imageInput (change)="uploadImage($event)" class="hidden" hidden="true" type="file" accept=".png,.jpg" />
				</div>
			</div>



			<div fxFlex class="profile-content">

				<div class="profile-details" ngClass.xs="profile-details"  fxLayoutGap="1em">
					
					<div class="font-style" fxLayout="row" fxLayoutGap="0.15em">
						<strong>NOMBRE:</strong>
						<p>{{authService.user?.name || '-'}}</p>
					</div>

					<div class="font-style" fxLayout="row" fxLayoutGap="0.15em">
						<strong>USUARIO:</strong>
						<p>{{authService.user?.username || '-'}}</p>
					</div>

					<div class="font-style" fxLayout="row" fxLayoutGap="0.15em">
						<strong>EMAIL:</strong>
						<p>{{authService.user?.email || '-'}}</p>
					</div>

					<div class="font-style" fxLayout="row" fxLayoutGap="0.15em">
						<strong>TELÉFONO:</strong>
						<p>{{authService.user?.phone || '-'}}</p>
					</div>

					<div class="font-style" fxLayout="row" fxLayoutGap="0.15em">
						<strong>DIRECCIÓN:</strong>
						<p>{{authService.user?.address || '-'}}</p>
					</div>

					<div class="font-style" fxLayout="row" fxLayoutGap="0.15em">
						<strong>CÓDIGO DE CLIENTE:</strong>
						<p>{{authService.user?.code || '-'}}</p>
					</div>

				</div>

				<div  class="button-profile" *ngIf="user?.username" >
					<button mat-button color="primary" (click)="resetPasswordDialog()"><img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">Cambiar contraseña</button>
					<button mat-flat-button color="primary" (click)="editProfileDialog()"><img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">EDITAR PERFIL</button>
				</div>

			</div>
			

		</mat-card>

	</div>

</div>