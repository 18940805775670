import { EventEmitter, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { RouterService } from '../services/router.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	navchange: EventEmitter<number> = new EventEmitter();
	constructor(private authService: AuthService,
				private routerService: RouterService) {

	};
	getNavChangeEmitter() {
		return this.navchange;
	  }

	get router() {return this.routerService.router;}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		this.navchange.emit(next.url.length);
		var route = next.url && next.url.length > 0 ? next.url[0].path : '';

		var admin = next.data["admin"] as boolean;
		var customer = next.data["customer"] as boolean;

		var _module = next.data["module"] as string;

		var promise = new Promise<boolean>((resolve, reject) => {

			this.authService.getCurrentUser().then(user => {

				if (user)
				{
					if (route == "login" || route == "signup")
					{
						this.refuse(false, '/');
						resolve(false);
					}
					else if (route == "fb_signup")
					{
						if (user.code != null && user.code != '')
						{
							this.refuse(false, '/');
							resolve(false);
						}
						else
						{
							resolve(true);
						}
					}
					else if (route == "message")
					{
						if (user.active == true)
						{
							this.refuse(false, '/');
							resolve(false);
						}
						else
						{
							resolve(true);
						}
					}
					else
					{
						if (this.authService.loginNextRoute != null)
						{
							const nextRoute = this.authService.loginNextRoute;
							this.authService.loginNextRoute = null;

							this.refuse(false, nextRoute);
						}
						else if (admin && !user.admin)
						{
							this.refuse(false, '/');
							resolve(false);
						}
						else if (customer && user.admin)
						{
							this.refuse(false, '/admin');
							resolve(false);
						}
						else
						{
							if (user.admin || (customer && user.role == 'admin'))
							{
								resolve(true);
							}
							else if (user.code != null && user.code != '')
							{
								if (user.active == true)
								{
									if (customer && _module)
									{
										if (user.modules != null && user.modules.indexOf(_module) >= 0)
										{
											resolve(true);
										}
										else
										{
											this.refuse(true, '/');
											resolve(false);
										}
									}
									else
									{
										resolve(true);
									}
								}
								else
								{
									this.refuse(false, '/message');
									resolve(false);
								}
							}
							else
							{
								this.refuse(false, '/fb_signup');
								resolve(false);
							}
						}
					}
				}
				else
				{
					if (route == "login" || route == "signup")
					{
						resolve(true);
					}
					else
					{
						var nextRoute : string = null;

						if (next.url && next.url.length > 0)
						{
							nextRoute = '';

							for (var segment of next.url)
							{
								nextRoute += '/' + segment.path;
							}
						}

						this.authService.loginNextRoute = nextRoute;

						this.refuse(false, '/login');
						resolve(false);
					}
				}

			});
		});

		return promise;
	}



	refuse(showAlert: boolean, route?: string) : void {

		if (showAlert == true)
			alert("Acceso denegado");

		var _route : string = route ? route : '/';

		this.router.navigate([_route]);
	}
}





