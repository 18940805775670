import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserData, User } from '../../classes/user';
import { ValidatorsService } from '../../services/validators.service';
import { AuthService } from '../../services/auth.service';
import { UsersService } from '../../services/users.service';
import { DialogService } from '../../services/dialog.service';
import { ApiService } from '../../services/api.service';


@Component({
  selector: 'app-new-customer',
  templateUrl: './new-customer.component.html',
  styleUrls: ['./new-customer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewCustomerComponent implements OnInit {

	@Input('user') user : User;

	@Input('confirm') confirm : Function;
	@Input('close') close : Function;
	@Input('errorMessage') errorMessage : string;

	@Input() loading : boolean;
	@Output() loadingChange = new EventEmitter<boolean>();

	setLoading(value: boolean) {
		this.loading = value;
		this.loadingChange.emit(this.loading);
	}


	saving : boolean = false;


	form : FormGroup;

	constructor(private formBuilder: FormBuilder,
				private validatorsService: ValidatorsService,
				private usersService: UsersService,
				private dialogService: DialogService,
				private api: ApiService,
				private authService: AuthService) {

	}

	ngOnInit() {

		if (this.user)
		{
			this.form = this.formBuilder.group({
				name: [this.user.name, [Validators.required]],
				email: [this.user.email, [Validators.required, Validators.email]],
				code: [this.user.code, [Validators.required]],
				username: [this.user.username, [Validators.required, this.validatorsService.textPattern]],
				password: ['', []],
				repeat: ['', []]
			});
		}
		else
		{
			this.form = this.formBuilder.group({
				name: [null, [Validators.required]],
				email: [null, [Validators.required, Validators.email]],
				code: ['', [Validators.required]],
				username: [null, [Validators.required, this.validatorsService.textPattern]],
				password: ['', [Validators.required]],
				repeat: ['', [Validators.required]]
			});

			this.form.get('repeat').setValidators([
				Validators.required,
				this.validatorsService.checkPasswords(this.form.get('password'))
			]);
		}
	}


	usernameChanged : boolean;

	changeUser(event, from: 'name' | 'username') {

		setTimeout(() => {

			var _from = from;

			if (_from == 'username')
			{
				this.usernameChanged = true;
			}

			if (this.usernameChanged)
				_from = 'username';

			const username = this.form.get(_from).value;

			var user : string = (username || '');

			user = user.toLowerCase();

			user = user.replace(/\s/g, "");

			user = user.replace(/á/gi,"a");
			user = user.replace(/é/gi,"e");
			user = user.replace(/í/gi,"i");
			user = user.replace(/ó/gi,"o");
			user = user.replace(/ú/gi,"u");
			user = user.replace(/ñ/gi,"n");

			user = user.replace(/[^\w\s]/gi, '');

			this.form.get('username').setValue(user);
		});
	}


	save() {

		if (this.form.invalid)
		{
			Object.keys(this.form.controls).forEach(key => {
				this.form.controls[key].markAsTouched();
			});
			
			return;
		}

		this.saving = true;


		const code = this.form.get('code').value;


		this.api.company().then(result => {

			if (!result)
			{
				this.form.get('code').setErrors({invalid: true});
				this.saving = false;
				return;
			}

			const userData : UserData = {
				name: this.form.get('name').value,
				username: this.form.get('username').value,
				email: this.form.get('email').value,
				code: code,
				role: 'admin',
				active: true
			};

			if (this.user)
			{
				const user : User = {id: this.user.id, ...userData};

				this.usersService.set(user).then(result => {
					this.close();
				}).catch(() => {
					this.saving = false;
				});
			}
			else
			{
				this.usersService.add(userData, this.form.get('password').value).then(result => {
					this.close();
				}).catch((error) => {
					this.saving = false;
				});
			}

		}).catch(reason => {

			this.form.get('code').setErrors({invalid: true});
			this.saving = false;
			return;
		});
	}

}
