import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StateSharedService<T> {
  private status: Map<string, BehaviorSubject<T>> = new Map<string, BehaviorSubject<T>>();

  get(tag: string): BehaviorSubject<T> {
    if (!this.status.has(tag)) {
      this.status.set(tag, new BehaviorSubject<T>(null!));
    }
    return this.status.get(tag)!;
  }

  set(tag: string, newState: T) {
    const estado = this.get(tag);
    estado.next(newState);
  }

  destroy(tag: string) {
    if (this.status.has(tag)) {
      this.status.get(tag)!.complete();
      this.status.delete(tag);
    }
  }
}
