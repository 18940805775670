
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { AuthService } from 'src/app/services/auth.service';
import { ApiService } from 'src/app/services/api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-salesteam',
  templateUrl: './salesteam.component.html',
  styleUrls: ['./salesteam.component.scss']
})
export class SalesteamComponent implements OnInit {
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  constructor(
    private mediaMatcher: MediaMatcher,
    private changeDetectorRef: ChangeDetectorRef,
    public authService: AuthService,
    private api: ApiService,
    private http: HttpClient,
  ) {
    this.mobileQuery = mediaMatcher.matchMedia('(max-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  loading: boolean = true;
  facturadores = [];
  vendedores = [];
  data = [];
  phone: string = '';

  ngOnInit(): void {
    this.api.company(this.authService.user.code).then(company => {
      console.log("🚀 ~ SalesteamComponent ~ this.api.company ~ company:", company)
      if (company && company['codigo_entidad'] == '6000') this.phone = '507';
      else if (company && company['codigo_entidad'] == '5000') this.phone = '506';
      else if (company && company['codigo_entidad'] == '4000') this.phone = '505';
      else if (company && company['codigo_entidad'] == '1000') this.phone = '502';
    });
    this.mobileQuery.removeListener(this._mobileQueryListener);



    this.loadData();
  }


  async toBase64(imageBlob: Blob) {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Aquí puedes asignar el resultado al src de tu elemento img
        const base64data = reader.result as string;
        resolve(base64data);
      };
      reader.readAsDataURL(imageBlob);
    })
  }

  async onCompress(urlImage: string) {
    const urlEncode = encodeURIComponent(urlImage);
    const url = `${environment.gcServer.protocol}://${environment.gcServer.ip}/api/v3/file/compress/${urlEncode}`;
    return await this.http.get(url, { responseType: 'blob' }).toPromise();
  }

  loadData() {



    this.api.company(this.authService.user.code).then(company => {

      this.api.companyInformation(company.id).then(async result => {

        const contacts = result['vendedores'];
        const contactsWithImages = await Promise.all(contacts.map(async (contact) => {
          const imageCompress = await this.onCompress(contact.photo);
          const imageBase64 = await this.toBase64(imageCompress);
          contact.photo = imageBase64;
          return contact;
        }));
        if (result['facturadores']) {
          this.facturadores = contactsWithImages;
          this.data = contactsWithImages;
        }
        if (result['vendedores']) {
          this.vendedores = contactsWithImages;
          this.data = contactsWithImages;
        }
        this.loading = false;

      });

    });



  }

}
