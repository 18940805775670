import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";
import { BehaviorSubject, Subscription } from 'rxjs';
import { User, UserData } from '../classes/user';
import { Location } from '@angular/common';
import { TokenGLService } from './token-gl.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';



export enum LoginStatus {
	ToDo = 'ToDo',
	Doing = 'Doing',
	Done = 'Done',
	Error = 'Error'
}






@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

	public loaded : BehaviorSubject<boolean> = new BehaviorSubject(false);

	public userBS : BehaviorSubject<User> = new BehaviorSubject<User>(null);

	public tokenGlBS : BehaviorSubject<string> = new BehaviorSubject<string>(null);

	get user() : User {
		return this.userBS.value;
	}

	get tokenGl() : string {
		return this.tokenGlBS.value;
	}

	loginStatus : LoginStatus = LoginStatus.ToDo;
	errorMessage : string = '';
	loginMessage : string = '';


	routerSubscription : Subscription;
	userSubscription : Subscription;
	authStateSubscription : Subscription;
	userDataSubscription : Subscription;
	companySubscription : Subscription;


	loginNextRoute : string = null;


	constructor(private http: HttpClient,
				private router: Router,
				private location: Location,
				public tokenGlService: TokenGLService) {

		this.routerSubscription = router.events.subscribe((val) => {

			if (val instanceof NavigationEnd)
			{
				this.loginStatus = LoginStatus.ToDo;
				this.errorMessage = '';
			}
		});

		this.userSubscription = this.userBS.subscribe(user => {

			this.loaded.next(true);
			if (this.userSubscription) this.userSubscription.unsubscribe();
		});

		this.signOutFunction = this.signOut.bind(this);
	}


	ngOnDestroy() {

		if (this.routerSubscription) this.routerSubscription.unsubscribe();
		if (this.userSubscription) this.userSubscription.unsubscribe();
		if (this.authStateSubscription) this.authStateSubscription.unsubscribe();
		if (this.userDataSubscription) this.userDataSubscription.unsubscribe();
		if (this.companySubscription) this.companySubscription.unsubscribe();
	}

	getUser() : Promise<User> {

		const promise = new Promise<User>((resolve) => {

			const url: string = this.getUrl('apiPortal') + '/auth-client/current-user';

			this.http.get<any>(url, this.header('token')).subscribe((json: any) => {

				if (json.message) {
					resolve(null);
				}
				else if (json.data) {
					resolve(json.data);
				}
				else {
					resolve(null);
				}
			},
				(error: any) => {
				console.log("🚀 ~ ApiService ~ promise ~ error:", error)

					resolve(null);
				});
		});

		return promise;

	}

	getUserGl() : Promise<User> {

		const promise = new Promise<User>((resolve) => {

			const url: string = this.getUrl('gcServerV3') + '/client/info';

			this.http.get<any>(url, this.header('tokenGL')).subscribe((json: any) => {

				if (json.message) {
					resolve(null);
				}
				else if (json.data && json.data.length > 0) {
					resolve(json.data[0]);
				}
				else {
					resolve(null);
				}
			},
				(error: any) => {
				console.log("🚀 ~ ApiService ~ promise ~ error:", error)

					resolve(null);
				});
		});

		return promise;

	}


	getCurrentUser() : Promise<User> {
		var promise : Promise<User>;
		const tokenGL:string = localStorage.getItem('tokenGL');
		const token: string = localStorage.getItem('token'); 

		if (tokenGL && token)
		{
			promise = new Promise<User>((resolve) => {
				
				this.getUser().then(function(user) {

					this.userBS.next(user);
					resolve(user);	

				}.bind(this));
					
			});
		}else{
			promise = new Promise<User>((resolve) => {
				this.userBS.next(null);
				resolve(null);
			});
		}

		return promise;
	}



	profileImageUrl() : Promise<string> {

		var promise = new Promise<string>((resolve, reject) => {

			const url: string = this.getUrl('apiPortal') + '/auth-client/current-user';

			this.http.get<any>(url, this.header('token')).subscribe((authUser: any) => {

				if (authUser.message) {
					reject();
				}
				else if (authUser.data) {
					resolve(authUser.data.photoURL);
				}
				else {
					reject();
				}
			},
			(error: any) => {
				console.log("🚀 ~ ApiService ~ promise ~ error:", error)
				reject();
			});


		});

		return promise;
	}

	getUrl(server: string): any {
		console.log('server server', server)
		const url = `${environment[server].protocol}://${environment[server].ip}/${environment[server].version}`;
		return url;
	}

	header(token: string): any {
		const header = {
			headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem(token))
		};

		return header;
	}

	emailLogin(email: string, password: string, keepSession: boolean): Promise<any> {

		this.loginStatus = LoginStatus.Doing;

		var promise = new Promise<any>((resolve, reject) => {

			const url = this.getUrl('apiPortal') + '/auth-client/login';
			this.http.post<any>(url, {email, password}).subscribe((userCredential: any) => {

				console.log('userCredential userCredential', userCredential.data.UserInfo)

				if (userCredential.data.UserInfo.active){


					this.tokenGlService.generate(userCredential.data.userCredential.stsTokenManager.accessToken).then((result:any) => {
						localStorage.setItem('tokenGL', result.token);
						localStorage.setItem('token', userCredential.data.userCredential.stsTokenManager.accessToken);
						this.tokenGlBS.next(result.token);
						this.loginStatus = LoginStatus.Done;

						resolve(userCredential.data.UserInfo);
					}).catch((error:any) => {
						this.loginStatus = LoginStatus.Error;
						this.errorMessage = error.error.message;
						this.signOut()
						reject(this.errorMessage);
					})
				}else{
					this.loginStatus = LoginStatus.Error;
					this.errorMessage = "Usuario no activo";
					this.signOut()

					reject(this.errorMessage);
				}
					
				
			},
			(error: any) => {
				reject(new Error(error));
				this.loginStatus = LoginStatus.Error;
				this.errorMessage = this.errorMessageWithCode(error.error.message.split('(')[1].split(')')[0]);

				reject(this.errorMessage);
			});

		});

		return promise;
	}

	emailSignUp(email: string, password: string, user: UserData): Promise<any> {

		this.loginStatus = LoginStatus.Doing;

		var promise = new Promise<any>((resolve, reject) => {

			const url = this.getUrl('apiPortal') + '/auth-client/sign-up';
			this.http.post<any>(url, {email, password, user}).subscribe((userCredential: any) => {

				console.log('userCreated userCreated', userCredential)

				this.tokenGlService.generate(userCredential.data.userCredential.stsTokenManager.accessToken).then((result:any) => {
					localStorage.setItem('tokenGL', result.token);
					localStorage.setItem('token', userCredential.data.userCredential.stsTokenManager.accessToken);
					this.tokenGlBS.next(result.token);
					this.loginStatus = LoginStatus.Done;

					resolve("success");
				}).catch((error:any) => {
					this.loginStatus = LoginStatus.Error;
					this.errorMessage = error.error.message;
					this.signOut()
					reject(this.errorMessage);
				})
			},
			(error: any) => {
				reject(new Error(error));
				this.loginStatus = LoginStatus.Error;
				this.errorMessage = this.errorMessageWithCode(error.error.message.split('(')[1].split(')')[0]);

				reject(this.errorMessage);
			});


		});

		return promise;
	}

	changePassword(newPassword: string, oldPassword: string) : Promise<void> {

		var promise = new Promise<void>((resolve, reject) => {

			this.getUser().then(function(authUser) {

				if (authUser) {
					const email = authUser.username+ '@multigroup-sa.web.app';
	
					this.emailLogin(email, oldPassword, true).then(result => {


						this.changePasswordPost(email, newPassword, oldPassword).then(result => {

							this.emailLogin(email, newPassword, true);

							resolve(result);
						}).catch(reason => {
							
							this.emailLogin(email, oldPassword, true);

							reject(reason);
						});


					}).catch(reason => {

						reject(reason);
					});
					
				}
				else
				{
					reject();
				}
				

			}.bind(this));

			
		});

		return promise;
	}

	changePasswordPost(email: string, newPassword: string, oldPassword: string): Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			const url = this.getUrl('apiPortal') + '/auth-client/change-password';
			this.http.post<any>(url, {email, password: oldPassword, newPassword}).subscribe((response: any) => {
				resolve("success");
			},
			(error: any) => {
				reject(new Error(error));
				this.loginStatus = LoginStatus.Error;
				this.errorMessage = this.errorMessageWithCode(error.error.message.split('(')[1].split(')')[0]);
				reject(this.errorMessage);
			});
		});

		return promise;
	}



	signOutFunction : Function;

	signOut(): void {

		localStorage.setItem('tokenGL', null);
		localStorage.setItem('token', null);
		this.router.navigate(['/login']).finally(() => {

			this.location.replaceState('/login');
		});
	}


	errorMessageWithCode(code: string) : string {

		var message : string = "Error al iniciar";

		switch (code) {
			case "auth/invalid-email":
				message = "El usuario ingresado no es válido";
				break;

			case "auth/user-disabled":
				message = "El usuario ha sido deshabilitado";
				break;

			case "auth/user-not-found":
				message = "El usuario no se encuentra registrado";
				break;

			case "auth/wrong-password":
				message = "Contraseña incorrecta";
				break;

			case "auth/email-already-in-use":
				message = "El usuario ya se encuentra registrado";
				break;

			case "auth/operation-not-allowed":
				message = "Operación no permitida";
				break;

			case "auth/weak-password":
				message = "La contraseña es demasiado débil";
				break;

			case "auth/invalid-password":
				message = "La contraseña debe tener minimo 6 caracteres";
				break;
			
			default:
				message = "Error al iniciar";
				break;
		}

		return message;
	}
}
