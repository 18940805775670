import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ViewChild } from '@angular/core';
import { OfferData, Offer } from '../../classes/offer';
import { OffersService } from '../../services/offers.service';
import { DialogService } from '../../services/dialog.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-offer-preview',
  templateUrl: './offer-preview.component.html',
  styleUrls: ['./offer-preview.component.scss']
})
export class OfferPreviewComponent implements OnInit {

	@Input('offer') offer : Offer;

	@Input('confirm') confirm : Function;
	@Input('close') close : Function;
	@Input('errorMessage') errorMessage : string;

	@Input() loading : boolean;
	@Output() loadingChange = new EventEmitter<boolean>();

	setLoading(value: boolean) {
		this.loading = value;
		this.loadingChange.emit(this.loading);
	}

	constructor(private storageService: StorageService) {

	}

	ngOnInit() {

	}


	download() {

		this.storageService.downloadFile(this.offer.file, this.offer.title);
	}

}
