import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { DialogService } from '../../services/dialog.service';
import { DatesService } from '../../services/dates.service';
import { SnackbarService } from '../../services/snackbar.service';
import { UsersService } from '../../services/users.service';
import { AuthService } from '../../services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MediaObserver } from '@angular/flex-layout';
import { User, UserData } from '../../classes/user';
import { Subscription, BehaviorSubject } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Page } from '../../adapters/page';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  animations: [
    trigger('expand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class UsersComponent implements OnInit {

	year : number = (new Date()).getFullYear();

	loading : boolean = true;

	displayedColumns: string[];
	dataSource: MatTableDataSource<User> = new MatTableDataSource([]);

	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild(MatSort, {static: true}) sort: MatSort;

	mediaSubscription : Subscription;

	expandedElement : User;


	page : number = 0;
	pageSize : number = 10;

	form : FormGroup

	constructor(public api: ApiService,
				private formBuilder: FormBuilder,
				private dialogService: DialogService,
				public authService: AuthService,
				public datesService: DatesService,
				private snackbar: SnackbarService,
				public usersService: UsersService,
				private changeDetectorRef: ChangeDetectorRef,
				public media: MediaObserver,
				private _page: Page) {

		this._page.actionBarHidden = true;

		this.form = this.formBuilder.group({search: ['', []]});

		this.loading = true;

		this.updateColumns();

		this.mediaSubscription = this.media.asObservable().subscribe(change => {
			this.updateColumns();
		});

	}

	get xs() : boolean {
		return this.media.isActive('xs');
	}

	updateColumns() {

		if (this.media.isActive('gt-xs'))
		{
			this.displayedColumns = ['name', 'username', 'status', 'actions'];
		}
		else
		{
			this.displayedColumns = ['name', 'actions'];
		}
	}


	loadData() {

		this.updateColumns();

		this.usersService.users(this.authService.user.code).subscribe(result => {

			if (result)
			{
				this.dataSource.data = result;

				this.loading = false; 

			
			}

		}, error => {

			this.loading = false;

			if (!this.changeDetectorRef['Destroyed'])
				this.changeDetectorRef.detectChanges();
		});
	}

	ngOnInit() {

		if (this.paginator)
		{
			this.paginator._intl.itemsPerPageLabel = "Usuarios por página:";
			this.paginator._intl.firstPageLabel = "Primera página";
			this.paginator._intl.previousPageLabel = "Página anterior";
			this.paginator._intl.nextPageLabel = "Página siguiente";
			this.paginator._intl.lastPageLabel = "Última página";

			this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {

				if (length == 0 || pageSize == 0) 
				{
					return `0 de ${length}`;
				}

				length = Math.max(length, 0);
				const startIndex = page * pageSize;
				const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
				return `${startIndex + 1} – ${endIndex} de ${length}`;
			};

			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		}

		this.loadData();
	}


	ngOnDestroy() {

		if (this.mediaSubscription) this.mediaSubscription.unsubscribe();
	}


	trackByFn(index, item: User) {
		return item.id;
	}



	editUserDialog(user: User) {

		this.dialogService.openDialog({
			type: 'user',
			object: {user: user}
		});
	}


	disableUserDialog(user: User) {

		this.dialogService.openDialog({
			title: 'Inhabilitar cliente',
			text: '¿Seguro que desea inhabilitar al usuario \"'+(user.name || user.username)+'\"?',
			type: 'Warn',
			confirm: this.disableUser.bind(this),
			object: user
		});
	}

	disableUser(user: User) {

		const _user : User = {...user};

		_user.active = false;

		this.usersService.set(_user, true).then(result => {

			user.active = false;

			this.snackbar.show("Usuario inhabilitado");
			
		}).catch(reason => {

			this.snackbar.show("Error al inhabilitar usuario");

		}).finally(() => {

			this.dialogService.closeDialog();
		});
	}



	applyFilter(filterValue: string) {

		this.dataSource.filterPredicate = (user: User, filter: string) => {
			return !filter ||
					(user.name && user.name.toLowerCase().replace(/\s/g,'').indexOf(filter.toLowerCase().replace(/\s/g,'')) >= 0) ||
					(user.username && user.username.toLowerCase().replace(/\s/g,'').indexOf(filter.toLowerCase().replace(/\s/g,'')) >= 0)
		};

		this.dataSource.filter = filterValue.trim().toLowerCase();
	}



	actions = [
		{names: [
			{prop: 'active', value: true, name: 'Editar'},
			{prop: 'active', value: false, name: 'Habilitar'}
		], action: this.editUserDialog.bind(this)},
		{name: 'Inhabilitar', if: {prop: 'active', value: true}, action: this.disableUserDialog.bind(this)}
	];

}
