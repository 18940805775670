<div class="documents" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1em">
	<button class="close" (click)="close()">X</button>
	<h4>Documentos</h4>
	
	<br/>

	<div *ngFor="let doc of docs; let i = index" class="full-width" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1em">

		<span>{{doc.name}}</span>
		
		<mat-spinner *ngIf="loading && listLoading == i" diameter="40"></mat-spinner>

		<button type="button" mat-button color="primary" (click)="download(doc, i)"> <img class="multi_arrow" [src]="'assets/images/multi_arrow.png'"> Descargar</button>
	</div>
</div>