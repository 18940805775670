import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProductData, Product } from '../classes/product';
import { DatabaseService } from '../services/database.service';
import { StorageService } from '../services/storage.service';
import { SnackbarService } from '../services/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

	products : BehaviorSubject<Product[]> = new BehaviorSubject(null);


	constructor(private database: DatabaseService,
				private snackbar: SnackbarService,
				private storageService: StorageService) {


		this.database.array<ProductData>('products', 'subscribe').subscribe(result => {

			if (result)
			{
				for (var product of result)
				{
					if (product.date && product.date['getTime'] == null)
						product.date = (product.date as firebase.firestore.Timestamp).toDate();

					if (product.expiration && product.expiration['getTime'] == null)
						product.expiration = (product.expiration as firebase.firestore.Timestamp).toDate();
				}

				this.products.next(result);
			}
		});
	}


	get(productID: string) : Promise<Product> {

		var promise = new Promise<Product>((resolve, reject) => {

			this.database.get<Product>('products', productID).then(product => {

				if (product.date && product.date['getTime'] == null)
					product.date = (product.date as firebase.firestore.Timestamp).toDate();

				if (product.expiration && product.expiration['getTime'] == null)
					product.expiration = (product.expiration as firebase.firestore.Timestamp).toDate();

				resolve(product);

			}).catch(reason => {

				reject(reason);
			})
		});

		return promise;
	}

	getFromArray(productID: string) : Product {

		if (this.products.value && this.products.value.length > 0)
		{
			var product : Product = null;

			for (var _product of this.products.value)
			{
				if (_product.id == productID)
				{
					product = _product;
					break;
				}
			}

			return product;
		}
		else
		{
			return null
		}
	}


	add(product: ProductData, silent?: boolean) : Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			this.database.add("products", product, silent ? null : {
				success: "Producto creado",
				error: "Error al crear producto"
			}).then(result => {
				resolve(result);
			}).catch(reason => {
				reject(reason);
			});
		});

		return promise;
	}


	set(product: Product, silent?: boolean) : Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			const productData : ProductData = {...product};

			this.database.set('products', product.id, productData, silent ? null : {
				success: "Producto guardado",
				error: "Error al guardar producto"
			}).then(result => {
				resolve(result);
			}).catch(reason => {
				reject(reason);
			});
		});

		return promise;
	}


	delete(product: Product, silent?: boolean) : Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			this.database.delete('products', product.id, silent ? null : {
				success: "Producto eliminado",
				error: "Error al eliminar producto"
			}).then(result => {
				resolve(result);
			}).catch(reason => {
				reject(reason);
			});
		});

		return promise;
	}

}
