<div *ngIf="!loading" class="profile-edit" ngClass.xs="xs" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2em">

	<h4>Editar perfil</h4>

	<div class="full-width" fxLayout="column" fxLayoutAlign="start start">

		<form class="full-width" [formGroup]="form" id="profileEditForm" (ngSubmit)="save()" autocomplete="off" fxLayout="column" fxLayoutAlign="start center">

			<mat-form-field class="full-width primary" appearance="outline" color="primary">
				<mat-label>NOMBRE</mat-label>
				<input matInput formControlName="name" maxlength="100" autocomplete="off">
				<mat-error *ngIf="form.get('name').hasError('required')">
					Nombre requerido
				</mat-error>
			</mat-form-field>

			<br/>

			<mat-form-field class="full-width primary" appearance="outline" color="primary">
				<mat-label>EMAIL</mat-label>
				<input matInput formControlName="email" maxlength="100" autocomplete="off">
				<mat-error *ngIf="form.get('email').hasError('required')">
					Email requerido
				</mat-error>
				<mat-error *ngIf="form.get('email').hasError('email')">
					Email inválido
				</mat-error>
			</mat-form-field>

			<br/>

			<mat-form-field class="full-width primary" appearance="outline" color="primary">
				<mat-label>TELÉFONO</mat-label>
				<input matInput formControlName="phone" maxlength="100" autocomplete="off">
			</mat-form-field>

			<br/>

			<mat-form-field class="full-width primary" appearance="outline" color="primary">
				<mat-label>DIRECCIÓN</mat-label>
				<input matInput formControlName="address" maxlength="100" autocomplete="off">
			</mat-form-field>


			<br/><br/>

			<mat-spinner *ngIf="saving" color="primary" diameter="40"></mat-spinner>

			<button *ngIf="!saving" class="main-button full-width" mat-flat-button color="primary" form="profileEditForm" type="submit">
				<img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">GUARDAR
			</button>

		</form>

	</div>

</div>


<div *ngIf="loading" class="spinner-container" fxLayout="column" fxLayoutAlign="center center">
	<mat-spinner color="primary" diameter="40"></mat-spinner>
</div>

