import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

	@Input('type') type : string;

	@Input('title') title : string;
	@Input('text') text : string;

	@Input('object') object : any;

	@Input('close') close : Function;
	@Input('confirm') confirm : Function;

	@Input('singleButton') singleButton : boolean = false;

	constructor() {

	}

	ngOnInit() {

		console.log(this.object);
	}

	confirmAlert() {

		this.confirm(this.object);
		this.close();
	}

}
