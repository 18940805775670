
<div class="home" ngClass.xs="xs" fxLayout="column" fxLayoutAlign="space-between center">


	<!-- <app-navbar class="full-width" bgcolor="primary"></app-navbar> -->
	<div class="edit-link" fxLayout="row" fxLayoutAlign="end">
		<a onclick="window.history.go(-1); return false;" [routerLink]="">Regresar</a>
	</div>



	<div fxFlex ngClass.gt-xs="content" ngClass.xs="full-width" fxLayout="column" fxLayoutAlign="start center">

		<mat-card ngClass.gt-xs="mat-elevation-z6" ngClass.xs="mat-elevation-z0" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="1em" fxLayoutGap.xs="0em">

			<h4>Orden de pago</h4>

			<input #fileInput [(ngModel)]="file" (change)="fileChanged($event)" hidden="true" type="file" accept="application/pdf,image/*" />

			<div class="actions-bar" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="1em" fxLayout.xs="column" fxLayoutAlign.xs="start stretch">

				<div *ngIf="!loading" class="bar-details" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0.25em">
					<span><strong>Cliente:</strong> {{order.purchasesData[0].nombre_fiscal}}</span>
					<span><strong>Fecha:</strong> {{stringDate(order?.date)}}</span>
					<span><strong>Monto:</strong> {{numbersService.fixedNumber(order.amount)}}</span>
					<span><strong>Estado:</strong> {{ordersService.orderStatus(order)}}</span>
					<br/>
				</div>

				<div *ngIf="!loading" fxLayout="column" fxLayoutAlign="start end" fxLayoutAlign.xs="start stretch">

					<button *ngIf="!order.file && !order.reject" mat-flat-button [color]="xs ? 'default' : 'primary'" (click)="selectFile()"><img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">Adjuntar comprobante</button>

					<button *ngIf="order.file" mat-button [color]="xs ? 'default' : 'primary'" (click)="download()"><img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">Descargar comprobante</button>

					<button *ngIf="order.paid != true && !order.reject" mat-button [color]="xs ? 'default' : 'primary'" (click)="confirmDialog()"><img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">Aprobar pago</button>

					<button *ngIf="order.paid != true && !order.reject" mat-button [color]="xs ? 'default' : 'primary'" (click)="rejectDialog()"><img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">Rechazar pago</button>

					<button *ngIf="order.reject" mat-button [color]="xs ? 'default' : 'primary'"><img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">Rechazado</button>

				</div>

			</div>


			<br *ngIf="loading" />


			<p class="empty-text" *ngIf="!loading && dataSource.data?.length < 1">
				Orden de pago sin facturas
			</p>

			<mat-spinner *ngIf="loading" fxFlexAlign="center" color="primary" diameter="40"></mat-spinner>


			<table [ngClass]="{'hidden': loading || dataSource.data?.length < 1}" mat-table matSort [dataSource]="dataSource" class="full-width" multiTemplateDataRows>

				<ng-container matColumnDef="row_id">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
					<td mat-cell *matCellDef="let purchase">
						{{purchase.row_id}}
					</td>
				</ng-container>

				<ng-container matColumnDef="factura_numero">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Factura</th>
					<td mat-cell *matCellDef="let purchase">
						{{purchase.factura_numero}}
					</td>
				</ng-container>

				<ng-container matColumnDef="factura_fecha">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Factura</th>
					<td mat-cell *matCellDef="let purchase">
						{{purchase.factura_fecha}}
					</td>
				</ng-container>

				<ng-container matColumnDef="factura_monto">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Monto</th>
					<td mat-cell *matCellDef="let purchase">
						{{numbersService.fixedNumber(purchase.factura_monto)}}
					</td>
				</ng-container>

				<ng-container matColumnDef="pagos_realizados">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Pagos realizados</th>
					<td mat-cell *matCellDef="let purchase">
						{{numbersService.fixedNumber(purchase.pagos_realizados)}}
					</td>
				</ng-container>

				<ng-container matColumnDef="saldo_pendiente">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Saldo pendiente</th>
					<td mat-cell *matCellDef="let purchase">
						{{numbersService.fixedNumber(purchase.saldo_pendiente)}}
					</td>
				</ng-container>


				<ng-container matColumnDef="expanded">
					<td mat-cell *matCellDef="let purchase" [attr.colspan]="displayedColumns.length">
						<div class="element-detail" [@expand]="purchase == expandedElement ? 'expanded' : 'collapsed'" fxLayout="column">

							<br/>
							
							<p><strong>Fecha: </strong>{{purchase.factura_fecha.split('T')[0]}}</p>
							<p><strong>Monto: </strong>{{numbersService.fixedNumber(purchase.factura_monto)}}</p>
							<p><strong>Pagos realizados: </strong>{{numbersService.fixedNumber(purchase.pagos_realizados)}}</p>
							<p><strong>Saldo pendiente: </strong>{{numbersService.fixedNumber(purchase.saldo_pendiente)}}</p>

						</div>
					</td>
				</ng-container>



				<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row *matRowDef="let purchase; columns: displayedColumns;" class="element-row" [class.expanded-row]="expandedElement === purchase" (click)="expandedElement = expandedElement === purchase || !xs ? null : purchase"></tr>
				<tr mat-row *matRowDef="let purchase; columns: ['expanded']" class="detail-row"></tr>

			</table>


			<div class="full-width" [ngClass]="{'hidden': loading || dataSource.data?.length < 1}" fxLayout="row" fxLayoutAlign="end center">

				<h4 class="total">Total: {{total}}</h4>

			</div>

		</mat-card>

	</div>

</div>