import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivationEnd } from '@angular/router';
import { OrdersService } from '../../services/orders.service';
import { DialogService } from '../../services/dialog.service';
import { NumbersService } from '../../services/numbers.service';
import { RouterService } from '../../services/router.service';
import { DatesService } from '../../services/dates.service';
import { StorageService } from '../../services/storage.service';
import { AuthService } from '../../services/auth.service';
import { SnackbarService } from '../../services/snackbar.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatInput } from '@angular/material/input';
import { MediaObserver } from '@angular/flex-layout';
import { Purchase } from '../../classes/purchase';
import { Order, OrderData } from '../../classes/order';
import { Subscription, BehaviorSubject } from 'rxjs';
import { MatDatepicker } from '@angular/material/datepicker';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Page } from '../../adapters/page';
import { ApiService } from 'src/app/services/api.service';


@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('expand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class OrderComponent implements OnInit {

	year : number = (new Date()).getFullYear();

	loading : boolean = true;

	displayedColumns: string[];
	dataSource: MatTableDataSource<Purchase> = new MatTableDataSource([]);

	@ViewChild(MatSort, {static: true}) sort: MatSort;

	mediaSubscription : Subscription;

	expandedElement : Purchase;


	@ViewChild('fileInput', { static: false }) fileInput : ElementRef;


	page : number = 0;
	pageSize : number = 10;


	orderID: string;
	order: Order;

	admin : boolean;

	constructor(
				private api: ApiService,
				public routerService: RouterService,
				public ordersService: OrdersService,
				private storageService: StorageService,
				private snackbar: SnackbarService,
				public numbersService: NumbersService,
				private dialogService: DialogService,
				public authService: AuthService,
				public datesService: DatesService,
				private changeDetectorRef: ChangeDetectorRef,
				public media: MediaObserver,
				private _page: Page) {

		this._page.actionBarHidden = true;

		this.router.events.subscribe(event => {

			if (event instanceof ActivationEnd)
			{
				const segment = event.snapshot.url[event.snapshot.url.length - 3];

				this.admin = segment && segment.path == 'admin';

				this.orderID = event.snapshot.url[event.snapshot.url.length - 1].path;

				this.loadData();
			}
		});


		this.loading = true;

		this.updateColumns();

		this.mediaSubscription = this.media.asObservable().subscribe(change => {
			this.updateColumns();
		});

		this.changeDetectorRef.markForCheck();
	}


	get router() {return this.routerService.router;}


	get xs() : boolean {
		return this.media.isActive('xs');
	}


	updateColumns() {

		if (this.media.isActive('gt-xs'))
		{
			this.displayedColumns = [ 'factura_numero', 'factura_fecha', 'factura_monto', 'pagos_realizados', 'saldo_pendiente'];
		}
		else
		{
			this.displayedColumns = ['factura_numero'];
		}
	}


	loadData() {

		this.updateColumns();

		this.ordersService.get(this.orderID).then(result => {
			if (result)
			{
				this.order = result;

				

				let purchases = result.purchasesData.map(purchase => {
					
						
					return {...purchase};
				});
				this.dataSource.data = purchases;

				this.loading = false;

				this.changeDetectorRef.markForCheck();
				this.changeDetectorRef.detectChanges();
			}

		}).catch(reason => {

			this.loading = false;

			this.changeDetectorRef.markForCheck();
			this.changeDetectorRef.detectChanges();
		});
	}


	stringToDate(string: string) : Date {

		const doo = new Date(string);

		return new Date( doo.getTime() - doo.getTimezoneOffset() * -60000 );
	}

	stringDate(date: Date) : string {

		return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
	}


	moneda: string  = '';

	ngOnInit() {

		if (this.sort)
		{
			this.dataSource.sort = this.sort;
		}

		this.api.company(this.authService.user.code).then(result => {
			if (result.moneda && result.moneda[0]){
				this.moneda = result.moneda[0]['tipo']
			}
		});
	}


	ngOnDestroy() {

		if (this.mediaSubscription) this.mediaSubscription.unsubscribe();
	}


	trackByFn(index, item: Purchase) {
		return item.row_id;
	}


	file: string;

	selectFile() {
		this.fileInput.nativeElement.click();
	}

	fileChanged(event) {

		this.loading = true;

		this.changeDetectorRef.detectChanges();
		this.changeDetectorRef.markForCheck();

		if (event.target.files.length == 0)
			return;

		const file = event.target.files[0];
		const extension = file.name.substring(file.name.lastIndexOf('.'));

		const path : string = 'Orders/'+this.orderID+extension;

		const order : Order = {...this.order};

		this.storageService.uploadFile(path, file).then(result => {

			order.file = path;

			this.ordersService.set(order).then(result => {

				this.order.file = order.file;
				this.loading = false;

				this.changeDetectorRef.detectChanges();
				this.changeDetectorRef.markForCheck();

			}).catch(reason => {
				this.loading = false;

				this.changeDetectorRef.detectChanges();
				this.changeDetectorRef.markForCheck();
			});

		}).catch(reason => {

			this.snackbar.show('Error al adjuntar comprobante');
			this.loading = false;

			this.changeDetectorRef.detectChanges();
			this.changeDetectorRef.markForCheck();
		});
	}


	download() {

		this.storageService.downloadFile(this.order.file, this.order.id);
	}


	confirmDialog() {

		this.dialogService.openDialog({
			type: 'Alert',
			title: 'Aprobar pago',
			text: '¿Seguro que desea aprobar el pago de esta orden? Esta acción no se puede deshacer',
			confirm: this.confirm.bind(this)
		});
	}

	confirm() {

		this.dialogService.closeDialog();

		this.loading = true;

		const order : Order = {...this.order};

		order.paid = true;

		this.ordersService.set(order, true).then(result => {

			this.order = order;
			this.loading = false;
			this.snackbar.show("Pago aprobado");
			this.changeDetectorRef.markForCheck();

		}).catch(reason => {

			this.loading = false;
			this.snackbar.show("Error al aprobar el pago");
			this.changeDetectorRef.markForCheck();
		});
	}



	rejectDialog() {

		this.dialogService.openDialog({
			type: 'Warn',
			title: 'Rechazar pago',
			text: '¿Seguro que desea rechazar el pago de esta orden? Esta acción no se puede deshacer',
			confirm: this.confirm.bind(this)
		});
	}

	reject() {

		this.dialogService.closeDialog();
		
		this.loading = true;

		const order : Order = {...this.order};

		order.reject = true;

		this.ordersService.set(order, true).then(result => {

			this.order = order;
			this.loading = false;
			this.snackbar.show("Pago rechazado");
			this.changeDetectorRef.markForCheck();

		}).catch(reason => {

			this.loading = false;
			this.snackbar.show("Error al rechazar el pago");
			this.changeDetectorRef.markForCheck();
		});
	}



	get total() : string {

		var _total = (this.dataSource.data || []).map(purchase => {return purchase.saldo_pendiente}).reduce((acc, cur) => {
			return acc + cur;
		});
		return this.numbersService.fixedNumber(_total);
	}

}
