<h2 mat-dialog-title></h2>
<mat-dialog-content>
  <p>Estimado/a Cliente,</p>
  <p>Este proceso puede tomar de 5 y 30 minutos</p>
  <p>
    ¿Prefiere continuar con la descarga del documento o enviar al correo {{email}}?
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Descargar Documento</button>
  <button
    mat-button
    style="background-color: #ea0029; color: #fff;"
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    Enviar por Correo
  </button>
</mat-dialog-actions>
