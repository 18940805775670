import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UsersService } from '../../services/users.service';
import { DialogService } from '../../services/dialog.service';
import { DatesService } from '../../services/dates.service';
import { AuthService } from '../../services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MediaObserver } from '@angular/flex-layout';
import { User, UserData } from '../../classes/user';
import { Subscription, BehaviorSubject } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Page } from '../../adapters/page';


@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('expand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class CustomersComponent implements OnInit {

	year : number = (new Date()).getFullYear();

	loading : boolean = true;

	displayedColumns: string[];
	dataSource: MatTableDataSource<User> = new MatTableDataSource([]);

	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild(MatSort, {static: true}) sort: MatSort;

	mediaSubscription : Subscription;
	sourceSubscription : Subscription;


	expandedElement : User;


	page : number = 0;
	pageSize : number = 10;


	form : FormGroup;


	constructor(public usersService: UsersService,
				private dialogService: DialogService,
				public authService: AuthService,
				private formBuilder: FormBuilder,
				public datesService: DatesService,
				private changeDetectorRef: ChangeDetectorRef,
				public media: MediaObserver,
				private _page: Page) {

		this._page.actionBarHidden = true;

		this.form = this.formBuilder.group({search: ['', []]});

		this.loading = true;

		this.updateColumns();

		this.mediaSubscription = this.media.asObservable().subscribe(change => {
			this.updateColumns();
		});

		if (!this.changeDetectorRef['destroyed'])
			this.changeDetectorRef.markForCheck();
	}


	get xs() : boolean {
		return this.media.isActive('xs');
	}


	updateColumns() {

		if (this.media.isActive('gt-xs'))
		{
			this.displayedColumns = ['name', 'username', 'code', 'actions'];
		}
		else
		{
			this.displayedColumns = ['name', 'actions'];
		}
	}


	loadData() {
		this.loading = true;

		this.updateColumns();

		this.sourceSubscription = this.usersService.users().subscribe(result => {
			console.log('result', result)

			if (result)
			{
				this.dataSource.data = result;

				this.loading = false;

				if (!this.changeDetectorRef['destroyed'])
					this.changeDetectorRef.markForCheck();
			}
		});
	}



	ngOnInit() {

		if (this.paginator)
		{
			this.paginator._intl.itemsPerPageLabel = "Clientes por página:";
			this.paginator._intl.firstPageLabel = "Primera página";
			this.paginator._intl.previousPageLabel = "Página anterior";
			this.paginator._intl.nextPageLabel = "Página siguiente";
			this.paginator._intl.lastPageLabel = "Última página";

			this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {

				if (length == 0 || pageSize == 0) 
				{
					return `0 de ${length}`;
				}

				length = Math.max(length, 0);
				const startIndex = page * pageSize;
				const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
				return `${startIndex + 1} – ${endIndex} de ${length}`;
			};

			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		}

		this.loadData();
	}


	ngOnDestroy() {

		if (this.mediaSubscription) this.mediaSubscription.unsubscribe();
		if (this.sourceSubscription) this.sourceSubscription.unsubscribe();
	}


	newUserDialog() {

		this.dialogService.openDialog({
			title: 'Nuevo cliente',
			type: 'newCustomer',
			close: this.loadData.bind(this)
		});
	}


	// deleteUserDialog(user: User) {

	// 	this.dialogService.openDialog({
	// 		title: 'Eliminar cliente',
	// 		text: '¿Seguro que desea eliminar al cliente \"'+(user.name || user.username)+'\"?',
	// 		type: 'Warn',
	// 		confirm: this.deleteUser.bind(this),
	// 		object: user
	// 	});
	// }

	// deleteUser(user: User) {

	// 	this.usersService.delete(user).then(result => {
	// 		this.dialogService.closeDialog();
	// 	});
	// }


	editUserDialog(user: User) {

		this.dialogService.openDialog({
			type: 'newCustomer',
			object: {user: user},
			close: this.loadData.bind(this)
		});
	}


	trackByFn(index, item) {
		return item.id;
	}


	applyFilter(filterValue: string) {

		this.dataSource.filterPredicate = (user: User, filter: string) => {
			return !filter ||
					(user.name && user.name.toLowerCase().replace(/\s/g,'').indexOf(filter.toLowerCase().replace(/\s/g,'')) >= 0) ||
					(user.code && user.code.toLowerCase().replace(/\s/g,'').indexOf(filter.toLowerCase().replace(/\s/g,'')) >= 0) ||
					(user.username && user.username.toLowerCase().replace(/\s/g,'').indexOf(filter.toLowerCase().replace(/\s/g,'')) >= 0)
		};

		this.dataSource.filter = filterValue.trim().toLowerCase();
	}


	actions = [
		{name: 'Editar', action: this.editUserDialog.bind(this)}
	];

}
