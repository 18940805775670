<!-- <mat-toolbar ngClass.gt-xs="mat-elevation-z5" [ngClass.xs]="bgcolor" [class]="forceColor ? bgcolor : ''">
		<mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
			<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.2em">
				<button mat-icon-button (click)="snav.toggle()"><mat-icon [style.color]="mobileQuery.matches ? '#ffffff' : '#ffffff'">menu</mat-icon></button>
				<img class="logo clickable"
					[src]="'assets/images/multi'+(bgcolor == 'primary' && (xs && !forceColor) ? '' : '_red1')+'.png'"
					alt="Multi" (click)="openHome()" />
	
			</div>
			<div fxHide.xs fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1.5em">
	
				<div *ngIf="!forceColor || xs" class="clickable" fxLayout="row" fxLayoutAlign="center center"
					[routerLink]="homePath+'profile'">
					<span>{{username}}</span>
					<div class="photo" fxLayout="row" fxLayoutAlign="center center"
						[ngStyle]="{'background-image' : (!uploading && photoUrl != '' && photoUrl != null ? 'url('+photoUrl+')' : 'none')}">
					</div>
				</div>
	
				<div class="clickable" fxLayout="row" fxLayoutAlign="center center" (click)="signOut()">
					<span>Salir</span>
					<img class="exit" src="assets/images/exit.png" alt="Exit" />
				</div>
			</div>
	
	
			<div fxHide.gt-xs>
	
				<div class="photo xs" fxLayout="row" fxLayoutAlign="center center"
					[ngStyle]="{'background-image' : (!uploading && photoUrl != '' && photoUrl != null ? 'url('+photoUrl+')' : 'none')}"
					[matMenuTriggerFor]="navMenu" (click)="$event.stopPropagation()">
				</div>
	
				<mat-menu #navMenu="matMenu">
	
					<button mat-menu-item [routerLink]="homePath+'profile'" fxLayout="row" fxLayoutAlign="start center"
						fxLayoutGap="1em">
						<app-icon icon="user_primary" size="24"></app-icon>
						<span>Perfil ({{username}})</span>
					</button>
	
					<button mat-menu-item (click)="signOut()" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em">
						<app-icon icon="exit" size="24"></app-icon>
						<span>Salir</span>
					</button>
	
				</mat-menu>
			</div>
	
		</mat-toolbar-row>
	</mat-toolbar> -->

<div class="main-container" [class.example-is-mobile]="mobileQuery.matches">
	<mat-sidenav-container class="sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 1 : 0">
		<!--<mat-sidenav #snav mode="side" disableClose="true" [opened]="mobileQuery.matches ? false : true">
			<mat-nav-list>



				<mat-list-item *ngFor="let section of sections; let i= index;" [ngClass]="{
					'active-list-item': url.includes(section?.path)
				 }" (click)="openSection(i)">
					<div class="sidebar-icon-container"  >
						<div class="sidebar-icon-radius" fxLayout="column" fxLayoutAlign="center center">
							<app-icon [icon]="section?.icon" [size]="xs ? 1.5 : 1.5" sizeUnit="em"></app-icon>

						</div>
					</div>
				<span  (click)="openSection(i)" class="title">{{section?.text}}11</span>

				</mat-list-item>

				
			</mat-nav-list>
		</mat-sidenav>-->


		<mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
			fixedTopGap="56" [opened]="mobileQuery.matches ? false : true">


			<div class="menu-inner">
				<div class="menu-top">

					<a routerLink="" class="menu-logo">
						<img src="/assets/images/new-logo.svg" />
					</a>

					<div class="menu-links">

						<div *ngFor="let section of sections; let i= index;"
							[ngClass]="menuStatus == section.path ? 'selected': ''">
							<mat-nav-list *ngIf="!section.children" [ngClass]="section.children ? 'submenu-wrap' : ''">
								<a mat-list-item (click)="openSection(i)"><img
										src="/assets/images/multi_arrow.png" />{{section?.text}}</a>
							</mat-nav-list>


							<mat-expansion-panel *ngIf="section.children" [expanded]="menuOpen">
								<mat-expansion-panel-header>
									<mat-panel-title style="font-weight: bold;">
										<img class="panel-icon" src="/assets/images/multi_arrow.png" />
										{{section?.text}}
									</mat-panel-title>
								</mat-expansion-panel-header>
								<div class="panel-body">
									<mat-nav-list *ngFor="let subsection of section.children; let si= index;"
										[ngClass]="menuStatus == subsection.path ? 'selected': ''">
										<a mat-list-item (click)="openSubSection(subsection.path)" style="font-weight: normal !important;"><span style="padding-right: 13px;"></span><img
												src="/assets/images/multi_arrow.png" /><p></p>{{subsection?.text}}</a>
									</mat-nav-list>

								</div>
							</mat-expansion-panel>
						</div>

					</div>

				</div>

				<div class="menu-bottom">
					<div class="menu-links">
						<mat-nav-list *ngIf="company" [ngClass]="menuStatus == '/salesteam' ? 'selected': ''">
							<a mat-list-item (click)="openSalesTeam()"><img
									src="/assets/images/multi_arrow.png" />CONTACTOS</a>
						</mat-nav-list>
						<mat-nav-list *ngIf="company" [ngClass]="menuStatus == '/company' ? 'selected': ''">
							<a mat-list-item (click)="openHome()"><img
									src="/assets/images/multi_arrow.png" />MULTIGROUP.COM</a>
						</mat-nav-list>
						<mat-nav-list [ngClass]="menuStatus == '/profile' ? 'selected': ''">
							<a mat-list-item (click)="openProfile()"><img src="/assets/images/multi_arrow.png" />MI
								CUENTA</a>
						</mat-nav-list>
						<mat-nav-list>
							<a mat-list-item (click)="signOut()"><img src="/assets/images/multi_arrow.png" />CERRAR
								SESION</a>
						</mat-nav-list>
					</div>
				</div>
			</div>
		</mat-sidenav>
		<mat-sidenav-content class="left-sidenav-content">
			<button mat-icon-button (click)="snav.toggle(); viewLogo()" fxLayout.gt-sm="none"
				class="hamburger-icon"><mat-icon>menu</mat-icon></button>
			<a href="https://api.whatsapp.com/send?phone=50223205555" target="_blank" class="whatsapp-icon-wrap">
				<img class="whatsapp-stick-icon" src="../../assets/images/whatsapp.png" alt="" srcset="" />
			</a>
			<div *ngIf="view" class="img-container"><img src="../../assets/images/Logo-multi-full.svg" alt="img-logo" class="img-logo-full"></div>
			<router-outlet></router-outlet>
			<div class="copyright-box">
				<p>© 2023. Multi. Reservados Todos los Derechos.</p>
			</div>
		</mat-sidenav-content>
	</mat-sidenav-container>