<mat-card class="custum-card">
	<h1>FACTURAS PENDIENTE DE PAGO</h1>
	<div class="purchase-table">
		<div class="actions-bar" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="25px"
			fxLayout.xs="column" fxLayout.sm="column">
			<div fxLayoutAlign="start center">
				<mat-form-field ngClass.gt-xs="primary" ngClass.xs="full-width" appearance="outline"
					[color]="xs ? 'default': 'primary'">
					<input class="clickable" [max]="maxDate" [min]="minDate" #datepickerInput matInput readonly
						[matDatepicker]="dp" placeholder="Seleccionar mes"
						(click)="$event.stopPropagation(); dp.open(); setInputValue();" (focusout)="setInputValue()">
					<button *ngIf="monthDate" matSuffix mat-icon-button aria-label="Clear" (click)="filterValue(null)">
						<mat-icon>close</mat-icon>
					</button>
					<mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
					<mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
						(monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
					</mat-datepicker>
				</mat-form-field>

				<div style="margin-left: 15px;" class="button-res" fxLayout="row" fxLayoutGap="12px"
					fxLayout.xs="column" fxLayout.sm="column">
					<button mat-button class="home-btn bg-red"
						[color]="filter == 'month' ? (xs ? 'white' : 'primary') : (xs ? 'primary' : 'white')"
						(click)="filterMonthCurrent()">
						<img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">
						<span>MES ACTUAL</span>
					</button>
					<button mat-button class="home-btn"
						[color]="filter == '8days' ? (xs ? 'white' : 'primary') : (xs ? 'primary' : 'white')"
						(click)="filterToday()">
						<img src="/assets/images/refresh-icon.svg" />
						<span>VENCIDO A HOY</span>
					</button>
					<button mat-button class="home-btn"
						[color]="filter == '8days' ? (xs ? 'white' : 'primary') : (xs ? 'primary' : 'white')"
						(click)="filterMonthLast()">
						<img src="/assets/images/refresh-icon.svg" />
						<span>VENCIDO A FIN MES</span>
					</button>
				</div>
			</div>
		</div>

		<div fxLayoutAlign="end center">
			<div class="button-res" fxLayout="row" fxLayoutGap="12px" fxLayout.xs="column" fxLayout.sm="column">
				<mat-form-field>
					<mat-label>Sociedad</mat-label>
					<mat-select [(ngModel)]="societySelected" name="food">
						<mat-option (click)="onSelectSociety(society)" *ngFor="let society of companies"
							[value]="society.id">{{society.nombre}}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field ngClass.gt-xs="primary" ngClass.xs="full-width" appearance="outline"
					[color]="xs ? 'default': 'primary'" style="width: 200px">
					<mat-label>BUSCAR</mat-label>
					<input matInput maxlength="100" autocomplete="off" #searchInput
						(keyup)="filterValue($event.target.value)">
					<app-icon [icon]="'search'+(!xs ? '_primary': '')" matSuffix></app-icon>
				</mat-form-field>

			</div>
			<!-- <div class="button-res" fxLayout="row" fxLayoutGap="12px" fxLayout.xs="column" fxLayout.sm="column">
			
		</div> -->


		</div>


		<div style="margin-bottom: 10px;" fxLayout="row" fxLayoutAlign="space-between center">

			<div class="button-res" fxLayout="row" fxLayoutGap="12px" fxLayout.xs="column" fxLayout.sm="column">

				<button mat-button class="home-btn bg-red" [color]="(xs ? 'white' : 'primary')"
					(click)="dowload_docs()">
					<img class="multi_arrow" [src]="'assets/icons/download-icon.svg'">
					<span>DESCARGAR ARCHIVOS</span>
				</button>

				<ng-container matColumnDef="factura_numero">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Numero Factura</th>
					<td mat-cell *matCellDef="let purchase">
						{{purchase.factura_numero}}
					</td>
				</ng-container>

				<ng-container matColumnDef="oc">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Orden de Compra</th>
					<td style="padding-left: 3%;" mat-cell *matCellDef="let purchase">
						{{purchase.oc}}
					</td>
				</ng-container>

				<ng-container matColumnDef="factura_fecha">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Dias de Vencimiento</th>
					<td style="padding-left: 5%;" mat-cell *matCellDef="let purchase">
						{{showDaysPastDue(purchase.dias_atraso) }}
					</td>
				</ng-container>



				<ng-container matColumnDef="valor">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Total</th>
					<td mat-cell *matCellDef="let purchase">
						{{moneda}} {{numbersService.fixedNumber(purchase.factura_monto)}}
					</td>
				</ng-container>
				<ng-container matColumnDef="saldo_pendiente">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Saldo pendiente</th>
					<td mat-cell *matCellDef="let purchase">
						{{moneda}} {{numbersService.fixedNumber(purchase.saldo_pendiente)}}
					</td>
				</ng-container>
				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let purchase" class="last-column">

						<app-icon icon="search_primary" size="16" class="clickable"
							(click)="goToPage($event, purchase)"></app-icon>
					</td>
				</ng-container>

				<ng-container matColumnDef="expanded">
					<td mat-cell *matCellDef="let purchase" [attr.colspan]="displayedColumns.length">
						<div class="element-detail" [@expand]="purchase == expandedElement ? 'expanded' : 'collapsed'"
							fxLayout="column">

							<br />
							<p><strong>Fecha: </strong>{{purchase.factura_fecha.split('T')[0]}}</p>
							<p><strong>Monto: </strong>{{numbersService.fixedNumber(purchase.factura_monto)}}</p>
							<p><strong>Total:
								</strong>{{numbersService.fixedNumber(purchase.factura_monto)}}</p>
							<p><strong>Saldo pendiente:
								</strong>{{numbersService.fixedNumber(purchase.saldo_pendiente)}}</p>

						</div>
					</td>
				</ng-container>
			</div>
			<div class="sub-container">
				<div class="sub">
					<label>Subtotal: {{moneda}} {{ numbersService.fixedNumber(subtotal) }}</label>
				</div>
			</div>


		</div>


		<div class="custum-table" fxLayout="column">
			<br *ngIf="loading" />


			<p class="empty-text" *ngIf="!loading && dataSource.data?.length < 1">
				No hay facturas registradas
			</p>

			<mat-spinner *ngIf="loading" fxFlexAlign="center" color="primary" diameter="40"></mat-spinner>


			<table [ngClass]="{'hidden': loading || dataSource.data?.length < 1}" mat-table matSort
				[dataSource]="dataSource" class="full-width" multiTemplateDataRows>


				<ng-container matColumnDef="select">
					<th mat-header-cell *matHeaderCellDef>
						<div class="check-cont">
							<div class="check">
								<!-- <mat-checkbox (click)="clickCheck();"
									(change)="[($event ? masterToggle() : null), generateSubtotal()]" color="primary"
									[checked]="selection.hasValue() && isAllSelected()"
									[indeterminate]="selection.hasValue() && !isAllSelected()" class="check-box">
								</mat-checkbox> -->
							</div>
						</div>
					</th>
					<td mat-cell *matCellDef="let purchase">
						<mat-checkbox (click)="$event.stopPropagation(); clickCheck();" color="primary"
							(change)="[($event ? selection.toggle(purchase) : null), generateSubtotal()]"
							[checked]="selection.isSelected(purchase)">
						</mat-checkbox>
					</td>

				</ng-container>



				<ng-container matColumnDef="factura_numero">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Numero Factura</th>
					<td mat-cell *matCellDef="let purchase">
						{{purchase.factura_numero}}
					</td>
				</ng-container>

				<ng-container matColumnDef="oc">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Orden de Compra</th>
					<td style="padding-left: 3%;" mat-cell *matCellDef="let purchase">
						{{purchase.oc}}
					</td>
				</ng-container>

				<ng-container matColumnDef="factura_fecha">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Dias de Vencimiento</th>
					<td style="padding-left: 5%;" mat-cell *matCellDef="let purchase">
						{{showDaysPastDue(purchase.dias_atraso) }}
					</td>
				</ng-container>


				<ng-container matColumnDef="valor">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Total</th>
					<td mat-cell *matCellDef="let purchase">
						{{moneda}} {{numbersService.fixedNumber((purchase.factura_monto)*-1)}}
					</td>
				</ng-container>
				<ng-container matColumnDef="saldo_pendiente">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Saldo pendiente</th>
					<td mat-cell *matCellDef="let purchase">
						{{moneda}} {{numbersService.fixedNumber(purchase.saldo_pendiente*-1)}}
					</td>
				</ng-container>


				<ng-container matColumnDef="status">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Estado de pago</th>
					<td mat-cell *matCellDef="let purchase">
						<span class="Pendiente_pago" *ngIf="purchase.orderStatus != 'pending'">
							Pendiente pago
						</span>

						<span class="En_proceso" *ngIf="purchase.orderStatus == 'pending'">
							En proceso
						</span>

					</td>
				</ng-container>




				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let purchase" class="last-column">
						<!-- <a [href]="'invoices/'+purchase.pedido"> -->
						<app-icon (click)="goToPage($event, purchase)" icon="search_primary" size="16"
							class="clickable"></app-icon>
						<!-- </a> -->
					</td>
				</ng-container>

				<ng-container matColumnDef="expanded">
					<td mat-cell *matCellDef="let purchase" [attr.colspan]="displayedColumns.length">
						<div class="element-detail" [@expand]="purchase == expandedElement ? 'expanded' : 'collapsed'"
							fxLayout="column">

							<br />
							<p><strong>Fecha: </strong>{{purchase.factura_fecha.split('T')[0]}}</p>

							<p><strong>Monto: </strong>{{numbersService.fixedNumber(purchase.factura_monto)}}</p>
							<p><strong>Saldo pendiente:
								</strong>{{numbersService.fixedNumber(purchase.saldo_pendiente)}}</p>
						</div>
					</td>
				</ng-container>



				<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row *matRowDef="let purchase; columns: displayedColumns;" class="element-row"
					[class.expanded-row]="expandedElement === purchase"
					(click)="expandedElement = expandedElement === purchase || !xs ? null : purchase"></tr>
				<tr mat-row *matRowDef="let purchase; columns: ['expanded']" class="detail-row"></tr>


			</table>


			<mat-paginator fxFlexAlign="end" [ngClass]="{'hidden': loading || dataSource.data?.length < 1}"
				[pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="pageSize" [pageIndex]="page"></mat-paginator>
		</div>
	</div>

</mat-card>