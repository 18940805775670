import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'ExpansionPanel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent implements OnInit {

	

	constructor() {

	}

	ngOnInit() {

	}

}
