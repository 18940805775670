import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { StorageService } from '../services/storage.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

	photoUrl: BehaviorSubject<string> = new BehaviorSubject<string>("assets/images/profile.jpg");

	defaultPhoto: string = "assets/images/profile.jpg";

	uploading : boolean = false;


	constructor(private authService: AuthService,
				private storageService: StorageService) {

		this.updateImageURL();
	}


	updateImageURL() {

		this.uploading = true;

		if (this.authService.user?.profileImagePath)
		{
			this.storageService.getUrl(this.authService.user.profileImagePath).then(url => {

				if (url)
				{
					this.uploading = false;
					this.photoUrl.next(url);
				}
				else
				{
					this.uploading = false;
					this.photoUrl.next(this.defaultPhoto);
				}
			}).catch(reason => {
				this.uploading = false;
				this.photoUrl.next(this.defaultPhoto);
			});
		}
		else
		{
			this.authService.profileImageUrl().then(url => {

				if (url)
				{
					this.uploading = false;
					this.photoUrl.next(url);
				}
				else
				{
					this.uploading = false;
					this.photoUrl.next(this.defaultPhoto);
				}
			}).catch(reason => {
				this.uploading = false;
				this.photoUrl.next(this.defaultPhoto);
			});
		}
	}
}
