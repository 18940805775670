import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Purchase, CommercialPurchase } from '../classes/purchase';
import { Company } from '../classes/company';
import { Shopping } from '../classes/shopping';
import { TrackingResponse } from '../classes/tracking';
import { AuthService } from './auth.service';
import { DatesService } from './dates.service';
import { environment } from 'src/environments/environment';
import { DocGCInterface, OrderInterface } from '../classes/document-logistic';
import { Account } from '../classes/account';
import { CollectionGoalInterface } from '../datasource/sap/v1/collection-goal/domain/interface/collection-goal.interface';
import { ResponseInterface } from '../datasource/api/domain/interface/response.interface';
import { ProductData } from '../classes/product';
import { UserData } from '../classes/user';


@Injectable({
	providedIn: 'root'
})
export class ApiService {

	customer: string;

	constructor(private http: HttpClient,
		private datesService: DatesService,
		private authService: AuthService) {

		this.authService.userBS.subscribe(user => {
			console.log('user', user)
			if (user) this.customer = user.code;
		});
	}

	get headerGL(): any {
		const header = {
			headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('tokenGL'))
		};

		return header;
	}

	get headerC(): any {
		const header = {
			headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('token'))
		};

		return header;
	}

	getUrl(server: string): any {
		console.log('server server', server)
		const url = `${environment[server].protocol}://${environment[server].ip}/${environment[server].version}`;
		return url;
	}


	purchases(year, month, filter): Promise<Purchase[]> {

		let date_i = this.begin(year, month)
		let date_f = this.end(year, month)

		if (filter == '8days') {
			date_i = this.hoyEightDay()
			date_f = this.hoy()
		}

		if (filter == 'allYear') {
			date_i = this.initYear()
			date_f = this.hoy()
		}

		const promise = new Promise<Purchase[]>((resolve, reject) => {

			this.company().then(company => {

				const url = this.getUrl('gcServerV3') + '/tracking/finaciero?inicio=' + date_i + '&final=' + date_f + '&empresas=' + this.companiesParam(company);
				this.http.get<any>(url, this.headerGL).subscribe((json: any) => {

					if (json.error) {
						reject();
					}
					else {
						resolve(json);
					}
				},
					(error: any) => {

						reject(error);
					});

			}).catch(reason => {

				reject();
			});
		});

		return promise;
	}

	async purchasesEstadoCuenta(clientCode?: string): Promise<{ pedidos: Purchase[], oc: "" }> {
		const company = await this.company();
		const entidad = company['codigo_entidad'];
		const token = localStorage.getItem('tokenGL');
		 
		if(!clientCode) clientCode = this.authService.user.code;
		const url = this.getUrl('sapApiServer') + `/collection_goal/recalclient?f[0][clientCode]=${clientCode}&f[0][entityCode]=${entidad}&f[0][audatDate]=${new Date().toISOString()}`;
		const response = await this.http.get<ResponseInterface<CollectionGoalInterface[]>>(url, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) }).toPromise();
		const collectionGoalDocuments = response.data;
		const filteredDocuments = collectionGoalDocuments.filter((document) => document.documentType == "RV");
		const documents = filteredDocuments.map((document) => {
			const ncNumbers = document.collectionGoalList.filter((document) => document.documentType == 'NC').map((document) => document.sapNumber);
			const purchase: Purchase = {
				row_id: 0,
				nombre_sociedad: document.documentEntityCode,
				nombre_fiscal: document.clientName,
				factura_numero: document.fiscalNumber,
				factura_sap: document.sapNumber,
				factura_fecha: document.documentDate,
				factura_vencimiento: document.dueDate,
				dias_atraso: document.creditDays,
				pedido: document.orderNumber,
				factura_monto: Math.abs(document.nativeTotal),
				pagos_realizados: Math.abs(document.nativePayment),
				saldo_pendiente: Math.abs(document.nativeBalance) * -1,
				ncNumbers,
				codigo_cliente: String(Number(document.clientCode)),
				en_cxc: '',
				sociedad: '',
				tipo_documento: document.documentType,
				orderStatus: '',
				oc: '',
				documents: document.collectionGoalList
			}
			return purchase;
		});
		return { pedidos: documents, oc: "" };
	}

	purchasesEstadoCuentaOC(dateStart: Date, dateEnd: Date): Promise<Purchase[]> {
		const dateStartString = dateStart.toISOString().split('T')[0];
		const dateEndString = dateEnd.toISOString().split('T')[0];

		const promise = new Promise<Purchase[]>((resolve, reject) => {

			this.company().then(company => {
				const url = this.getUrl('gcServerV3') + '/tracking/statement?inicio=' + dateStartString + '&final=' + dateEndString + '&empresas=' + this.companiesParam(company);
				this.http.get<any>(url, this.headerGL).subscribe((json: any) => {

					if (json.error) {
						reject();
					}
					else {
						resolve(json);
					}
				},
					(error: any) => {
						reject(error);
					});

			}).catch(reason => {
				reject();
			});
		});

		return promise;
	}

	accountStatement(filter: any): Promise<Account> {
		const promise = new Promise<Account>((resolve, reject) => {
			
			const url = this.getUrl('sapApiServerV3') + '/collection/account-statement-client' + filter;
			this.http.get<any>(url, this.headerGL).subscribe((json: any) => {

				if (json.error) {
					reject(new Error('Error al consultar pedidos'));
				}
				else {
					resolve(json);
				}
			},
			(error: any) => {
				reject(new Error(error));
			});
		});

		return promise;
	}


	commercial(year, month, filter, clientCode?: string): Promise<CommercialPurchase[]> {

		let date_i = this.begin(year, month)
		let date_f = this.end(year, month)

		if (filter == '8days') {
			date_i = this.hoyEightDay()
			date_f = this.hoy()
		}

		if (filter == 'allYear') {
			date_i = this.initYear()
			date_f = this.hoy()
		}

		if (filter == '30days') {
			const current = new Date();
			const days30Ms = 1000 * 60 * 60 * 24 * 30;
			const days30Date = new Date(current.getTime() - days30Ms);
			date_i = this.stringDate(days30Date);
			date_f = this.stringDate(current);
		}

		const promise = new Promise<CommercialPurchase[]>((resolve, reject) => {
			if(!clientCode)clientCode=this.authService.user.code;
			console.log("🚀 ~ ApiService ~ promise ~ clientCode:", clientCode)
			this.company().then(company => {

				const url = this.getUrl('gcServerV3') + '/tracking/comercial?inicio=' + date_i + '&final=' + date_f + '&empresas=' + this.companiesParam(company);

				this.http.get<any>(url, this.headerGL).subscribe((json: any) => {

					if (json.error) {
						reject(new Error('Error al consultar pedidos'));
					}
					else {
						resolve(json.pedidos);
					}
				},
					(error: any) => {

						reject(new Error(error));
					});

			}).catch(reason => {

				reject(new Error(reason));
			});
		});

		return promise;
	}



	tracking(order: string): Promise<TrackingResponse> {

		const promise = new Promise<TrackingResponse>((resolve, reject) => {
			const url: string = this.getUrl('gcServerV3') + '/tracking?idPedido=' + order;

			this.http.get<any>(url, this.headerGL).subscribe((json: any) => {
				resolve(json);
			},
				(error: any) => {
					reject(error);
				});
		});

		return promise;
	}
	documentLogistic(order: string): Promise<OrderInterface | undefined> {

		const promise = new Promise<OrderInterface>((resolve, reject) => {
			const url: string = this.getUrl('sapApiServerV4') + '/document-logistic/client?orderNumber=' + order;

			this.http.get<any>(url, this.headerGL).subscribe((json: any) => {
				if (json.statusCode != 200) {
					reject(json.message);
					return;
				}

				resolve(json.data);
			},
				(error: any) => {
					error.message = error?.error?.message ?? error?.message;
					reject(error);
				});
		});

		return promise;
	}
	documentFlow(order: string): Promise<OrderInterface | undefined> {

		const promise = new Promise<OrderInterface>((resolve, reject) => {
			const url: string = this.getUrl('sapApiServerV4') + '/document-flow?orderNumber=' + order;

			this.http.get<any>(url, this.headerGL).subscribe((json: any) => {
				if (json.statusCode != 200) {
					reject(json.message);
					return;
				}

				resolve(json.data);
			},
				(error: any) => {
					error.message = error?.error?.message ?? error?.message;
					reject(error);
				});
		});

		return promise;
	}

	findClientById(id: number): Promise<{ codigo: string, nombreFiscal: string, id: number, moneda: string }> {

		const promise = new Promise<{ codigo: string, nombreFiscal: string, id: number, moneda: string }>((resolve, reject) => {
			const url: string = this.getUrl('gcServer') + '/collection/clients/' + id;

			this.http.get<any>(url, this.headerGL).subscribe((json: any) => {
				resolve(json);
			},
				(error: any) => {

					reject(error);
				});
		});

		return promise;
	}

	documentLogisticGC(order: string): Promise<DocGCInterface[] | undefined> {

		const promise = new Promise<DocGCInterface[]>((resolve, reject) => {
			const url: string = this.getUrl('gcServer') + '/successful-delivery/client?order=' + order;

			this.http.get<any>(url, this.headerGL).subscribe((json: any) => {
				if (json.statusCode != 200) {
					reject(json.message);
					return;
				}

				resolve(json.data[0].attachments);
			},
				(error: any) => {
					error.message = error?.error?.message ?? error?.message;
					reject(error);
				});
		});

		return promise;
	}



	company(): Promise<Company> {

		const promise = new Promise<Company>((resolve, reject) => {

			const url: string = this.getUrl('gcServerV3') + '/client/info';

			this.http.get<any>(url, this.headerGL).subscribe((json: any) => {
			console.log("🚀 ~ ApiService ~ this.http.get<any> ~ json:", json)


				if (json.message) {
					reject(new Error(json.message));
				}
				else if (json.data && json.data.length > 0) {
					resolve(json.data[0]);
				}
				else {
					reject(new Error('Ocurrio un error'));
				}
			},
				(error: any) => {
				console.log("🚀 ~ ApiService ~ promise ~ error:", error)

					reject(new Error(error));
				});
		});

		return promise;
	}


	companyAnonymous(code: string): Promise<Company> {

		const promise = new Promise<Company>((resolve, reject) => {

			const url: string = this.getUrl('gcServerV3') + '/client/validate_existence?code=' + code;

			this.http.get<any>(url).subscribe((json: any) => {

				console.log('json', json)


				if (json.message) {
					reject(new Error('Cliente invalido'));
				}
				else if (json) {
					resolve(json);
				}
				else {
					reject(new Error('Cliente invalido'));
				}
			},
				(error: any) => {

					reject(new Error(error));
				});
		});

		return promise;
	}

	companyInformation(code: string): Promise<Company> {

		const promise = new Promise<Company>((resolve, reject) => {

			const url: string = this.getUrl('gcServerV3') + '/client/contacts?client_id=' + code;

			this.http.get<any>(url, this.headerGL).subscribe((json: any) => {


				if (json.message) {
					reject();
				}
				else if (json.data) {
					resolve(json.data);
				}
				else {
					reject();
				}
			},
				(error: any) => {

					reject(error);
				});
		});

		return promise;
	}


	shopping(code?: string): Promise<Shopping> {

		if (code) {
			return this._shopping(code);
		}
		else {
			const promise = new Promise<Shopping>((resolve, reject) => {

				this.company().then(company => {

					if (company) {
						this._shopping(company.id).then(shopping => {

							resolve(shopping);

						}).catch(reason => {
							reject();
						});
					}
					else {
						reject();
					}

				}).catch(reason => {

					reject();
				});
			});

			return promise;
		}
	}

	_shopping(code: string): Promise<Shopping> {

		const promise = new Promise<Shopping>((resolve, reject) => {


			const url: string = this.getUrl('gcServerV3') + '/client/contacts?client_id=' + code;

			this.http.get<any>(url, this.headerGL).subscribe((json: any) => {

				console.log(json);


				if (json.error) {
					reject();
				}
				else if (json.data) {
					resolve(json.data);
				}
				else {
					reject();
				}
			},
				(error: any) => {

					console.log(error);

					reject(error);
				});
		});

		return promise;
	}


	begin(year, month): string {

		var date = new Date();

		date.setFullYear(year);
		date.setMonth(month - 1);

		const begin = this.stringDate(this.datesService.getFirstMonthDay(date));

		return begin;
	}

	end(year, month): string {

		var date = new Date();

		date.setFullYear(year);
		date.setMonth(month - 1);

		const end = this.stringDate(this.datesService.getLastMonthDay(date));

		return end;
	}

	hoy(): string {

		var date = new Date();
		const hoy = this.stringDate(date);

		return hoy;
	}

	hoyEightDay(): string {

		var date = new Date();
		var semanaEnMilisegundos = 1000 * 60 * 60 * 24 * 8;

		let resta = date.getTime() - semanaEnMilisegundos;
		let hoy_eight_day = new Date(resta);
		const hoy_eight_day_str = this.stringDate(hoy_eight_day);
		console.log("hoy_eight_day_str", hoy_eight_day)
		console.log("hoy_eight_day_str", hoy_eight_day_str)

		return hoy_eight_day_str;
	}

	initYear(): string {
		var hoy = new Date((new Date()).getFullYear(), 0, 1);
		const date = this.stringDate(hoy);

		return date;
	}

	companiesParam(company: Company): string {

		var companies = '';

		if (this.authService.user.admin || this.authService.user.role == 'admin') {
			companies += company.id;

			for (var _company of company.subempresas) {
				companies += ',%20' + _company.id;
			}
		}
		else {
			for (var _companyID of this.authService.user.companies || []) {
				companies += (companies == '' ? _companyID : ',%20' + _companyID);
			}
		}

		return companies;
	}

	dataDashboard(typeDashboard, fechaI, fechaF, year, yearMonth, month, yearOders, clientCode?: string): Promise<Purchase[]> {

		var promise = new Promise<Purchase[]>((resolve, reject) => {
			if(!clientCode) clientCode = this.authService.user.code
			var url = this.getUrl('gcServer') + '/customer-portal/dasboard?customerCode=' + clientCode + '&dateI=' + fechaI + '&dateF=' + fechaF + '&year=' + year + '&month=' + month + "&yearMonth=" + yearMonth + "&yearOders=" + yearOders
			this.http.get<any>(url, this.headerGL).subscribe((json: any) => {

				if (json.error) {
					reject();
				}
				else {
					resolve(json.data);
				}
			},
				(error: any) => {

					reject(error);
				});
		});

		return promise;
	}



	stringDate(date: Date): string {

		return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
	}

	/// productos
	setProduct(id: string, data: ProductData): Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			const url = this.getUrl('apiPortal') + '/products/'+id;
			this.http.patch<any>(url, data, this.headerC).subscribe((response: any) => {
				if (response.error) {
					reject();
				}
				else {
					resolve(response.data);
				}
			},
			(error: any) => {
				reject(error);
			});
		});

		return promise;
	}

	addProduct(data: ProductData): Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			const url = this.getUrl('apiPortal') + '/products';
			this.http.post<any>(url, data, this.headerC).subscribe((response: any) => {
				if (response.error) {
					reject();
				}
				else {
					resolve(response.data);
				}
			},
			(error: any) => {
				reject(error);
			});
		});

		return promise;
	}

	deleteProduct(id: string): Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			const url = this.getUrl('apiPortal') + '/products/'+id;
			this.http.delete<any>(url, this.headerC).subscribe((response: any) => {
				if (response.error) {
					reject();
				}
				else {
					resolve(response.data);
				}
			},
			(error: any) => {
				reject(error);
			});
		});

		return promise;
	}

	getProducts(): Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			const url = this.getUrl('apiPortal') + '/products';
			this.http.get<any>(url, this.headerC).subscribe((response: any) => {
				if (response.error) {
					reject();
				}
				else {
					resolve(response.data);
				}
			},
			(error: any) => {
				reject(error);
			});
		});

		return promise;
	}


	/// usuarios data

	getUsers(): Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			const url = this.getUrl('apiPortal') + '/users';
			this.http.get<any>(url, this.headerC).subscribe((response: any) => {
				if (response.error) {
					reject();
				}
				else {
					resolve(response.data);
				}
			},
			(error: any) => {
				reject(error);
			});
		});

		return promise;
	}

	getUser(): Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			const url = this.getUrl('apiPortal') + '/users/am';
			this.http.get<any>(url, this.headerC).subscribe((response: any) => {
				if (response.error) {
					reject();
				}
				else {
					resolve(response.data);
				}
			},
			(error: any) => {
				reject(error);
			});
		});

		return promise;
	}

	setUser(id: string, data: UserData): Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			const url = this.getUrl('apiPortal') + '/users/'+id;
			this.http.patch<any>(url, data, this.headerC).subscribe((response: any) => {
				if (response.error) {
					reject();
				}
				else {
					resolve(response.data);
				}
			},
			(error: any) => {
				reject(error);
			});
		});

		return promise;
	}

	verifiUserName(userName: string): Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			const url = this.getUrl('apiPortal') + '/users/verify/'+userName;
			this.http.get<any>(url).subscribe((response: any) => {
				if (response.error) {
					reject();
				}
				else {
					resolve(response.data);
				}
			},
			(error: any) => {
				reject(error);
			});
		});

		return promise;
	}

}