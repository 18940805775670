<div *ngIf="!loading" class="reset-password" ngClass.xs="xs" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2em">

	<h4>Recuperar contraseña</h4>

	<p>Si confirma esta operación, se le asignará una contraseña nueva, la cual será enviada a su dirección de email. Puede modificar su contraseña cuando desee desde su perfil.</p>

	<div class="full-width" fxLayout="column" fxLayoutAlign="start start">

		<form class="full-width" [formGroup]="form" id="resetPasswordForm" (ngSubmit)="save()" autocomplete="off" fxLayout="column" fxLayoutAlign="start center">

			<mat-form-field class="full-width primary" appearance="outline" color="primary">
				<mat-label>USUARIO</mat-label>
				<input matInput type="text" formControlName="username" maxlength="100" autocomplete="off">
				<mat-error *ngIf="form.get('username').hasError('required')">
					Usuario requerido
				</mat-error>
				<mat-error *ngIf="form.get('username').hasError('invalid')">
					Usuario inválido
				</mat-error>
			</mat-form-field>


			<br/><br/>

			<mat-spinner *ngIf="saving" color="primary" diameter="40"></mat-spinner>

			<button *ngIf="!saving" class="main-button full-width" mat-flat-button color="primary" form="resetPasswordForm" type="submit">
				<img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">CONFIRMAR
			</button>

		</form>

	</div>

</div>


<div *ngIf="loading" class="spinner-container" fxLayout="column" fxLayoutAlign="center center">
	<mat-spinner color="primary" diameter="40"></mat-spinner>
</div>

