import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

	constructor() {

	}


	setItem(key: string, value: string) {

		sessionStorage.setItem(key, value);
	}

	getItem(key: string, defaultValue?: string) : string {

		return sessionStorage.getItem(key) || defaultValue;
	}
	
}
