import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import {
  AngularFirestoreModule,
  AngularFirestore,
} from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireMessagingModule } from '@angular/fire/messaging';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { MaterialFileInputModule } from 'ngx-material-file-input';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { environment } from 'src/environments/environment.local';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { PdfViewerModule } from 'ng2-pdf-viewer';
//import {PdfViewerModule} from '@syncfusion/ej2-angular-pdfviewer';

import { HomeComponent } from 'src/app/home/home.component';
import { HomebComponent } from 'src/app/home/homeb/homeb.component';
import { LoginComponent } from 'src/app/login/login.component';
import { SignupComponent } from 'src/app/signup/signup.component';
import { DialogComponent } from 'src/app/dialog/dialog.component';
import { IconComponent } from 'src/app/components/icon/icon.component';
import { ErrorComponent } from 'src/app/error/error.component';
import { MessageComponent } from 'src/app/message/message.component';
import { NavbarComponent } from 'src/app/navbar/navbar.component';
import { CustomersComponent } from 'src/app/home/customers/customers.component';
import { NewCustomerComponent } from 'src/app/dialog/new-customer/new-customer.component';
import { ProductsComponent } from 'src/app/home/products/products.component';
import { ProductsAdminComponent } from 'src/app/home/products/productsadmin.component';
import { OffersComponent } from 'src/app/home/offers/offers.component';
import { NewProductComponent } from 'src/app/dialog/new-product/new-product.component';
import { NewOfferComponent } from 'src/app/dialog/new-offer/new-offer.component';
import { ProfileComponent } from 'src/app/profile/profile.component';
import { ProfileEditComponent } from 'src/app/dialog/profile-edit/profile-edit.component';
import { PurchasesComponent } from 'src/app/home/purchases/purchases.component';
import { PurchaseComponent } from 'src/app/home/purchase/purchase.component';
import { ClaimComponent } from 'src/app/home/claim/claim.component';
import { ProductPreviewComponent } from 'src/app/dialog/product-preview/product-preview.component';
import { UsersComponent } from 'src/app/home/users/users.component';
import { UserComponent } from 'src/app/dialog/user/user.component';
import { FinancesComponent } from 'src/app/home/finances/finances.component';
import { AccountComponent } from 'src/app/home/account/account.component';
import { InvoiceComponent } from 'src/app/home/invoice/invoice.component';
import { InvoicesComponent } from 'src/app/home/invoices/invoices.component';
import { OrdersComponent } from 'src/app/home/orders/orders.component';
import { OrderComponent } from 'src/app/home/order/order.component';
import { OfferPreviewComponent } from 'src/app/dialog/offer-preview/offer-preview.component';
import { ComingSoonComponent } from 'src/app/home/coming-soon/coming-soon.component';
import { ShoppingComponent } from 'src/app/home/shopping/shopping.component';
import { ResetPasswordComponent } from 'src/app/dialog/reset-password/reset-password.component';
import { ChangePasswordComponent } from 'src/app/dialog/change-password/change-password.component';
import { DocumentsComponent } from 'src/app/dialog/documents/documents.component';
import { ExpansionPanelComponent } from 'src/app/components/expansion-panel/expansion-panel.component';
import { AlertComponent } from 'src/app/dialog/alert/alert.component';
import { MultiSelectComponent } from 'src/app/components/multi-select/multi-select.component';
import { FormFieldComponent } from 'src/app/components/form-field/form-field.component';
import { MonthPickerComponent } from 'src/app/components/month-picker/month-picker.component';
import { SelectMonthComponent } from 'src/app/dialog/select-month/select-month.component';
import { ComboBoxComponent } from 'src/app/components/combo-box/combo-box.component';
import { DatePickerComponent } from 'src/app/components/date-picker/date-picker.component';
import { SelectDateComponent } from 'src/app/dialog/select-date/select-date.component';
import { FileInputComponent } from 'src/app/components/file-input/file-input.component';
import { TextareaComponent } from 'src/app/components/textarea/textarea.component';
import { HomeLayoutComponent } from './home/home-layout/home-layout.component';
import { PageOne } from 'src/app/pageone/pageone.component';
import { PageTwo } from 'src/app/pagetwo/pagetwo.component';
import { PageThree } from 'src/app/pagethree/pagethree.component';
import { PageFour } from 'src/app/pagefour/pagefour.component';

import { MatExpansionModule } from "@angular/material/expansion";
import { OrdersAdminComponent } from './home/orders/ordersadmin.component';
import { OrderAdminComponent } from './home/order/orderadmin.component';
import { SalesteamComponent } from './home/salesteam/salesteam.component';
import { MatCarouselModule } from '@ngbmodule/material-carousel';
import { ElementSkeletonComponent } from './components/element-skeleton/element-skeleton.component';
import { QuestionSupportInvoicesDialog } from './home/invoices/component/question-support-invoices';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HomebComponent,
    LoginComponent,
    SignupComponent,
    DialogComponent,
    IconComponent,
    ErrorComponent,
    MessageComponent,
    NavbarComponent,
    CustomersComponent,
    NewCustomerComponent,
    ProductsComponent,
    ProductsAdminComponent,
    OffersComponent,
    NewProductComponent,
    NewOfferComponent,
    ProfileComponent,
    ProfileEditComponent,
    PurchasesComponent,
    SalesteamComponent,
    PurchaseComponent,
    ClaimComponent,
    ProductPreviewComponent,
    UsersComponent,
    UserComponent,
    FinancesComponent,
    AccountComponent,
    InvoiceComponent,
    InvoicesComponent,
    OrdersComponent,
    OrdersAdminComponent,
    OrderComponent,
    OrderAdminComponent,
    OfferPreviewComponent,
    ComingSoonComponent,
    ShoppingComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    DocumentsComponent,
    ExpansionPanelComponent,
    AlertComponent,
    MultiSelectComponent,
    FormFieldComponent,
    MonthPickerComponent,
    SelectMonthComponent,
    ComboBoxComponent,
    DatePickerComponent,
    SelectDateComponent,
    FileInputComponent,
    TextareaComponent,
    HomeLayoutComponent,
    PageOne,
    PageThree,
    PageTwo,
    PageFour,
    ElementSkeletonComponent,
    QuestionSupportInvoicesDialog,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    MaterialFileInputModule,
    MatToolbarModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatMenuModule,
    MatStepperModule,
    MatSidenavModule,
    MatTabsModule,
    MatTooltipModule,
    MatListModule,
    MatDialogModule,
    MatRippleModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatBadgeModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    PdfViewerModule,
    MatCarouselModule.forRoot()
  ],
  entryComponents: [DialogComponent],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
