import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';


import * as firebase from 'firebase/app';
export { firebase };

import 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

	alreadyInitialized : boolean = false;

	constructor() {

	}

	init() {

	}


}

