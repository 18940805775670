import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewEncapsulation, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { SnackbarService } from '../../services/snackbar.service';
import { DialogService } from '../../services/dialog.service';
import { DatesService } from '../../services/dates.service';
import { AuthService } from '../../services/auth.service';
import { NumbersService } from '../../services/numbers.service';
import { SessionService } from '../../services/session.service';
import { DownloadService } from '../../services/download.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatInput } from '@angular/material/input';
import { MediaObserver } from '@angular/flex-layout';
import { Purchase } from '../../classes/purchase';
import { Observable } from 'rxjs';
import { MatDatepicker } from '@angular/material/datepicker';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Page } from '../../adapters/page';
import { Chart, Legend, registerables } from 'chart.js';
import { MatCarousel, MatCarouselComponent } from '@ngbmodule/material-carousel';
import { MatCard } from '@angular/material/card';
import { UsersService } from 'src/app/services/users.service';
import { map } from 'rxjs/operators';
import { type } from 'os';
import { newArray } from '@angular/compiler/src/util';
import { Company } from 'src/app/classes/company';




Chart.register(...registerables);

@Component({
	selector: 'app-home',
	templateUrl: './homeb.component.html',
	styleUrls: ['./homeb.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('expand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	]
})
export class HomebComponent implements OnInit {
	phone: string = '';
	@ViewChild('myChart') myChart: ElementRef;
	year: number = (new Date()).getFullYear();

	loading: boolean = true;

	moneda: string = "Moneda"


	monthDate: Date = new Date();
	oneYearAgo: Date = new Date();

	tipoUsuario: string = (this.authService.user.role == 'admin') ? 'ADMINISTRADOR' : '';
	usuarioSinPermisos: boolean = false;

	purcharsePrevYear: string = '0';
	purcharsePrevYearLabel: string = "";
	balancePending: string = '0';
	balancePendingLabel: string = "";
	indicador3: Observable<string> | string;
	indicadorText3: string = "";
	nameUser: string = '';
	slides = [];

	months = {
		1: "ENE",
		2: "FEB",
		3: "MAR",
		4: "ABR",
		5: "MAY",
		6: "JUN",
		7: "JUL",
		8: "AGO",
		9: "SEP",
		10: "OCT",
		11: "NOV",
		12: "DIC"
	}
	get user() { return this.authService.user; }

	images = ['steel-bar-red.svg', 'steel-bar-dark.svg', 'steel-bar.svg'];

	constructor(public api: ApiService,
		private dialogService: DialogService,
		public authService: AuthService,
		public datesService: DatesService,
		public numbersService: NumbersService,
		private snackbar: SnackbarService,
		private changeDetectorRef: ChangeDetectorRef,
		public media: MediaObserver,
		public usersService: UsersService,
		private downloadService: DownloadService,
		private sessionService: SessionService,
		private _page: Page) {

		this.loading = true;

		if (!this.changeDetectorRef['destroyed'])
			this.changeDetectorRef.markForCheck();
	}

	loadData() {
		this.loading = true;
		var monthF: string = (this.monthDate.getMonth() + 1).toString()
		var monthI: string = (this.oneYearAgo.getMonth() + 1).toString()

		let fechaF: string = this.monthDate.getFullYear().toString() + (monthF.length != 1 ? monthF : '0' + monthF)
		let fechaI: string = this.oneYearAgo.getFullYear().toString() + (monthI.length != 1 ? monthI : '0' + monthI)

		let year = this.monthDate.getFullYear() - 1
		let month = this.monthDate.getMonth() + 1;
		this.nameUser = this.authService.user.username
		this.api.company(this.authService.user.code).then(result => {
			if (result.moneda && result.moneda[0]) {
				this.moneda = result.moneda[0]['tipo']
			}
		});

		this.api.dataDashboard(this.tipoUsuario, fechaI, fechaF, year, this.monthDate.getFullYear(), month, this.monthDate.getFullYear(), this.companyCode).then(result => {

			let yearCurrentF: string = this.monthDate.getFullYear().toString() + "12"
			let yearCurrentI: string = this.monthDate.getFullYear().toString() + "01"

			this.api.dataDashboard(this.tipoUsuario, yearCurrentI, yearCurrentF, this.monthDate.getFullYear(), this.monthDate.getFullYear(), month, this.monthDate.getFullYear(), this.companyCode).then(resultCurrentYear => {

				if (result && resultCurrentYear) {
					var lastDate: Date;
					//mostrar grafica ad

					if (this.tipoUsuario == 'ADMINISTRADOR') {
						this.loadAdmin(result, resultCurrentYear);
						// let newResult: any[] = result as any[];
						// // this.indicador3 = newResult.salesForMonth.reduce((a, b) => { return a + b.tm }, 0)
						// this.indicador3 = newResult.reduce((total, item) => {
						// 	if (item && item.salesForMont) {
						// 		return total + item.salesForMont.reduce((a, b) => a + b.tm, 0);
						// 	}
						// 	return total;
						// }, 0);
						// return this.indicador3


					} else if (this.tipoUsuario == 'COMPRAS') {
						this.loadCompras(result);
					} else if (this.tipoUsuario == 'PAGOS') {
						this.loadPagos(result);
					}



					if (!this.changeDetectorRef['destroyed'])
						this.changeDetectorRef.markForCheck();
				}

			}).catch(reason => {
				this.loading = false;

				if (!this.changeDetectorRef['destroyed'])
					this.changeDetectorRef.markForCheck();
			});

		}).catch(reason => {

			this.loading = false;

			if (!this.changeDetectorRef['destroyed'])
				this.changeDetectorRef.markForCheck();
		});
		this.loading = false;
	}

	getImageRandom(prevImage) {
		let imageRandom;
		do {
			imageRandom = this.images[Math.floor(Math.random() * this.images.length)];
		} while (imageRandom === prevImage);

		return imageRandom;
	}

	chartInstance!: Chart;
	async loadCharts(responseData) {
		const labels = [];
		const data = [];

		const randomImages = [];
		let imagenPrev = '';
		responseData.salesForMont.forEach((key, index) => {
			imagenPrev = this.getImageRandom(imagenPrev);
			randomImages.push(imagenPrev);
			labels.push(this.months[key.mes].toUpperCase());
			data.push(key.tm);
		});

		const canvas = this.myChart.nativeElement as HTMLCanvasElement;
		const ctx = canvas.getContext('2d');
		await Promise.all(responseData.salesForMont.map(async (key: any, val: any) => {
			const img = await new Promise((resolve) => {
				const img = new Image();
				img.src = `/assets/images/${randomImages[val]}`;
				img.onload = () => {
					resolve(img);
				}
			})
			randomImages[val] = img;
		}));
		console.log('error not');
		if (this.chartInstance) {
			this.chartInstance.destroy();
		}
		this.chartInstance = new Chart(ctx, {
			type: 'bar',
			data: {
				labels: labels,
				datasets: [{
					label: 'TUS COMPRAS',
					data: data,
				}]
			},
			options: {
				plugins: {
					legend: {
						display: true,
						onClick: null,
					},
				},
				animation: {
					onComplete: () => {
						const gradient = ctx.createLinearGradient(0, 0, 0, 300);
						gradient.addColorStop(0, 'rgba(255, 255, 255, 0)');
						gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

						this.chartInstance.data.datasets[0].backgroundColor = gradient;
						this.chartInstance.update();
					}
				},
				scales: {
					y: {
						beginAtZero: true,
						grid: {
							display: false
						},
					},
					x: {
						grid: {
							display: false
						}
					}
				},
			},
			plugins: [
				{
					id: '1',
					beforeDraw: (chart: Chart) => {
						const dataset = chart.data.datasets[0];
						const meta = chart.getDatasetMeta(0);
						const rect = canvas.getBoundingClientRect();

						meta.data.forEach((bar, index) => {
							const x = bar.tooltipPosition().x - 20;
							const y = bar.tooltipPosition().y;
							const width = bar['width'] >= 40 ? 40 : bar['width'];
							const height = bar['height'];
							const img = randomImages[index];
							const label = dataset.data[index];
							ctx.drawImage(img, x, y, width, height);
						});
					}
				} as any
			]
		});
	}
	parseNumber(param: number | any): string {
		if (param == null) return '0';
		const num = typeof param === 'string' ? parseFloat(param) : param;
		if (num == null) return '0';

		if (isNaN(param)) {
			return "se esperaba number o string"
		}
		if (num >= 1000000) {
			return (num / 1000000).toFixed(1) + 'M'
		} else if (num >= 1000) {
			return (num / 1000).toFixed(1) + 'K'
		} else {
			return num.toString()
		}
	}

	async loadAdmin(responseData, responseCurrentYear) {
		console.log("🚀 ~ HomebComponent ~ loadAdmin ~ responseCurrentYear:", responseCurrentYear)
		try {
			console.log('responseData', responseData);
			this.loadCharts(responseData);
			this.indicador3 = this.parseNumber(responseCurrentYear.salesForMont.reduce((a, b) => {
				console.log(b);
				return a + b.tm
			}, 0))
			console.log("🚀 ~ HomebComponent ~ loadAdmin ~ this.indicador3:", this.indicador3)
			this.indicadorText3 = "TM COMPRADAS ESTE AÑO";
			this.purcharsePrevYear = this.parseNumber(responseData?.salesYear[0]?.tm ?? 0)
			this.purcharsePrevYearLabel = "TM COMPRADAS AÑO ANTERIOR";
			this.balancePending = "$" + this.parseNumber(responseData?.balanceDue[0]?.totaldolar ?? 0);
			this.balancePendingLabel = "SALDO PENDIENTE A CANCELAR";

			// await this.slides.push({
			// 	img:`https://multigroup.com/wp-content/uploads/2022/10/columna-o-solera-de-corona-1-1.jpg`,
			// 	text: "PEDIDOS PENDIENTES DE DESPACHO",
			// 	valor: new Intl.NumberFormat('es-MX').format(responseData.totalPendingOrders.count)
			// })

			// await this.slides.push({
			// 	img:`https://multigroup.com/wp-content/uploads/2022/10/columna-o-solera-de-corona-1-1.jpg`,
			// 	text: "VENTA EN $ MES ACTUAL",
			// 	valor: "$"+ new Intl.NumberFormat('es-MX').format(responseData?.salesMonth[0]?.usd ?? 0)
			// })

			// await this.slides.push({
			// 	img:`https://multigroup.com/wp-content/uploads/2022/09/DSC_2427-scaled.jpg`,
			// 	text: "$ COMPRADOS AÑO ANTERIOR",
			// 	valor: "$" + new Intl.NumberFormat('es-MX').format(responseData?.salesYear[0]?.us ?? 0d)
			// })

			// await this.slides.push({
			// 	img:`https://multigroup.com/wp-content/uploads/2022/09/C0107T01.jpg`,
			// 	text: "% PEDIDOS ENTREGADOS A TIEMPO AÑO ACTUAL",
			// 	valor: responseData.ordersDeliveredOnTime[0].porcentaje+"%"
			// })

			// await this.slides.push({
			// 	img:`https://multigroup.com/wp-content/uploads/2022/10/columna-o-solera-de-corona-1-1.jpg`,
			// 	text: "NUMERO DE RECLAMOS",
			// 	valor: new Intl.NumberFormat('es-MX').format(responseData.getTotalClaimYear[0].total)
			// })

			// await this.slides.push({
			// 	img:`https://multigroup.com/wp-content/uploads/2022/10/columna-o-solera-de-corona-1-1.jpg`,
			// 	text: "PRODUCTO MAS SOLICITADO",
			// 	valor: responseData?.SKUMoreBuy[0]?.desc_product ?? 0
			// })

			// await this.slides.push({
			// 	img:`https://multigroup.com/wp-content/uploads/2022/10/columna-o-solera-de-corona-1-1.jpg`,
			// 	text: "NUEVO PRODUCTO ADQUIRIDO",
			// 	valor: responseData?.NewProduct[0]?.desc_product ?? 0
			// })

			await this.slides.push({
				img: '../../../assets/images/products/Graficos/Costanera negra.png',
				text: "",
				valor: ""
			})
			await this.slides.push({
				img: '../../../assets/images/products/Graficos/Electromalla.png'
			})
			// await this.slides.push({
			// 	img: '../../../assets/images/products/Graficos/Lamina desplegada.png'
			// })
			await this.slides.push({
				img: '../../../assets/images/products/Graficos/Lamina perforada.png'
			})
			await this.slides.push({
				img: '../../../assets/images/products/Graficos/Tuberia cerca.png'
			})
			await this.slides.push({
				img: '../../../assets/images/products/Graficos/Tuberia conduccion.png'
			})
			await this.slides.push({
				img: '../../../assets/images/products/Graficos/Tuberia estructural.png'
			})
			await this.slides.push({
				img: '../../../assets/images/products/Graficos/Tuberia galvanizada.png'
			})
			await this.slides.push({
				img: '../../../assets/images/products/Graficos/Tuberia industrial.png'
			})
			// await this.slides.push({
			// 	img: '../../../assets/images/products/Graficos/Tuberia pozo.png'
			// })
			// await this.slides.push({
			// 	img: '../../../assets/images/products/Graficos/Tubo proceso.png'
			// })
			await this.slides.push({
				img: '../../../assets/images/products/Graficos/Varilla corrugada.png'
			})
			await this.slides.push({
				img: '../../../assets/images/products/Graficos/Varilla Lisa.png'
			})
			await this.slides.push({
				img: '../../../assets/images/products/Graficos/Varilla.png'
			})
			await this.slides.push({
				img: '../../../assets/images/products/Graficos/Viga.png'
			})
			if (this.slides.length >= 6) {
				const shuffledSlides = this.shuffleArray(this.slides.slice())
				this.slides = shuffledSlides.slice(0, 6)
			} else {
				console.log("No hay suficientes imagenes")
			}
			this.loading = false;
		} catch (error) {
			this.snackbar.show(error?.message ?? 'Ocurrio un error al cargar el dashboard.');
			this.loading = false;
		}

	}


	async loadCompras(responseData) {
		let labels = [];
		let data = [];

		await responseData.salesForMont.forEach((key: any, val: any) => {
			labels.push(this.months[key.mes]);
			data.push(key.usd)
		})

		var myChart = new Chart("myChart", {
			type: 'bar',
			data: {
				labels: labels,
				datasets: [{
					label: 'VENTA EN $$ POR MES DEL AÑO ACTUAL',
					data: data,
					backgroundColor: [
						'#ea0029',
					],
					borderColor: [
						'rgba(255, 99, 132, 1)',
					],
					borderWidth: 1
				}]
			},
			options: {
				scales: {
					y: {
						beginAtZero: true
					}
				}
			}
		});

		this.purcharsePrevYear = this.parseNumber(responseData?.salesYear[0]?.tm ?? 0)
		this.purcharsePrevYearLabel = "TM COMPRADAS AÑO ANTERIOR";
		this.balancePending = "$" + this.parseNumber(responseData?.balanceDue[0]?.totaldolar ?? 0);
		this.balancePendingLabel = "SALDO PENDIENTE A CANCELAR";
		this.indicador3 = new Observable(observer => {
			observer.next('0');
			observer.complete();
		});
		this.indicadorText3 = "PEDIDOS PENDIENTES DE DESPACHO";

		await this.slides.push({
			img: `https://multigroup.com/wp-content/uploads/2022/10/columna-o-solera-de-corona-1-1.jpg`,
			text: "VENTA EN $ MES ACTUAL",
			valor: "$" + new Intl.NumberFormat('es-MX').format(responseData?.salesMonth[0]?.usd ?? 0)
		})

		await this.slides.push({
			img: `https://multigroup.com/wp-content/uploads/2022/09/DSC_2427-scaled.jpg`,
			text: "$ COMPRADOS AÑO ANTERIOR",
			valor: "$" + new Intl.NumberFormat('es-MX').format(responseData?.salesYear[0]?.usd ?? 0)
		})

		await this.slides.push({
			img: `https://multigroup.com/wp-content/uploads/2022/09/C0107T01.jpg`,
			text: "% PEDIDOS ENTREGADOS A TIEMPO AÑO ACTUAL",
			valor: (responseData?.ordersDeliveredOnTime[0]?.porcentaje ?? 0) + "%"
		})

		await this.slides.push({
			img: `https://multigroup.com/wp-content/uploads/2022/10/columna-o-solera-de-corona-1-1.jpg`,
			text: "NUMERO DE RECLAMOS",
			valor: (responseData?.getTotalClaimYear[0]?.total ?? 0)
		})

		await this.slides.push({
			img: `https://multigroup.com/wp-content/uploads/2022/10/columna-o-solera-de-corona-1-1.jpg`,
			text: "PRODUCTO MAS SOLICITADO",
			valor: responseData?.SKUMoreBuy[0]?.desc_product ?? 0
		})

		await this.slides.push({
			img: `https://multigroup.com/wp-content/uploads/2022/10/columna-o-solera-de-corona-1-1.jpg`,
			text: "NUEVO PRODUCTO ADQUIRIDO",
			valor: responseData?.NewProduct[0]?.desc_product ?? 0
		})


		this.loading = false;

	}

	shuffleArray(array: any[]) {
		let carousel = array.length;
		let randomCarousel;
		while (carousel !== 0) {
			randomCarousel = Math.floor(Math.random() * carousel)
			carousel--

			[array[carousel], array[randomCarousel]] = [array[randomCarousel], array[carousel]]
		}
		return array
	}


	async loadPagos(responseData) {
		let labels = [];
		let data = [];

		await responseData.salesForMont.forEach((key: any, val: any) => {
			labels.push(key.mes);
			data.push(key.usd)
		})

		var myChart = new Chart("myChart", {
			type: 'bar',
			data: {
				labels: labels,
				datasets: [{
					label: 'VENTA EN $$ POR MES DEL AÑO ACTUAL',
					data: data,
					backgroundColor: [
						'#ea0029',
					],
					borderColor: [
						'rgba(255, 99, 132, 1)',
					],
					borderWidth: 1
				}]
			},
			options: {
				scales: {
					y: {
						beginAtZero: true
					}
				}
			}
		});

		this.purcharsePrevYear = "$" + this.parseNumber(responseData?.balanceDue[0]?.total ?? 0);
		this.purcharsePrevYearLabel = "SALDO PENDIENTE A CANCELAR";
		this.balancePending = "0";
		this.balancePendingLabel = "NUMERO DE FACTURAS PENDIENTES";
		this.indicador3 = new Observable(observer => {
			observer.next('0');
			observer.complete();
		});
		this.indicadorText3 = "DIAS DE ATRAZO EN LA MAYOR FACTURA";


		await this.slides.push({
			img: `https://multigroup.com/wp-content/uploads/2022/09/DSC_2427-scaled.jpg`,
			text: "$ COMPRADOS AÑO ANTERIOR",
			valor: "$" + new Intl.NumberFormat('es-MX').format(responseData?.salesYear[0]?.usd ?? 0)
		})

		await this.slides.push({
			img: `https://multigroup.com/wp-content/uploads/2022/10/columna-o-solera-de-corona-1-1.jpg`,
			text: "PRODUCTO MAS SOLICITADO",
			valor: responseData?.SKUMoreBuy[0]?.desc_product ?? 0
		})

		await this.slides.push({
			img: `https://multigroup.com/wp-content/uploads/2022/10/columna-o-solera-de-corona-1-1.jpg`,
			text: "NUEVO PRODUCTO ADQUIRIDO",
			valor: responseData?.NewProduct[0]?.desc_product ?? 0
		})

		this.loading = false;

	}



	ngOnInit() {
		this.loadCompanies();
		this.api.company(this.authService.user.code).then(company => {
			if (company && company['codigo_entidad'] == '6000') this.phone = '+507 3095353';
			else this.phone = '+502 23205555';
		});
		this.oneYearAgo.setFullYear(this.oneYearAgo.getFullYear() - 1, this.oneYearAgo.getMonth() + 1, 1);


		if (this.tipoUsuario !== '') {
			this.loadData();
		} else {
			this.nameUser = this.authService.user.username

			if (!this.authService.user.modules) {
				this.usuarioSinPermisos = true;
			}

			this.authService.user.modules.forEach(module => {
				if (module == 'dashboard_compras_') {
					this.tipoUsuario = 'COMPRAS';
					this.loadData();
				} else if (module == 'dashboard_pagos_') {
					this.tipoUsuario = 'PAGOS';
					this.loadData();
				} else if (module == 'dashboard') {
					this.tipoUsuario = 'ADMINISTRADOR';
					this.loadData();
				}
			});
		}

	}

	companies: Company[] = [];
	companyCode!: string;
	societySelected: string;
	loadCompanies() {
		this.api.company(this.authService.user.code).then(company => {
			if (company.moneda && company.moneda[0]) {
				this.moneda = company.moneda[0]['tipo']
			}
			this.companyCode = this.authService.user.code;
			if (company) {
				this.companies.push(company);

				for (var subcompany of (company.subempresas || [])) {
					if (this.authService.user.companies.includes(subcompany.id))
						this.companies.push(subcompany);
				}
			}
		});
	}

	async onSelectSociety(company: Company) {
		this.loading = true;
		const client = await this.api.findClientById(Number(company.id));
		this.companyCode = client.codigo;
		this.loadData();
	}
}
