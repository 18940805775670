export const environment = {
	production: false,
	environmentName: "local",
	firebase: {
		apiKey: "AIzaSyA2PJvXjyFOxaDgECkKFPlHk12GKH33oUs",
		authDomain: "multigroup-sa.firebaseapp.com",
		databaseURL: "https://multigroup-sa.firebaseio.com",
		projectId: "multigroup-sa",
		storageBucket: "multigroup-sa.appspot.com",
		messagingSenderId: "123084572857",
		appId: "1:123084572857:web:dd07e4caa23c37c8717368"
	},
	tokenBass: "b6dd0e310fcdb00f5d4c6377fb5fa3041171ab42",
    gcServer: {
        protocol: "https",
        ip: "api.gc.multigroup.com",
        port: "3000",
        version: "api/v2",
    },
    gescomBaasServer: {
        protocol: "https",
        ip: "gc.multigroup.com",
        port: "80",
        version: "baas/v1",
    },
    gescomRestApiServer: {
        protocol: "https",
        ip: "gc.multigroup.com",
        port: "80",
        version: "restapi/v1",
    },
    baasBaasServer: {
        protocol: "https",
        ip: "baas.multigroup.com",
        port: "80",
        version: "baas/v1",
    },
    baasRestApiServer: {
        protocol: "https",
        ip: "baas.multigroup.com",
        port: "80",
        version: "restapi/v1",
    },
    sapApiServer: {
        protocol: "https",
        ip: "api.sap.multigroup.com",
        port: "3000",
        version: "api/v1",
    },
};
