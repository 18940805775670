import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DialogService } from '../../services/dialog.service';
import { DatesService } from '../../services/dates.service';
import { AuthService } from '../../services/auth.service';
import { RouterService } from '../../services/router.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MediaObserver } from '@angular/flex-layout';
import { Offer } from '../../classes/offer';
import { Subscription } from 'rxjs';
import { ActivationEnd } from '@angular/router';
import { Page } from '../../adapters/page';
import { utils } from '../../adapters/utils';


@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class OffersComponent implements OnInit {

	year : number = (new Date()).getFullYear();

	_loading : boolean = true;

	set loading(value: boolean) {this._loading = value;}

	get loading() : boolean {return this._loading;}

	displayedColumns: string[];
	dataSource: MatTableDataSource<Offer> = new MatTableDataSource([]);

	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild(MatSort, {static: true}) sort: MatSort;

	mediaSubscription : Subscription;
	sourceSubscription : Subscription;


	page : number = 0;
	pageSize : number = 10;

	admin : boolean = false;

	videoURL : string = 'https://firebasestorage.googleapis.com/v0/b/multigroup-sa.appspot.com/o/multigroup.mp4?alt=media&token=3fddeaf2-4ee9-4390-ad19-9e88a7074ea7';


	form : FormGroup



	constructor(private dialogService: DialogService,
				private formBuilder: FormBuilder,
				public authService: AuthService,
				public datesService: DatesService,
				private changeDetectorRef: ChangeDetectorRef,
				private routerService: RouterService,
				public media: MediaObserver,
				private _page: Page) {

		this._page.actionBarHidden = true;

		this.form = this.formBuilder.group({
			search: ['', []]
		});

		this.loading = true;

		this.router.events.subscribe(event => {

			if (event instanceof ActivationEnd)
			{
				if (event.snapshot.data.admin == true)
				{
					this.admin = true;
				}

				this.updateColumns();

				this.mediaSubscription = this.media.asObservable().subscribe(change => {
					this.updateColumns();
				});

				if (!this.changeDetectorRef['destroyed'])
					this.changeDetectorRef.markForCheck();

				this.loadData();
			}
		});
	}


	get router() {return this.routerService.router;}


	get xs() : boolean {
		return this.media.isActive('xs');
	}


	updateColumns() {

		if (this.media.isActive('gt-xs'))
		{
			this.displayedColumns = ['title', 'date', 'expiration'];
		}
		else
		{
			this.displayedColumns = ['title'];
		}

		if (this.admin == true)
			this.displayedColumns.push('actions');
	}


	loadData() {

		this.updateColumns();
		this.loading = false;
	}



	ngOnInit() {

		if (this.paginator)
		{
			this.paginator._intl.itemsPerPageLabel = "Ofertas por página:";
			this.paginator._intl.firstPageLabel = "Primera página";
			this.paginator._intl.previousPageLabel = "Página anterior";
			this.paginator._intl.nextPageLabel = "Página siguiente";
			this.paginator._intl.lastPageLabel = "Última página";

			this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {

				if (length == 0 || pageSize == 0) 
				{
					return `0 de ${length}`;
				}

				length = Math.max(length, 0);
				const startIndex = page * pageSize;
				const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
				return `${startIndex + 1} – ${endIndex} de ${length}`;
			};

			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		}

		this.loadData();
	}


	ngOnDestroy() {

		if (this.mediaSubscription) this.mediaSubscription.unsubscribe();
		if (this.sourceSubscription) this.sourceSubscription.unsubscribe();
	}


	newOfferDialog() {

		this.dialogService.openDialog({
			title: 'Nueva oferta',
			type: 'newOffer'
		});
	}


	deleteOfferDialog(offer: Offer) {

		this.dialogService.openDialog({
			title: 'Eliminar oferta',
			text: '¿Seguro que desea eliminar la oferta \"'+offer.title+'\"?',
			type: 'Warn',
			confirm: this.deleteOffer.bind(this),
			object: offer
		});
	}

	deleteOffer(offer: Offer) {

		
	}


	editOfferDialog(offer: Offer) {

		this.dialogService.openDialog({
			type: 'newOffer',
			object: {offer: offer}
		});
	}


	openPreviewDialog(offer: Offer) {

		this.dialogService.openDialog({
			type: 'offerPreview',
			object: {offer: offer}
		});
	}


	trackByFn(index, item) {
		return item.id;
	}


	applyFilter(filterValue: string) {

		this.dataSource.filterPredicate = (offer: Offer, filter: string) => {
			return !filter ||
					(offer.title && offer.title.toLowerCase().replace(/\s/g,'').indexOf(filter.toLowerCase().replace(/\s/g,'')) >= 0)
		};

		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	actions = [
		{name: 'Editar', action: this.editOfferDialog.bind(this)},
		{name: 'Eliminar', action: this.deleteOfferDialog.bind(this)}
	];


	openURL() {

		utils.openUrl("https://multigroup.com/");
	}

}
