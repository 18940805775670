<mat-card class="custum-card">
	<h1>Tu socio en acero</h1>

	<div class="about-wrap">

		<div class="video-box">
			<video controls id="videoplayer"
				autoplay
				width="100%"
				height="600"
				[src]="videoURL"
				title="YouTube video player"
				frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				allowfullscreen
			></video>
		</div>

		<div class="about-info">
            <div class="about-left">
              <h3>Más De 30 años de experiencia en la industria.</h3>
              <p>
                MULTIGROUP se ha posicionado como el grupo líder en acero de la región. Ofreciendo la más amplia variedad de productos derivados del acero. MULTIGROUP cuenta con 3 centros productivos, 2 en Guatemala y 1 en Nicaragua, equipados con tecnología de clase mundial operando bajo las más estrictas normas de calidad propias de la industria.
              </p>
            </div>
            <div class="about-right">
              <a href="https://multigroup.com/" target="_blank">
				<button>
                <img src="/assets/images/multi_arrow.png" />
				visita nuestra página
			</button>
			  </a>
            </div>
        </div>


	</div>

</mat-card>