<mat-card class="custum-card">

	<h1>PRODUCTOS</h1>

			
			<p class="empty-text" *ngIf="!loading && dataSource.data?.length < 1">
				No hay productos cargados
			</p>

			<mat-spinner *ngIf="loading" fxFlexAlign="center" color="primary" diameter="40"></mat-spinner>


			<div [ngClass]="{'hidden': loading || dataSource.data?.length < 1}" class="product-box-wrap" fxLayout="row">

				<!--<a *ngFor="let product of dataSource.data" 
				(click)="openPreviewDialog(product)" 
				[routerLink]="" 
				class="product-box" style="background-image: url('{{product.image}}');">
					<div class="product-layer"></div>
					<div class="product-info">
						<p>{{product.title}}</p>
						<span >Ver Productos</span>
					</div>
				</a>-->

				<a *ngFor="let product of dataSource.data"  [routerLink]="" 
					(click)="openWebMulti(product.file)"
					class="product-box" [ngClass]="{'product-full' : product.full == true }"  >
					<div
					  class="product-img"
					  style="background-image: url('{{product.image}}')"
					>
					  <div class="product-layer"></div>
					  <div class="product-info">
						<p>{{product.title}}</p>
						<span>Ver Productos</span>
					  </div>
					</div>
				</a>

			</div>

</mat-card>