<div class="offer-preview" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1em">

	<h4>{{offer.title}}</h4>

	<br/>

	<div class="full-width" fxLayout="column" fxLayoutAlign="start start">
		<mat-label>DESCRIPCIÓN</mat-label>
		<p class="text">{{offer.description}}</p>
	</div>

	<div *ngIf="offer.file" class="full-width" fxLayout="column" fxLayoutAlign="start start">
		<mat-label>ARCHIVO</mat-label>
		<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em">

			<span>1 archivo {{extension}}</span>

			<button type="button" mat-button color="primary" (click)="download()"><img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">Descargar</button>
		</div>
	</div>
</div>