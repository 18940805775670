import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserData, User } from '../../classes/user';
import { ValidatorsService } from '../../services/validators.service';
import { AuthService } from '../../services/auth.service';
import { SnackbarService } from '../../services/snackbar.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChangePasswordComponent implements OnInit {

	@Input('confirm') confirm : Function;
	@Input('close') close : Function;
	@Input('errorMessage') errorMessage : string;

	@Input() loading : boolean;
	@Output() loadingChange = new EventEmitter<boolean>();

	setLoading(value: boolean) {
		this.loading = value;
		this.loadingChange.emit(this.loading);
	}


	saving : boolean = false;


	form : FormGroup;

	constructor(private formBuilder: FormBuilder,
				private validatorsService: ValidatorsService,
				private authService: AuthService,
				private snackbar: SnackbarService) {

	}

	ngOnInit() {

		this.form = this.formBuilder.group({
			new: ['', [Validators.required]],
			password: ['', [Validators.required]],
			repeat: ['', [Validators.required]]
		});


		this.form.get('repeat').setValidators([
			Validators.required,
			this.validatorsService.checkPasswords(this.form.get('new'))
		]);
	}


	save() {

		if (this.form.invalid)
		{
			Object.keys(this.form.controls).forEach(key => {
				this.form.controls[key].markAsTouched();
			});
			
			return;
		}

		this.setLoading(true);

		this.authService.changePassword(this.form.get('new').value, this.form.get('password').value).then(result => {

			this.confirm();

			this.snackbar.show("Contraseña cambiada");

		}).catch(reason => {

			if (reason == 'Contraseña incorrecta')
			{
				this.snackbar.show("Contraseña incorrecta");
			}
			else
			{
				this.snackbar.show("Error al cambiar contraseña");
			}

			this.setLoading(false);
		});
	}

}
