<div class="signup" ngClass.xs="xs" fxLayout="column" fxLayoutAlign="start center">
	<a href="https://api.whatsapp.com/send?phone=50223205555" target="_blank" class="whatsapp-icon-wrap">
		<img class="whatsapp-stick-icon" src="../../assets/images/whatsapp.png" alt="" srcset="" />
	</a>
	<div class="content" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.5em">
		<img class="logo clickable" src="assets/images/multi.png" alt="Multi" routerLink="/login" />
		<h6>REGISTRAR NUEVA CUENTA</h6>
		<mat-spinner *ngIf="loading" color="accent" diameter="40"></mat-spinner>
		<form *ngIf="!loading" class="full-width" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1.25em"
			[formGroup]="form" (ngSubmit)="save()" autocomplete="off">
			<mat-form-field class="full-width" appearance="outline" color="accent">
				<app-icon matPrefix icon="company"></app-icon>
				<mat-label>CÓDIGO DE CLIENTE</mat-label>
				<input matInput formControlName="code" maxlength="100">
				<mat-error *ngIf="form.get('code').hasError('required')">Código requerido</mat-error>
				<div *ngIf="errorCodig" class="pass">Código de cliente es invalido</div>
			</mat-form-field>
			<mat-form-field *ngIf="method == 'signup'" class="full-width" appearance="outline" color="accent">
				<app-icon matPrefix icon="email"></app-icon>
				<mat-label>EMAIL</mat-label>
				<input matInput formControlName="email" maxlength="100">
				<mat-error *ngIf="form.get('email').hasError('required')">Email requerido</mat-error>
				<mat-error *ngIf="form.get('email').hasError('email')">Email inválido</mat-error>
			</mat-form-field>
			<mat-form-field *ngIf="method == 'signup'" class="full-width" appearance="outline" color="accent">
				<app-icon matPrefix icon="user"></app-icon>
				<mat-label>USUARIO</mat-label>
				<input matInput formControlName="username" maxlength="100">
				<mat-error *ngIf="form.get('username').hasError('required')">Usuario requerido</mat-error>
				<mat-error *ngIf="form.get('username').hasError('pattern')">Usuario inválido</mat-error>
			</mat-form-field>
			<mat-form-field *ngIf="method == 'signup'" class="full-width" appearance="outline" color="accent">
				<app-icon matPrefix icon="password"></app-icon>
				<mat-label>CONTRASEÑA</mat-label>
					<input matInput [type]="show['password'] == true ? 'text' : 'password'"
						formControlName="securePass" [(ngModel)]="firstPass" (input)="validatePass()" maxlength="100">
				<app-icon matSuffix class="clickable" [icon]="show['password'] == true ? 'hide' : 'show'"
					(click)="toggleShow($event, 'password')"></app-icon>
				<div class="pass">{{ validatePass() }}</div>
				<div class="pass-validate" *ngIf="validPass">
					<div *ngIf="form.get('securePass').hasError('validatePass')">
						La contraseña debe contener al menos un símbolo y un número.
					</div>
				</div>
			</mat-form-field>

			<mat-form-field *ngIf="method == 'signup'" class="full-width" appearance="outline" color="accent">
				<app-icon matPrefix icon="password"></app-icon>
				<mat-label>CONFIRMAR CONTRASEÑA</mat-label>
				<input matInput [type]="show['repeat'] == true ? 'text' : 'password'" formControlName="repeat"
					[(ngModel)]="secondPass" (input)="matchPass()" maxlength="100">
				<app-icon matSuffix class="clickable" [icon]="show['repeat'] == true ? 'hide' : 'show'"
					(click)="toggleShow($event, 'repeat')"></app-icon>
				<div class="pass">{{ matchPass() }}</div>
			</mat-form-field>

			<br *ngIf="method == 'fb_signup'" />
			<br *ngIf="method == 'fb_signup'" />
			<br/>
			
			
				<h4 style="margin-top: 20px; font-size: 12px;" *ngIf="authService.loginStatus == 'Error'">
					<img class="multi_arrow" style="font-size: 12px !important" src="assets/icons/advertencia-off.png">
					{{authService.errorMessage}}
				</h4>
			

			<button mat-flat-button type="submit" [disabled]="disabled">REGISTRARSE</button>
		</form>
		<span fxFlex></span>
	</div>
</div>