import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterService } from '../../services/router.service';
import { ApiService } from '../../services/api.service';
import { DatesService } from '../../services/dates.service';
import { DialogService } from '../../services/dialog.service';
import { MediaObserver } from '@angular/flex-layout';
import { Page } from '../../adapters/page';
import { DocGCInterface, OrderInterface } from 'src/app/classes/document-logistic';
import { Tracking } from 'src/app/classes/tracking';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { StateSharedService } from 'src/app/services/state-shared.service';
import { environment } from 'src/environments/environment';

export interface DoucmentElement {
	tipodedocumento: string;
	estado: string;
	file: string;
	active: boolean;
}
const DOUCMENT_DATA: DoucmentElement[] = [
	{
		tipodedocumento: 'ORDEN DE COMPRA',
		estado: 'Descargar',
		file: "orden_compra",
		active: false
	},
	{
		tipodedocumento: 'FACTURA',
		estado: 'Descargar',
		file: "factura",
		active: false
	},
	{
		tipodedocumento: 'DESPACHOS',
		estado: 'Descargar',
		file: "adicionales",
		active: false
	},
	{
		tipodedocumento: 'ENTRAGAS',
		estado: 'Descargar',
		file: "entregas",
		active: false
	},
];

@Component({
	selector: 'app-purchase',
	templateUrl: './purchase.component.html',
	styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit {

	/* -------------------- Variables para el tracking de punto a punto -------------------- */
	deliveryProcessBar: any;
	routeProcessBar: any;
	dispatchProcessBar: any;
	invoiceProcessBar: any;
	documentTrackingV: boolean = false;
	/* ---------------------------------------------- . --------------------------------------------- */

	year: number = (new Date()).getFullYear();

	orderNumber: string;

	despachoBtn: boolean = false

	order: OrderInterface;

	tracking: Tracking;

	pedido: any;

	messages: { mensaje: string; fecha: string; }[] = [];

	get loading(): boolean { return this.order == null; }

	progress: number = 30;

	// *** Variables para guardar el estado del tracking  ***
	delivery: number = 0;
	dispatch: number = 0;
	route: number = 0;
	invoiced: number = 0;
	dispatched: number = 0;
	requested: number = 0;
	gcDocuments:DocGCInterface[]  = [];

	dataSourceThree = DOUCMENT_DATA;

	displayedColumnsTwo: string[] = [
		'descripcion',
		'solicitado',
		'despacho',
		'facturado',
	];

	displayedColumnsThree: string[] = ['tipodedocumento', 'estado'];

	constructor(private routerService: RouterService,
		public datesService: DatesService,
		private dialogService: DialogService,
		private api: ApiService,
		private media: MediaObserver,
		private _page: Page,
		private snackbar: SnackbarService,
		private routeParam: ActivatedRoute,
		private stateSharedService: StateSharedService<any>) {

		this._page.actionBarHidden = true;

	}

	get router() { return this.routerService.router; }

	get xs(): boolean {
		return this.media.isActive('xs');
	}

	toNumberContable(number: number) {
		return number.toFixed(0);
	}

	stringToDate(string: string): Date {

		const doo = new Date(string);

		return new Date(doo.getTime() - doo.getTimezoneOffset() * -60000);
	}

	ngOnInit() {
		this.stateSharedService.get('filter').subscribe((filter) => {
			console.log("🚀 ~ file: purchase.component.ts:124 ~ PurchaseComponent ~ this.stateSharedService.get ~ filter:", filter);
		});

		this.routeParam.params.subscribe(async params => {
			const orderNumber = params['item'];
			try {
				let response: any = await this.api.tracking(orderNumber);
				console.log(response)
				this.tracking = response.tracking;
				this.messages = response.mensajes;
				this.pedido = response;
				// /* Variables para guardar el progreso del tracking */

				// this.dispatch = this.tracking.linea.despacho.porcentaje;
				// this.delivery = this.tracking.linea.entrega.porcentaje;
				// this.invoiced = this.tracking.linea.facturado.porcentaje;
				// this.route = this.tracking.linea.ruta.porcentaje;

				response = await this.api.documentFlow(orderNumber);
				this.order = response[0];

				this.getDocumentsGC()
				// this.tracking.despacho.documentos = this.order.details.map((detail)=>{
				// 	detail.deliveriesDetails.map((delivery)=>{
				// 		delivery.details.map((detail)=>{

				// 		});
				// 	});
				// });
			} catch (error) {
				this.snackbar.show(`No fue posible consultar el pedido ${orderNumber}, por favor intente nuevamente o contante con el administrador del sistema.`);
				this.order = '' as any;
			}
		});
	}

	disableButton(element) {
		console.log("lemento", element)
		return (
			(this.tracking.orden_compra.generado === false && element.file === 'orden_compra') ||
			(this.tracking.factura.documentos.length < 1 && element.file === 'factura') ||
			(this.tracking.adicionales.documentos.length < 1 && element.file === 'adicionales') ||
			(this.gcDocuments.length < 1 && element.file == 'entregas')
		)
	}

	getUrl(server: string): any {
		const url = `${environment[server].protocol}://${environment[server].ip}/${environment[server].version}`;
		return url;
	}

	async getDocumentsGC() {
		try {
			//consultar dos GC
			const docsGC: DocGCInterface[] = await this.api.documentLogisticGC(this.order.number.toString());

			this.gcDocuments = docsGC
			
		} catch (error) {
			console.log(error.message);
		}
	}
	
	getDocuments(field: string) {
		let docs: { name: string, url: string, typeDownload:string }[] = [];
		if (field == 'entregas') {
			this.gcDocuments.forEach((docGc:DocGCInterface, index) => {
				docs.push({
					name: 'FOTO '+(index+1),
					url: docGc.path,
					typeDownload: 'NEWTAB'
				});
			});

			this.dialogService.openDialog({
				type: 'documents',
				object: { docs: docs }
			});

			return;
		}
		console.log("this.tracking", this.tracking)

		if (field == 'despacho') {
			for (var despacho of this.tracking.despacho.documentos || []) {
				docs.push({
					name: despacho.numero,
					url: despacho.url,
					typeDownload: 'NEWTAB'
				});
			}
		}
		else if (field == 'orden_compra') {
			docs.push({
				name: 'Orden de compra',
				url: this.tracking.orden_compra.url, //url: 'https://gc.multigroup.com/sales/pedidos/cotizacion/'+this.tracking.cotizacion.id+'/pdfapp/'
				typeDownload: 'NEWTAB'
			});
		}
		else if (field == 'factura') {
			for (var factura of this.tracking.factura.documentos || []) {
				docs.push({
					name: String(factura.numero).trim()[0] == '5' ? "Factura (" + factura.numero + ")" : String(factura.numero).trim()[0] == '6' ? "Nota (" + factura.numero + ")" : factura.numero,
					url: this.getUrl('sapApiServerV2') + '/invoice/export-pdf?invoice=' + factura.numero,
					typeDownload: 'DOWNLOAD'
				});
			}
		}
		else if (field == 'adicionales') {
			var i = 0
			for (var adicional of this.tracking.adicionales.documentos || []) {
				i++
				docs.push({
					name: 'Entrega ' + String(i),
					url: adicional.url,
					typeDownload: 'NEWTAB'
				});
			}
		}

		if (docs.length > 0) {
			this.dialogService.openDialog({
				type: 'documents',
				object: { docs: docs }
			});
		}
	}

}