import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

	constructor() {

	}


	resizeImage(size: number, url: string) : Promise<Blob> {

		var promise = new Promise<Blob>((resolve, reject) => {

			var temp = new Image();

			temp.setAttribute('crossOrigin', 'anonymous');
			temp.src = url;

			temp.onload = (event) => {

				var target = { width: temp.width, height: temp.height, aspect: temp.width / temp.height };

				if (temp.width > temp.height)
				{
					size = Math.min(temp.width, size);
					target.width = size; target.height = size / target.aspect;
				}
				else
				{
					size = Math.min(temp.height, size);
					target.height = size; target.width = size * target.aspect;
				}

				var canvas = document.createElement('canvas');
				canvas.width = target.width; canvas.height = target.height;

				var ctx = canvas.getContext('2d');

				ctx.drawImage(temp, 0, 0, temp.width, temp.height, 0, 0, target.width, target.height);

				var type = "image/jpeg"

				var data = canvas.toDataURL(type);

				var bytes = (data.split(',')[0].indexOf('base64') >= 0) ? atob(data.split(',')[1]) : unescape(data.split(',')[1]);

				var ia = new Uint8Array(bytes.length);
				for(var i = 0; i < bytes.length; i++) { ia[i] = bytes.charCodeAt(i); }

				var blb = new Blob([ia], {type : type});

				resolve(blb);
			}

			temp.onerror = (event) => {

				reject(null);
			}
		});

		return promise;
	}


	imageSize(url: string) : Promise<string> {

		var promise = new Promise<string>((resolve, reject) => {

			var temp = new Image();

			temp.setAttribute('crossOrigin', 'anonymous');
			temp.src = url;

			temp.onload = (event) => {

				var imageSize = ''+temp.width+'x'+temp.height+'';

				resolve(imageSize);
			};

			temp.onerror = (event) => {

				reject(null);
			};
		});

		return promise;
	}
}
