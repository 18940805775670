import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { storage } from 'firebase';
import { saveAs as importedSaveAs } from "file-saver";
import { ImagesService } from './images.service';
import { environment } from 'src/environments/environment';


export interface FileMetadata {
	name: string;
	size: number;
	created: string;
	updated: string;
}

export interface UploadingID {
	value: boolean;
	id: string;
}


@Injectable({
	providedIn: 'root'
})
export class StorageService {


	uploadingBS: BehaviorSubject<UploadingID> = new BehaviorSubject({ value: false, id: "" });

	setUploadingBS(value: boolean, id: string) {

		this.uploadingBS.next({ value: value, id: id });
	}

	constructor(private afStorage: AngularFireStorage,
		private imagesService: ImagesService,
		private http: HttpClient) {

	}


	uploadFile(path: string, file: File): AngularFireUploadTask {

		const id = path.substr(path.lastIndexOf('/') + 1).substr(0, path.lastIndexOf('.'));
		this.setUploadingBS(true, id);

		return this.afStorage.ref(path).put(file);
	}


	uploadImage(path: string, file: File, maxSize?: number): Promise<{ task: AngularFireUploadTask }> {

		var id = path.substr(path.lastIndexOf('/') + 1);

		id = id.substr(0, id.indexOf('.'));

		console.log(id);

		this.setUploadingBS(true, id);

		var url = window.URL.createObjectURL(file);

		return this.uploadImageWithURL(path, url, maxSize);
	}

	uploadImageWithURL(path: string, url: string, maxSize?: number): Promise<{ task: AngularFireUploadTask }> {

		var promise = new Promise<{ task: AngularFireUploadTask }>((resolve, reject) => {

			this.imagesService.resizeImage(maxSize ? maxSize : 1242, url).then(blob => {

				resolve({ task: this.afStorage.ref(path).put(blob) });

			}).catch(reason => {

				reject(reason)
			});
		});

		return promise;
	}

	requestFile(url): Observable<Blob> {

		let options = { headers: new HttpHeaders({ responseType: 'blob' }) }

		return this.http.get(url, options).pipe(
			map((res: any) => res.blob())
		);
	}

	// TODO create a class service for management files
	async onCompress(urlImage: string) {
		const urlEncode = encodeURIComponent(urlImage);
		const url = `${environment.gcServer.protocol}://${environment.gcServer.ip}/api/v3/file/compress/${urlEncode}`;
		return await this.http.get(url, { responseType: 'blob' }).toPromise();
	}

	downloadFile(path: string, name?: string) {

		var filename: string = name ? name : path.slice(path.indexOf('/') + 1);

		this.afStorage.ref(path).getDownloadURL().toPromise().then((url) => {

			this.onCompress(url).then(blob => {

				importedSaveAs(blob, filename);
			});

		}).catch(function (error) {
			console.log(error);
		});
	}

	deleteFile(path: string): Promise<any> {

		return this.afStorage.ref(path).delete().toPromise();
	}

	getUrl(path: string): Promise<string> {

		return this.afStorage.ref(path).getDownloadURL().toPromise<string>();
	}

	getMetadata(path: string): Observable<storage.FullMetadata> {

		return this.afStorage.ref(path).getMetadata();
	}

	getFileMetadata(metadata: storage.FullMetadata): FileMetadata {

		var fileMetadata: FileMetadata = {
			name: metadata.name,
			size: metadata.size / 1024,
			created: this.formattedDate(metadata.timeCreated),
			updated: this.formattedDate(metadata.updated)
		};

		return fileMetadata;
	}

	formattedDate(dateString: string): string {

		var date: Date = new Date(dateString);

		var dd: number = date.getDate();
		var mm: number = date.getMonth() + 1;
		var yyyy: number = date.getFullYear();

		var _dd: string = dd.toString();
		var _mm: string = mm.toString();

		if (dd < 10)
			_dd = '0' + dd;

		if (mm < 10)
			_mm = '0' + mm;

		var formattedDate: string = _dd + '/' + _mm + '/' + yyyy;

		return formattedDate;
	}
}
