import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Offer } from '../../classes/offer';
import { DialogService } from '../../services/dialog.service';
import { SnackbarService } from '../../services/snackbar.service';
import { FileInput } from 'ngx-material-file-input';

@Component({
  selector: 'app-new-offer',
  templateUrl: './new-offer.component.html',
  styleUrls: ['./new-offer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewOfferComponent implements OnInit {

	@Input('offer') offer : Offer;

	@Input('confirm') confirm : Function;
	@Input('close') close : Function;
	@Input('errorMessage') errorMessage : string;

	@Input() loading : boolean;
	@Output() loadingChange = new EventEmitter<boolean>();

	setLoading(value: boolean) {
		this.loading = value;
		this.loadingChange.emit(this.loading);
	}


	saving : boolean = false;


	form : FormGroup;

	constructor(private formBuilder: FormBuilder,
				private dialogService: DialogService,
				private snackbar: SnackbarService) {

	}

	ngOnInit() {

		if (this.offer)
		{
			this.form = this.formBuilder.group({
				title: [this.offer.title, [Validators.required]],
				description: [this.offer.description, [Validators.required]],
				date: [this.offer.date, []],
				expiration: [this.offer.expiration, []],
				file: [null, []]
			});
		}
		else
		{
			this.form = this.formBuilder.group({
				title: [null, [Validators.required]],
				description: [null, [Validators.required]],
				date: [null, []],
				expiration: [null, []],
				file: [null, []]
			});
		}
	}


	get extension() : string {

		if (this.offer && this.offer.file)
		{
			return this.offer.file.substring(this.offer.file.lastIndexOf('.') + 1).toUpperCase();
		}
		else
		{
			return '';
		}
	}


	download() {

		
	}


	deleteFileDialog() {

		this.dialogService.openDialog({
			type: 'Warn',
			title: 'Eliminar archivo',
			text: '¿Seguro que desea eliminar este archivo? Esta acción no se puede deshacer.',
			confirm: this.deleteFile.bind(this)
		});
	}

	deleteFile() {

		
	}


	save() {

		if (this.form.invalid)
		{
			Object.keys(this.form.controls).forEach(key => {
				this.form.controls[key].markAsTouched();
			});
			
			return;
		}

		this.saving = true;


		const fileInput : FileInput = this.form.get('file').value;

		const offerID = null;

		const offer : Offer = {
			id: offerID,
			title: this.form.get('title').value,
			description: this.form.get('description').value,
			date: this.form.get('date').value,
			expiration: this.form.get('expiration').value,
			file: this.offer ? this.offer.file : null
		};


		if (fileInput && fileInput.files && fileInput.files.length > 0)
		{
			const file = fileInput.files[0];
			const extension = file.name.substring(file.name.lastIndexOf('.'));

			const path : string = 'Offers/'+offerID+extension;

			
		}
		else
		{
			
		}
	}

}
