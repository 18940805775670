import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OfferData, Offer } from '../classes/offer';
import { DatabaseService } from '../services/database.service';
import { StorageService } from '../services/storage.service';
import { SnackbarService } from '../services/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class OffersService {

	offers : BehaviorSubject<Offer[]> = new BehaviorSubject(null);


	constructor(private database: DatabaseService,
				private snackbar: SnackbarService,
				private storageService: StorageService) {


		this.database.array<OfferData>('offers', 'subscribe').subscribe(result => {

			if (result)
			{
				for (var offer of result)
				{
					if (offer.date && offer.date['getTime'] == null)
						offer.date = (offer.date as firebase.firestore.Timestamp).toDate();

					if (offer.expiration && offer.expiration['getTime'] == null)
						offer.expiration = (offer.expiration as firebase.firestore.Timestamp).toDate();
				}

				this.offers.next(result);
			}
		});
	}


	get(offerID: string) : Promise<Offer> {

		var promise = new Promise<Offer>((resolve, reject) => {

			this.database.get<Offer>('offers', offerID).then(offer => {

				if (offer.date && offer.date['getTime'] == null)
					offer.date = (offer.date as firebase.firestore.Timestamp).toDate();

				if (offer.expiration && offer.expiration['getTime'] == null)
					offer.expiration = (offer.expiration as firebase.firestore.Timestamp).toDate();

				resolve(offer);

			}).catch(reason => {

				reject(reason);
			})
		});

		return promise;
	}

	getFromArray(offerID: string) : Offer {

		if (this.offers.value && this.offers.value.length > 0)
		{
			var offer : Offer = null;

			for (var _offer of this.offers.value)
			{
				if (_offer.id == offerID)
				{
					offer = _offer;
					break;
				}
			}

			return offer;
		}
		else
		{
			return null
		}
	}


	add(offer: OfferData, silent?: boolean) : Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			this.database.add("offers", offer, silent ? null : {
				success: "Oferta creada",
				error: "Error al crear la oferta"
			}).then(result => {
				resolve(result);
			}).catch(reason => {
				reject(reason);
			});
		});

		return promise;
	}


	set(offer: Offer, silent?: boolean) : Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			const offerData : OfferData = {...offer};

			this.database.set('offers', offer.id, offerData, silent ? null : {
				success: "Oferta guardada",
				error: "Error al guardar oferta"
			}).then(result => {
				resolve(result);
			}).catch(reason => {
				reject(reason);
			});
		});

		return promise;
	}


	delete(offer: Offer, silent?: boolean) : Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			this.database.delete('offers', offer.id, silent ? null : {
				success: "Oferta eliminada",
				error: "Error al eliminar oferta"
			}).then(result => {
				resolve(result);
			}).catch(reason => {
				reject(reason);
			});
		});

		return promise;
	}

}
