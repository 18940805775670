
<mat-card class="custum-card">
	<h1>BIENVENIDO, USUARIO</h1>
	<div class="home-row" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column" fxLayout.sm="column">
	
			
		<div class="question-box row-font">
			<img src="/assets/images/ques-info.png" />
			NECESITAS AYUDA?
		</div>
		<div class="no-box row-font">
			<img src="/assets/icons/phone-icon.svg" />
			2320-5555
		</div>
		<div>
			<button mat-button class="home-btn bg-red">
				<img src="/assets/images/plus-sign.png"/>
				<span>CREAR TICKET</span>
			</button>
		</div>
		<div>
			<button mat-button class="home-btn">
				<img src="/assets/images/plus-sign.png" />
				<span>HABLAR CON ALGUIEN</span>
			</button>
		</div>
	</div>

	<div class="home-image-box">
		<div class="image-box-inner">
			<div  fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column">
				<div class="">
					<img src="../../assets/images/left.png" />
				</div>
				<div class="">
					<img src="../../assets/images/right.png" />
				</div>
			</div>
		</div>
	</div>
	
</mat-card>


			