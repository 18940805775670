import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Page } from '../adapters/page';


@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

	username : string;

	constructor(public authService: AuthService,
				private page: Page) {

		this.page.actionBarHidden = true;

		this.authService.userBS.subscribe(user => {

			if (user)
			{
				this.username = user.name || user.username;
			}
		});
	}

	ngOnInit() {

	}

}
