import { Component, OnInit, ChangeDetectorRef, Input, ViewChild, OnDestroy } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { RouterService } from '../services/router.service';
import { ActivationEnd } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { ProfileService } from '../services/profile.service';
import { MediaObserver } from '@angular/flex-layout';
import { Menu } from '../adapters/menu';
import { MediaMatcher } from '@angular/cdk/layout';
import { AuthGuard } from '../guards/auth.guard';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

export interface ChildrenHomeSection {
	icon: string;
	text: string;
	path: string;
	module?: string;
	hidden?: boolean;
	disabled?: boolean;
}

export interface HomeSection {
	icon: string;
	text: string;
	path: string;
	module?: string;
	hidden?: boolean;
	disabled?: boolean;
	children?: ChildrenHomeSection[];
}




@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
	mobileQuery: MediaQueryList;
	@Input('bgcolor') bgcolor: string = 'default';

	@Input('forceColor') forceColor: boolean = false;

	@ViewChild('snav') sidenav;

	username: string;

	homePath: string;
	view: boolean = false

	photoUrl: string = "";

	urlLength: number = 10;

	menuStatus: string = "/"+(window.location.href.split("/"))[3];
	menuOpen: boolean = false;

	

	get showBack(): boolean {

		return this.homePath && (this.urlLength > (this.homePath == '/company' ? 0 : 1))
	}
	isHome = false;
	private _mobileQueryListener: () => void;
	sections: HomeSection[] = [];
	company: boolean = false;
	url='';

	constructor(public authService: AuthService,
		public storageService: StorageService,
		private changeDetectorRef: ChangeDetectorRef,
		private routerService: RouterService,
		private profileService: ProfileService,
		private media: MediaObserver,
		private lmedia: MediaMatcher,) {

			
		this.router.events.subscribe(event => {

			if (event instanceof ActivationEnd) {
				console.log(event.snapshot.url);
				
				this.urlLength = event.snapshot.url.length;
				console.log(this.urlLength);
				console.log(window.location.href);
				this.url =window.location.href
				if (this.urlLength == 0) {
					changeDetectorRef.detectChanges();
					this.isHome = true
				  } else {
					this.isHome = false
					changeDetectorRef.detectChanges();
				  }
				this.loadSections();
			}
		});


		this.authService.userBS.subscribe(user => {

			if (user) {
				this.username = user.name || user.username;

				if (user.admin) {
					this.homePath = '/admin';
				}
				else {
					this.homePath = '/';
				}
			}
		});

		this.mobileQuery = lmedia.matchMedia('(max-width: 600px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);
		
	}

	viewLogo(){
		if (this.view == false) {console.log("ver")
			return this.view = true
			
		}else{
			console.log("nover")
			return this.view = false
		}
	}

	onResize(event) {
		this.configureSideNav();
		console.log("a")
	}


	configureSideNav() {
		
		if (this.mobileQuery.matches) {
		  this.sidenav.mode = 'side';
		  this.sidenav.opened = true;
		  
		} else {
		  this.sidenav.mode = 'over';
		  this.sidenav.opened = false;
		}
	  }

	get router() { return this.routerService.router; }
	

	get xs(): boolean {
		return this.media.isActive('xs');
	}


	get uploading() { return this.profileService.uploading; }


	back() {

		if (this.showBack)
			this.routerService.back();
	}


	ngOnInit() {

		this.profileService.photoUrl.subscribe(url => {

			this.photoUrl = url;

			if (!this.changeDetectorRef['destroyed'])
				this.changeDetectorRef.detectChanges();
		});
	}


	openHome() {

		if (this.homePath)
			this.router.navigate([this.homePath+"/company"]);
			this.menuStatus = this.homePath+"company"
			this.menuOpen = false;
		
	}

	openSalesTeam(){
		if (this.homePath)
			this.router.navigate([this.homePath+"/salesteam"]);
			this.menuStatus = this.homePath+"salesteam"
			this.menuOpen = false;
	}

	openProfile() {

		if (this.homePath)
			this.router.navigate([this.homePath+"/profile"]);
			this.menuStatus = this.homePath+"profile"
			this.menuOpen = false;
	}


	signOut() {

		this.authService.signOut();
	}



	menu(view: any) {

		Menu.popup({
			view: view,
			actions: ['Perfil (' + this.username + ')', 'Salir'],
			cancelButtonText: "Cancelar"
		})
			.then(action => {

				if (action) {
					if (action.title == 'Perfil (' + this.username + ')') {
						this.router.navigate([this.homePath + 'profile'])
					}
					else if (action.title == 'Salir') {
						this.signOut();
					}
				}
			});
	}

	ngOnDestroy(): void {
		this.mobileQuery.removeListener(this._mobileQueryListener);
	}
	
	loadSections() {
		
		if (this.authService.user.admin) {
			this.company = true
			this.sections = [
				{ icon: 'user', text: 'Clientes administradores', path: '/admin/customers' },
				{ icon: 'money', text: 'Administrar ofertas', path: '/admin/offers' },
				{ icon: 'catalog', text: 'Catálogo de productos', path: '/admin/products' },
				// { icon: 'receipt', text: 'Aprobar pagos realizados', path: '/admin/orders' }
			];
		}
		else if (this.authService.user.role == "admin"){

			this.company = true

			this.sections = [
				{ icon: 'tracking', text: 'INICIO', path: '/', module: 'pageone'},
				
				{ icon: 'finances', text: 'GESTION FINANCIERA', path: '/finances', children: [
					{ icon: 'market', text: 'ESTADO DE CUENTA', path: '/account', module: 'account' },
					{ icon: 'market', text: 'FACTURAS Y PAGOS', path: '/invoices', module: 'invoices' },
					// { icon: 'market', text: 'ORDENES DE PAGO', path: '/orders', module: 'orders' },
				]},

				{ icon: 'market', text: 'COMPRAS', path: '/purchases', module: 'purchases' },
				{ icon: 'catalog', text: 'CATALOGO', path: '/products', module: 'products' },
				{ icon: 'user', text: 'USUARIOS', path: '/users' }
			];

		}else {



			this.sections = [
				{ icon: 'tracking', text: 'INICIO', path: '/', module: 'pageone'}
			];

			if (this.authService.user.modules){
				var sections_finances = []
				this.authService.user.modules.forEach(module => {
					if (module == 'account'){
						sections_finances.push({ icon: 'market', text: 'ESTADO DE CUENTA', path: '/account', module: 'account' })
					}else if (module == 'invoices'){
						sections_finances.push({ icon: 'market', text: 'FACTURAS Y PAGOS', path: '/invoices', module: 'invoices' })
					}else if (module == 'orders'){
						// sections_finances.push({ icon: 'market', text: 'ORDENES DE PAGO', path: '/orders', module: 'orders' })
					}else if (module == 'company'){
						this.company = true
					}else if (module == 'products'){
						this.sections.push({ icon: 'catalog', text: 'CATALOGO', path: '/products', module: 'products' })
					}else if (module == 'purchases'){
						this.sections.push({ icon: 'market', text: 'COMPRAS', path: '/purchases', module: 'purchases' });
					}
				});

				if (sections_finances.length >= 1){
					this.sections.push({ icon: 'finances', text: 'GESTION FINANCIERA', path: '/finances', children: sections_finances })
				}
			}
		}
	}

	openSection(index: number) {

		const section = this.section(index);

		if (section.hidden || !section.path || section.children) return;

		this.router.navigate([section.path]);
		this.menuStatus = section.path
		this.menuOpen = false;
	}

	openSubSection(path: string) {
		this.router.navigate([path]);
		this.menuStatus = path
		this.menuOpen = true;
	}

	section(index: number) : HomeSection {

		const section = this.enabledSection(index);

		if (section.module)
		{
			const user = this.authService.user;

			section.disabled = (!user.admin && user.role != 'admin') && (user.modules == null || user.modules.indexOf(section.module) < 0);
		}

		return section;
	}

		enabledSection(index: number) : HomeSection {

		if (this.sections && this.sections.length > 0)
		{
			if (index < this.sections.length)
			{
				return this.sections[index];
			}
			else
			{
				const section = {...this.sections[0]};
				section.hidden = true;

				return section;
			}
		}
		else
		{
			return null;
		}
	}



}
