<div class="home" ngClass.xs="xs" fxLayout="column" fxLayoutAlign="space-between center">


	<!-- <app-navbar class="full-width" bgcolor="primary"></app-navbar> -->


	<div fxFlex ngClass.gt-xs="content" ngClass.xs="claim-container" fxLayout="column" fxLayoutAlign="start center">

		<mat-card ngClass.gt-xs="content-card mat-elevation-z6" ngClass.xs="content-card mat-elevation-z0"
			fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="3em">

			<br />

			<mat-spinner *ngIf="loading" fxFlexAlign="center" [color]="xs ? 'accent' : 'primary'"
				diameter="40"></mat-spinner>

			<div *ngIf="!loading" class="claim" fxLayout="column" fxLayoutAlign="start center">

				<form *ngIf="!claim" ngClass.xs="full-width" [formGroup]="form" id="claimForm" (ngSubmit)="save()"
					autocomplete="off" fxLayout="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start stretch">

					<mat-form-field class="full-width" ngClass.gt-xs="primary" appearance="outline"
						[color]="xs ? 'default': 'primary'">
						<mat-label>TIPO DE RECLAMO</mat-label>
						<mat-select formControlName="type">
							<mat-option *ngFor="let type of types" [value]="type">{{type}}</mat-option>
						</mat-select>
						<mat-error *ngIf="form.get('type').hasError('required')">
							Tipo de reclamo requerido
						</mat-error>
					</mat-form-field>

					<br />

					<mat-form-field class="full-width" ngClass.gt-xs="primary" appearance="outline"
						[color]="xs ? 'default': 'primary'">
						<mat-label>COMENTARIO</mat-label>
						<textarea matInput formControlName="comment" rows="10" maxlength="4000"
							autocomplete="off"></textarea>
						<mat-error *ngIf="form.get('comment').hasError('required')">
							Comentario requerido
						</mat-error>
					</mat-form-field>


					<br />

					<mat-form-field class="full-width" ngClass.gt-xs="primary" appearance="outline"
						[color]="xs ? 'default': 'primary'">
						<mat-label>ARCHIVO (opcional)</mat-label>
						<ngx-mat-file-input #fileInput formControlName="file"
							accept="application/pdf,image/*"></ngx-mat-file-input>
						<button mat-icon-button color="primary" matSuffix *ngIf="!fileInput.empty"
							(click)="fileInput.clear($event)">
							<mat-icon>clear</mat-icon>
						</button>
					</mat-form-field>


					<br /><br />


					<button fxFlexAlign.gt-xs="end" class="main-button" ngClass.xs="full-width" mat-flat-button
						[color]="xs ? 'default' : 'primary'" form="claimForm" type="submit">
						<img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">ENVIAR
					</button>

					<br />

				</form>



				<div *ngIf="claim" class="claim-details" ngClass.xs="full-width" fxLayout="column"
					fxLayoutAlign="start start" fxLayoutGap="2em">

					<div class="field-container" fxLayout="column" fxLayoutAlign="start start">
						<mat-label>TIPO DE RECLAMACIÓN</mat-label>
						<p>{{claim.type}}</p>
					</div>

					<div class="field-container" fxLayout="column" fxLayoutAlign="start start">
						<mat-label>COMENTARIO</mat-label>
						<p>{{claim.comment}}</p>
					</div>

					<div *ngIf="claim?.file" class="field-container" fxLayout="column" fxLayoutAlign="start start">
						<mat-label>ARCHIVO</mat-label>
						<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em">

							<span>1 archivo {{extension}}</span>

							<button type="button" mat-button [color]="xs ? 'default' : 'primary'"
								(click)="download()"><img class="multi_arrow"
									[src]="'assets/images/multi_arrow.png'">Descargar</button>
						</div>
					</div>

					<br />

				</div>

			</div>


		</mat-card>

	</div>


</div>