<div *ngIf="!loading" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2em">

	<h4>{{product ? 'Editar' : 'Nuevo'}} producto</h4>

	<div class="full-width" fxLayout="column" fxLayoutAlign="start start">

		<form class="full-width" [formGroup]="form" id="newProductForm" (ngSubmit)="save()" autocomplete="off" fxLayout="column" fxLayoutAlign="start center">

			<mat-form-field class="primary full-width" appearance="outline" color="primary">
				<mat-label>TÍTULO</mat-label>
				<input matInput formControlName="title" maxlength="100" autocomplete="off">
				<mat-error *ngIf="form.get('title').hasError('required')">
					Título requerido
				</mat-error>
			</mat-form-field>

			<br/>
			
			<mat-form-field style="width: 700px;" class="primary full-width" appearance="outline" color="primary">
				<mat-label>DESCRIPCIÓN</mat-label>
				<textarea matInput formControlName="description" maxlength="500" autocomplete="off" rows="5"></textarea>
				<mat-error *ngIf="form.get('description').hasError('required')">
					Descripción requerida
				</mat-error>
			</mat-form-field>
			<br/>

			<mat-form-field style="width: 700px;" class="primary full-width" appearance="outline" color="primary">
				<mat-label>Url redireccionar mas informacion.</mat-label>
				<textarea matInput formControlName="file" maxlength="500" autocomplete="off" rows="2"></textarea>
				<mat-error *ngIf="form.get('file').hasError('required')">
					Url requerida
				</mat-error>
			</mat-form-field>
			<br/>
			<mat-form-field style="width: 700px;" class="primary full-width" appearance="outline" color="primary">
				<mat-label>Url imagen.</mat-label>
				<textarea matInput formControlName="image" maxlength="500" autocomplete="off" rows="2"></textarea>
				<mat-error *ngIf="form.get('image').hasError('required')">
					Url requerida
				</mat-error>
			</mat-form-field>

			


			<br/><br/>

			<mat-spinner *ngIf="saving" color="primary" diameter="40"></mat-spinner>

			<button *ngIf="!saving" class="main-button full-width" mat-flat-button color="primary" form="newProductForm" type="submit">
				<img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">GUARDAR
			</button>

		</form>

	</div>

</div>


<div *ngIf="loading" class="spinner-container" fxLayout="column" fxLayoutAlign="center center">
	<mat-spinner color="primary" diameter="40"></mat-spinner>
</div>

