import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection, Query, DocumentReference, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';
import { SnackbarService, SnackbarMessages } from './snackbar.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


export interface OrderBy {
	field: string;
	desc?: boolean;
}

export interface CollecitonParams {
	orderBy: OrderBy[] | OrderBy;
	startAt?: any;
	startAfter?: any;
	endAt?: any;
	endBefore?: any;
	limit?: number;
}


export interface WhereFilter {
	field: string;
	op: any;
	value: any;
}


export enum DatabaseStatus {
	Offline = "Offline",
	Synchronizing = "Synchronizing",
	Synchronized = "Synchronized"
}


export interface BatchOperation {
	collection: string;
	type: string;
	data: any;
}



@Injectable({
  providedIn: 'root'
})
export class DatabaseService {


	constructor(private afStore: AngularFirestore,
				private snackbar: SnackbarService) {

	}


	where : WhereFilter;


	init(where: WhereFilter) {

		this.where = where;
	}


	batch(operations: BatchOperation[], messages?: SnackbarMessages) : Promise<any> {

		var batch : firestore.WriteBatch = this.afStore.firestore.batch()

		var paths : string[] = [];

		for (var op of operations)
		{
			var ref : DocumentReference;
			var data : any;

			if (op.data.id != null)
			{
				ref = this.afStore.collection(op.collection).doc(op.data.id).ref;
				data = op.data;
				delete data.id;
			}
			else
			{
				ref = this.afStore.collection(op.collection).doc(this.createID()).ref;
				data = op.data;
			}

			paths.push(ref.path);

			switch (op.type) {
				case "set":
					batch.set(ref, data);
					break;

				case "update":
					batch.update(ref, data);
					break;

				case "delete":
					batch.delete(ref);
					break;
				
				default:
					break;
			}
		}

		var promise = new Promise<any>((resolve, reject) => {

			if (this.status != DatabaseStatus.Offline)
			{
				batch.commit().then(result => {

					if (messages && messages.success)
						this.snackbar.show(messages.success);

					resolve(result);

				}).catch(reason => {

					if (messages && messages.error)
						this.snackbar.show(messages.error);

					reject(reason);

				}).finally(() => {

					for (var path of paths)
					{
						this.removePendingWrite(path);
					}
				});
			}
			else
			{
				batch.commit().finally(() => {

					for (var path of paths)
					{
						this.removePendingWrite(path);
					}
				});

				if (messages && messages.success)
						this.snackbar.show(messages.success + " (Sincronización pendiente)");

				resolve(null);
			}
		});

		return promise;
	}


	createID() : string {
		return this.afStore.createId();
	}

	doc<T>(collection: string, doc: string) : AngularFirestoreDocument<T> {
		return this.afStore.collection(collection).doc<T>(doc);
	}

	get<T>(collection: string, doc: string) : Promise<(T & {id: string})> {

		var promise = new Promise<(T & {id: string})>((resolve, reject) => {

			this.doc(collection, doc).get().toPromise().then(_doc => {

				if (_doc.exists)
				{
					this.checkSync(_doc);

					const data = _doc.data() as T;
					const id = _doc.id;
					resolve({id: id, ...data});
				}
				else
				{
					reject();
				}

			}).catch(reason => {
				reject(reason);
			});
		});

		return promise;
	}

	set(collection: string, doc: string, data: any, messages?: SnackbarMessages) : Promise<any> {

		var _data = data;

		delete _data.id;

		var promise = new Promise<any>((resolve, reject) => {

			if (this.status != DatabaseStatus.Offline)
			{
				this.doc(collection, doc).set(_data).then(result => {

					if (messages && messages.success)
						this.snackbar.show(messages.success);

					resolve(result);

				}).catch(reason => {

					if (messages && messages.error)
						this.snackbar.show(messages.error);

					reject(reason);

				}).finally(() => {

					this.removePendingWrite(collection+'/'+doc)
				});
			}
			else
			{
				this.doc(collection, doc).set(_data).finally(() => {
					this.removePendingWrite(collection+'/'+doc)
				});

				if (messages && messages.success)
						this.snackbar.show(messages.success + " (Sincronización pendiente)");

				resolve(null);
			}
		});

		return promise;
	}

	add(collection: string, data: any, messages?: SnackbarMessages) : Promise<any> {

		var docID : string = data.id != null ? data.id : this.afStore.createId();
		return this.set(collection, docID, data, messages);
	}


	update(collection: string, doc: string, data: any, messages?: SnackbarMessages) : Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			this.doc(collection, doc).update(data).then(result => {

				if (messages && messages.success)
						this.snackbar.show(messages.success);

				resolve(result);

			}).catch(reason => {

				if (messages && messages.error)
						this.snackbar.show(messages.error);

				reject(reason);
			});
		});

		return promise;
	}


	delete(collection: string, doc: string, messages?: SnackbarMessages) : Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			if (this.status != DatabaseStatus.Offline)
			{
				this.doc(collection, doc).delete().then(result => {

					if (messages && messages.success)
						this.snackbar.show(messages.success);

					resolve(result);

				}).catch(reason => {

					if (messages && messages.error)
						this.snackbar.show(messages.error);

					reject(reason);

				}).finally(() => {

					this.removePendingWrite(collection+'/'+doc)
				});
			}
			else
			{
				this.doc(collection, doc).delete().finally(() => {

					this.removePendingWrite(collection+'/'+doc)
				});

				if (messages && messages.success)
						this.snackbar.show(messages.success + " (Sincronización pendiente)");

				resolve(null);
			}
		});

		return promise;
	}


	array<T>(path: string, method?: string, params?: CollecitonParams, filters?: WhereFilter | WhereFilter[]) : Observable<(T & {id: string})[]> {

		if (method == null || method == '')
			method = 'subscribe';

		var collection : AngularFirestoreCollection;

		collection = this.afStore.collection<T>(path, ref => {
				
			var _ref : Query = ref;


			if (this.where && method.indexOf('All') < 0)
				_ref = _ref.where(this.where.field, this.where.op, this.where.value);


			if (filters)
			{
				if (Object.prototype.toString.call(filters) != '[object Array]')
				{
					var filter = filters as WhereFilter;

					_ref = _ref.where(filter.field, filter.op, filter.value);
				}
				else
				{
					for (var filter of (filters as WhereFilter[]))
					{
						_ref = _ref.where(filter.field, filter.op, filter.value);
					}
				}
			}


			if (params)
			{
				if (Object.prototype.toString.call(params.orderBy) != '[object Array]')
				{
					var orderBy = params.orderBy as OrderBy;

					_ref = orderBy.desc == true ? _ref.orderBy(orderBy.field, "desc") : _ref.orderBy(orderBy.field);
				}
				else
				{
					for (var orderBy of (params.orderBy as OrderBy[]))
					{
						_ref = orderBy.desc == true ? _ref.orderBy(orderBy.field, "desc") : _ref.orderBy(orderBy.field);
					}
				}

				if (params.startAt)
					_ref = _ref.startAt(params.startAt);

				if (params.startAfter)
					_ref = _ref.startAfter(params.startAfter);

				if (params.endAt)
					_ref = _ref.endAt(params.endAt);

				if (params.endBefore)
				{
					_ref = _ref.endBefore(params.endBefore);
				}

				if (params.limit)
					_ref = _ref.limit(params.limit);
			}

			return _ref;
		});


		var observable : Observable<(T & {id: string})[]>;


		if (method.indexOf('subscribe') >= 0)
		{
			observable = collection.snapshotChanges().pipe(

				map(actions => {

					return actions.map(a => {

						this.checkSync(a.payload.doc);

						const data = a.payload.doc.data() as T;
						const id = a.payload.doc.id;
						return { ...data, id };
					});
				})
			);
		}
		else if (method.indexOf('get') >= 0)
		{
			observable = collection.get().pipe(

				map(snapshot => {

					if (snapshot == null || snapshot.empty)
					{
						return [];
					}
					else
					{
						return snapshot.docs.map(a => {

							this.checkSync(a);

							const data = a.data() as T;
							const id = a.id;
							return { ...data, id };
						});
					}
				})
			);
		}

		return observable;
	}




	exists(collection: string, where: WhereFilter) : Promise<string> {

		var promise = new Promise<string>((resolve, reject) => {

			this.afStore.collection(
				collection,
				ref => ref.where(where.field, where.op, where.value))
			.get().toPromise().then(snapshot => {

				resolve(snapshot.empty != true ? snapshot.docs[0].id : null);

			}).catch(reason => {

				reject(reason);
			});
		});

		return promise;
	}




	clearCache() : Promise<void> {

		return this.afStore.firestore.clearPersistence();
	}



	private _pendingWrites : string[] = [];

	private checkSync(snapshot: QueryDocumentSnapshot<any>) {

		if (snapshot.metadata.fromCache && snapshot.metadata.hasPendingWrites)
		{
			if (this._pendingWrites.indexOf(snapshot.ref.path) < 0)
			{
				this._pendingWrites.push(snapshot.ref.path);
			}
		}
		else
		{
			this.removePendingWrite(snapshot.ref.path);
		}
	}

	private removePendingWrite(path: string) {

		if (this._pendingWrites.indexOf(path) >= 0)
		{
			this._pendingWrites.splice(this._pendingWrites.indexOf(path), 1);
		}
	}



	get status() : DatabaseStatus {

		return DatabaseStatus.Synchronized;

		// if (this.appService.isOnline)
		// {
		// 	if (this._pendingWrites && this._pendingWrites.length > 0)
		// 	{
		// 		return DatabaseStatus.Synchronizing;
		// 	}
		// 	else
		// 	{
		// 		return DatabaseStatus.Synchronized;
		// 	}
		// }
		// else
		// {
		// 	return DatabaseStatus.Offline;
		// }
	}


	get statusProperties() : {text: string, class: string, icon?: string} {

		switch (this.status) {
			case DatabaseStatus.Offline:
				return {
					text: "Sin conexión",
					class: "warn-status"
				};
				break;

			case DatabaseStatus.Synchronizing:
				return {
					text: "Sincronizando",
					class: "waiting-status"
				};
				break;

			case DatabaseStatus.Synchronized:
				return {
					text: "Sincronizado",
					class: "success-status"
				};
				break;
			
			default:
				return {
					text: "Estado desconocido",
					class: "unknow-status"
				};
				break;
		}
	}

}
