import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserData, User } from '../../classes/user';
import { ValidatorsService } from '../../services/validators.service';
import { AuthService } from '../../services/auth.service';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileEditComponent implements OnInit {

	@Input('confirm') confirm : Function;
	@Input('close') close : Function;
	@Input('errorMessage') errorMessage : string;

	@Input() loading : boolean;
	@Output() loadingChange = new EventEmitter<boolean>();

	setLoading(value: boolean) {
		this.loading = value;
		this.loadingChange.emit(this.loading);
	}


	saving : boolean = false;


	form : FormGroup;

	constructor(private formBuilder: FormBuilder,
				private validatorsService: ValidatorsService,
				private authService: AuthService,
				private usersService: UsersService) {

	}

	ngOnInit() {

		this.form = this.formBuilder.group({
			name: [this.authService.user.name, [Validators.required]],
			email: [this.authService.user.email, [Validators.required, Validators.email]],
			phone: [this.authService.user.phone, []],
			address: [this.authService.user.address, []],
		});
	}


	save() {

		if (this.form.invalid)
		{
			Object.keys(this.form.controls).forEach(key => {
				this.form.controls[key].markAsTouched();
			});
			
			return;
		}

		this.setLoading(true);


		var user : User = {...this.authService.user};

		user.name = this.form.get('name').value;
		user.phone = this.form.get('phone').value;
		user.email = this.form.get('email').value;
		user.address = this.form.get('address').value;

		this.usersService.set(user).then(result => {

			this.authService.userBS.next(user);

			this.confirm();
		});
	}
}
