
<div class="home" ngClass.xs="xs" fxLayout="column" fxLayoutAlign="space-between center">


	<!-- <app-navbar class="full-width" bgcolor="primary"></app-navbar> -->

	<div class="user-container">
	<!-- <div fxFlex ngClass.gt-xs="content" ngClass.xs="full-width" fxLayout="column" fxLayoutAlign="start center" style="border: 5px solid green"> -->

		<mat-card class="no-shadow" ngClass.gt-xs="mat-elevation-z6" ngClass.xs="mat-elevation-z0" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="1em" >
			<div>
			<p class="tittle">Administrar usuarios</p>
			</div>
			<div class="actions-bar" fxLayout="row" fxLayoutAlign="space-between center">

				<mat-form-field ngClass.gt-xs="primary" ngClass.xs="full-width" appearance="outline" [color]="xs ? 'primary': 'primary'">
					<mat-label>BUSCAR</mat-label>
					<input matInput maxlength="100" autocomplete="off" #searchInput (keyup)="applyFilter($event.target.value)">
					<app-icon [icon]="'search' +('_primary')" matSuffix></app-icon>
					<!-- <app-icon [icon]="'search'+(!xs ? '_primary': '')" matSuffix></app-icon> -->
				</mat-form-field>

			</div>


			<br *ngIf="loading" />


			<p class="empty-text" *ngIf="!loading && dataSource.data?.length < 1">
				No hay usuarios registrados
			</p>

			<mat-spinner *ngIf="loading" fxFlexAlign="center" color="primary" diameter="40"></mat-spinner>

			<div class="table-container">
			<table [ngClass]="{'hidden': loading || dataSource.data?.length < 1}" mat-table matSort [dataSource]="dataSource" class="full-width" [trackBy]="trackByFn" multiTemplateDataRows>

				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
					<td mat-cell *matCellDef="let user">
						{{user.name || user.username}}
					</td>
				</ng-container>

				<ng-container matColumnDef="username">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
					<td mat-cell *matCellDef="let user">
						{{user.username}}
					</td>
				</ng-container>

				<ng-container matColumnDef="status">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
					<td mat-cell *matCellDef="let user">
						
						<span class="orangeChip" *ngIf="!user.active && user.modules">
							Inabilitado
						</span>
						<span class="orangeChip" *ngIf="user.active && !user.modules">
							Sin accesos
						</span>
						<span class="greenChip" *ngIf="user.active && user.modules">
							Habilitado
						</span>
						<!-- <span class="greyChip"
							*ngIf="ordersService.orderStatus(order) != 'Pago pendiente' && ordersService.orderStatus(order) != 'Pago en revisión' && ordersService.orderStatus(order) != 'Pagado'">
							{{user.active ? 'Habilitado' : 'Sin habilitar'}}
						</span> -->
					</td>
				</ng-container>


				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let user" class="last-column">

						<button mat-icon-button [matMenuTriggerFor]="actionsMenu" (click)="$event.stopPropagation()">
							<mat-icon>more_vert</mat-icon>
						</button>

						<mat-menu #actionsMenu="matMenu">

							<button mat-menu-item (click)="editUserDialog(user)">
								<mat-icon>edit</mat-icon>
								<span>{{user.active ? 'Editar' : 'Habilitar'}}</span>
							</button>

							<button *ngIf="user.active" mat-menu-item (click)="disableUserDialog(user)">
								<mat-icon>edit</mat-icon>
								<span>Inhabilitar</span>
							</button>

						</mat-menu>
					</td>
				</ng-container>



				<ng-container matColumnDef="expanded">
					<td mat-cell *matCellDef="let user" [attr.colspan]="displayedColumns.length">
						<div class="element-detail" [@expand]="user == expandedElement ? 'expanded' : 'collapsed'" fxLayout="column">

							<br/>
							
							<p><strong>Usuario: </strong>{{user.username}}</p>

							<p><strong>Estado: </strong>{{user.active ? 'Habilitado' : 'Sin habilitar'}}</p>

						</div>
					</td>
				</ng-container>



				<tr fxHide.xs mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row *matRowDef="let user; columns: displayedColumns;" class="element-row" [class.expanded-row]="expandedElement === user" (click)="expandedElement = expandedElement === user || !xs ? null : user"></tr>
				<tr mat-row *matRowDef="let user; columns: ['expanded']" class="detail-row"></tr>

			</table>
		</div>

			<mat-paginator fxFlexAlign="end" [ngClass]="{'hidden': loading || dataSource.data?.length < 1}" [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="pageSize" [pageIndex]="page"></mat-paginator>
			
		</mat-card>

	<!-- </div> -->
	</div>


	
</div>