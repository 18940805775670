<mat-card class="custum-card">
	<h1>BIENVENIDO, {{nameUser}}</h1><br>
	<mat-form-field>
		<mat-label>Sociedad</mat-label>
		<mat-select [(ngModel)]="societySelected" name="food">
			<mat-option (click)="onSelectSociety(society)" *ngFor="let society of companies" [value]="society.id">{{society.nombre}}</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-spinner *ngIf="loading" diameter="40"></mat-spinner>
	<div id="flexContainer" class="flex-container">

		<div *ngIf="tipoUsuario !== ''" id="flexItem" class="flex-items">
			<div *ngIf="tipoUsuario !== ''" style="height: 83%; width: 100%;">
				<mat-carousel timings="250ms ease-in" [autoplay]="true" interval="5000" color="accent" maxWidth="100%"
					maintainAspectRatio="false" slideHeight="100%" proportion="25" slides="5" [loop]="true"
					[hideArrows]="false" [hideIndicators]="false" [useKeyboard]="true" [useMouseWheel]="false"
					[maintainAspectRatio]="false" orientation="ltr">
					<mat-carousel-slide #matCarouselSlide *ngFor="let slide of slides; let i = index"
						[image]="slide.img" overlayColor="#00000040" [hideOverlay]="false">
						<div mat-button fxLayout="column" class="slite-btn" fxLayout.xs="column" fxLayout.sm="column">
							<p style="font-size: 20px;">{{slide.valor}}
							<p>
								<span>{{slide.text}}</span>
						</div>
					</mat-carousel-slide>
				</mat-carousel>
			</div>
		</div>


		<div *ngIf="tipoUsuario !== ''" id="flexItem2" class="flex-items">
			<div *ngIf="tipoUsuario !== ''" class="home-row-status" fxLayout="row" fxLayout.xs="column"
				fxLayout.sm="column">
				<div>
					<div mat-button fxLayout="column" class="home-btn" fxLayout.xs="column" fxLayout.sm="column">
						<p>{{indicador3 }}
						<p>
							<span>{{indicadorText3}}</span>
					</div>
				</div>
				<div>
					<div mat-button fxLayout="column" class="home-btn" fxLayout.xs="column" fxLayout.sm="column">
						<p>{{purcharsePrevYear}}
						<p>
							<span>{{purcharsePrevYearLabel}}</span>
					</div>
				</div>
				<div>
					<div mat-button fxLayout="column" class="home-btn" fxLayout.xs="column" fxLayout.sm="column">
						<p>{{balancePending}}
						<p>
							<span>{{balancePendingLabel}}</span>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="tipoUsuario !== ''" id="flexItem3" class="flex-items">
			<div class="home-row" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column"
				fxLayout.sm="column">

				<div class="question-box row-font">
					<img src="/assets/images/ques-info.png" />
					NECESITAS AYUDA?
				</div>
				<div class="no-box row-font">
					<img src="/assets/icons/phone-icon.svg" />
					{{phone}}
				</div>
			</div>
		</div>

		<div *ngIf="tipoUsuario === '' && !usuarioSinPermisos" style="width: 100% !important;" class="flex-items">
			<div class="home-row" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column"
				fxLayout.sm="column">
				<div></div>
				<div></div>
				<div></div>

				<div class="question-box row-font">
					<img src="/assets/images/ques-info.png" />
					NECESITAS AYUDA?
				</div>
				<div class="no-box row-font">
					<img src="/assets/icons/phone-icon.svg" />
					{{phone}}
				</div>
			</div>
		</div>

		<div *ngIf="tipoUsuario === '' && usuarioSinPermisos">
			<div fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column"
				fxLayout.sm="column">
				<div class="question-box row-font">
					<img src="/assets/images/advertencia.png" />
					¡SU USUARIO SE ENCUENTRA PENDIENTE DE HABILITAR!
				</div>
			</div>
		</div>





		<!-- <div class="canvas-container">
		<div class="flex-container">
			<div class="flex-items">
				<div *ngIf="tipoUsuario !== ''" class="image-box-inner">
					<div class="sale-graphic">
						<mat-carousel timings="250ms ease-in" [autoplay]="true" interval="5000" color="accent"
							maxWidth="100%" proportion="25" slides="5" [loop]="true" [hideArrows]="false"
							[hideIndicators]="false" [useKeyboard]="true" [useMouseWheel]="false"
							[maintainAspectRatio]="false" orientation="ltr">
							<mat-carousel-slide #matCarouselSlide *ngFor="let slide of slides; let i = index"
								[image]="slide.img" overlayColor="#00000040" [hideOverlay]="false">
								<div mat-button fxLayout="column" class="slite-btn" fxLayoutAlign="space-between center"
									fxLayout.xs="column" fxLayout.sm="column">
									<p style="font-size: 20px;">{{slide.valor}}
									<p>
										<span>{{slide.text}}</span>
								</div>
							</mat-carousel-slide>
						</mat-carousel>
					</div>
				</div>
			</div>
			<div class="flex-items">
				<br>
				<div id="divChart" style="border: 10px solid blue;">
					<canvas id="myChart"></canvas>
				</div>
			</div>


			<div class="flex-items">
				<div class="image-box-inner">
					<div fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column"
						style=" width: 100%; height: 100%;">
						<div class="" style="border: 10px solid blue; width: 100%; height: 100%;">
							<br>
							<br>
							<br>
							<br>
							<div id="divChart2">
								<canvas id="myChart2"></canvas>
							</div>
						</div>
						<div class="">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div> -->




		<!-- <div class="flex-items" >

					<mat-carousel
						timings="250ms ease-in"
						[autoplay]="true"
						interval="5000"
						color="accent"
						maxWidth="auto"
						proportion="25"
						slides="5"
						[loop]="true"
						[hideArrows]="false"
						[hideIndicators]="false"
						[useKeyboard]="true"
						[useMouseWheel]="false"
						[maintainAspectRatio]="false"
						orientation="ltr"
					>
						<mat-carousel-slide
							#matCarouselSlide
							*ngFor="let slide of slides; let i = index"
							[image]="slide.img"
							overlayColor="#00000040"
							[hideOverlay]="false"
						>
							<div mat-button fxLayout="column" class="slite-btn"  fxLayout.xs="column" fxLayout.sm="column">
								<p style="font-size: 20px;">{{slide.valor}}<p>
								<span>{{slide.text}}</span>
							</div>
						</mat-carousel-slide>
					</mat-carousel>
					

				</div> -->
		<div *ngIf="tipoUsuario !== ''" class="flex-items">
			<div *ngIf="tipoUsuario !== ''" style="width: 100%; height: 100%;">
				<br>
				<div id="divChart" style="margin-left: 10px; width: 100%;">
					<canvas #myChart></canvas>
				</div>
			</div>
		</div>
	</div>
</mat-card>