import { Injectable } from '@angular/core';
import { FormControl, Validator, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {


	private textRegex : string = "[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1 ]*";
	private numberRegex : string = "[0-9]*";

	amountOtherThanZero : Validator;
	amountGreaterThanZero : Validator;
	checkPasswords : any;
	textPattern : any;
	numberPattern : any;


	constructor() {

		this.amountOtherThanZero = this._amountOtherThanZero.bind(this);
		this.amountGreaterThanZero = this._amountGreaterThanZero.bind(this);
		this.checkPasswords = this._checkPasswords.bind(this);
		this.textPattern = Validators.pattern(this.textRegex);
		this.numberPattern = Validators.pattern(this.numberRegex);
	}



	private _amountOtherThanZero(control: FormControl) {

		let amount = control.value;

		return amount ? (amount != 0 ? null : { equalToZero: true }) : null;
	}


	private _amountGreaterThanZero(control: FormControl) {

		let amount = control.value;

		return amount ? (amount > 0 ? null : { lowerOrEqualThanZero: true }) : null;  
	}


	private _checkPasswords(otherControl: FormControl) {

		return (control: FormControl) => {

			let repeatPass = control.value;

			if (repeatPass == '' || repeatPass == null)
			{
				return null;
			}

			return otherControl.value == repeatPass ? null : { notSame: true };
		}
	}
}
