import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterService {

	constructor(private _router : Router) {

	}

	get router() : Router {return this._router;};

	get extensions() : any {return this._router;};


	back() {
		
	}
}
