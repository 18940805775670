<mat-card class="custum-card">
	<h1>SEGUIMIENTO DE COMPRAS</h1>
	<div class="edit-link" fxLayout="row" fxLayoutAlign="end">
		<a onclick="window.history.go(-1); return false;" [routerLink]="">Regresar</a>
	</div>

	<div class="purchase-table">

			<mat-spinner *ngIf="loading" fxFlexAlign="center" color="primary" diameter="40"></mat-spinner>


			<!-- ------------------------------------------------------------------------------------------- -->
			<!--                            INTEGRACION DE TRACKING PUNTO A PUNTO                            -->
			<!-- ------------------------------------------------------------------------------------------- -->

			<div *ngIf="!loading" class="new-progress" fxLayout="column" fxLayoutAlign="center start">
				<h4>NO. DE PEDIDO: {{order.number}}</h4>
				<!-- <div  *ngIf="pedido.pedido.direccion == 0 && pedido.pedido.tipo_documento != 'ZT08'" class="progress-line-recoje">
					
					<div [ngClass]="{'progress-si': tracking.estados.confirmado}" class="progress-no">
						<div class="progress-box">
							<img src="../../assets/icons/step-icon.svg" />
						</div>
						<div class="progress-text">Confirmado</div>
					</div>
					<div [ngClass]="{'progress-si': tracking.estados.cargado}" class="progress-no">
						<div class="progress-line"></div>
						<div class="progress-box">
							<img src="../../assets/icons/step-icon.svg" />
						</div>
						<div class="progress-text">Despachado</div>
					</div>
					<div [ngClass]="{'progress-si': tracking.estados.entregado}" class="progress-no">
						<div class="progress-line"></div>
						<div class="progress-box">
							<img src="../../assets/icons/step-icon.svg" />
						</div>
						<div class="progress-text">Facturado</div>
					</div>
				</div> -->


				<ng-container *ngIf="order && order.tracking">
					<div class="progress-line container-tracking">
						<ng-container *ngFor="let item of order.tracking, let i = index">
							<div [ngClass]="{'progress-si': item.isComplete}" class="progress-no">
								<div *ngIf="i > 0" class="progress-line"></div>
								<div class="progress-box">
									<img src="../../assets/icons/step-icon.svg" />
								</div>
								<div class="progress-text">{{item.name}}</div>
								<span *ngIf="item.progress >= 0" style="font-size: 12px;">({{toNumberContable(item.progress)}}%)</span>
							</div>
						</ng-container>
					</div>
				</ng-container>


			</div>

			<div class="detailed-table"  *ngIf="!loading">
				<h3>DETALLE DEL PEDIDO</h3>

				<div class="custum-table">
					<table mat-table [dataSource]="order.summariesDetails" class="mobile-table">


						<ng-container matColumnDef="descripcion">
							<th mat-header-cell *matHeaderCellDef> Descripcion </th>
							<td mat-cell *matCellDef="let element"> {{element.description}}</td>
						</ng-container>
						<ng-container matColumnDef="solicitado">
							<th mat-header-cell *matHeaderCellDef> Solicitado </th>
							<td mat-cell *matCellDef="let element"> {{element.required| number:'1.2-2'}}</td>
						</ng-container>
						<ng-container matColumnDef="despacho">
							<th mat-header-cell *matHeaderCellDef> Despacho </th>
							<td mat-cell *matCellDef="let element"> 
								<ng-container *ngIf="element.status != 'C' || element.dispatched > 0">
									{{element.dispatched | number:'1.2-2'}}
								</ng-container>
								<ng-container *ngIf="element.status == 'C' && element.dispatched == 0">
									Cerrado
								</ng-container>
							</td>
						</ng-container>
						<ng-container matColumnDef="facturado">
							<th mat-header-cell *matHeaderCellDef> Facturado </th>
							<td mat-cell *matCellDef="let element"> 
								<ng-container *ngIf="element.status != 'C' || element.invoiced > 0">
									{{element.invoiced | number:'1.2-2'}}
								</ng-container>
								<ng-container *ngIf="element.status == 'C' && element.invoiced == 0">
									Cerrado
								</ng-container>
							</td>
						</ng-container>
						
						<tr mat-header-row *matHeaderRowDef="displayedColumnsTwo"></tr>
						<tr mat-row *matRowDef="let row; columns: displayedColumnsTwo;"></tr>
					</table>
				</div>
			</div>

			<div class="detailed-table" *ngIf="!loading">
				<h3>DOCUMENTACION</h3>

				<div class="custum-table">
					<table mat-table [dataSource]="dataSourceThree" class="mobile-table">


						<ng-container matColumnDef="tipodedocumento">
							<th mat-header-cell *matHeaderCellDef> Tipo de documento </th>
							<td mat-cell *matCellDef="let element"> {{element.tipodedocumento}}</td>
						</ng-container>
						<!-- element.file == 'orden_compra' || 
						element.file == 'factura' ||
						element.file == 'despacho' ||
						element.file == 'adicionales' -->
						<ng-container matColumnDef="estado">
							<th mat-header-cell *matHeaderCellDef> Estado </th>
							<td mat-cell *matCellDef="let element">
								<button mat-button 
								 [ngClass]=" 
								disableButton(element)
								? 'off-btn' : 'download-btn'" 
								[disabled]="disableButton(element)">
									<img src="../../assets/icons/download-icon.svg" />
									{{element.estado}}
								</button>
							</td>
						</ng-container>



						<tr mat-header-row *matHeaderRowDef="displayedColumnsThree"></tr>
						<tr mat-row *matRowDef="let row; columns: displayedColumnsThree;" (click)="getDocuments(row.file)"></tr>
					</table>
				</div>
			</div>

			<!--<div class="card" *ngIf="!loading">
				
				<div class="card-body">



					<div class="accordion mt-3" id="accordionDetail" *ngIf="tracking.detalle_completo">
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingOne">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
									data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
									DETALLE DE TRACKING
								</button>
							</h2>
							<div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
								data-bs-parent="#accordionDetail">
								<div class="accordion-body">

									<div class="table-responsive"
										*ngIf="tracking.detalle_completo.length !=0; else withoutData">
										<table class="table table-striped">
											<thead>
												<tr>
													<th scope="col">Descripción de Producto</th>
													<th scope="col" class="text-center">Solicitado</th>
													<th scope="col" class="text-center">Despacho</th>
													<th scope="col" class="text-center">Facturado</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let item of tracking.detalle_completo">
													<td>{{item.descripcion}}</td>
													<td class="text-center">{{item.solicitado | number:'1.2-2'}}</td>
													<td class="text-center">{{item.despacho | number:'1.2-2'}}</td>
													<td class="text-center">{{item.facturado | number:'1.2-2'}}</td>
												</tr>
											</tbody>
										</table>
									</div>
									<ng-template #withoutData>
										<h4>Actualmente no posee un detalle de pedido</h4>
									</ng-template>
								</div>
							</div>

						</div>
					</div>

				</div>
			</div>-->

			<!-- ------------------------------ FIN DE TRACKING PUNTO A PUNTO ------------------------------ -->

			<!--<div *ngIf="!loading" class="progress pt-3" fxLayout="row" fxLayoutAlign="center start"
				style="height: 13rem;">



				<div class="step" [ngClass]="{'active': tracking.estados.confirmado}"
					fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.5em">
					<span class="porcent">{{(tracking.estados.confirmado) ? '100':'0'}}%</span>

					<div fxLayout="row" fxLayoutAlign="center center" class="full-width">
						<div class="line left hidden"></div>
						<div class="circle"></div>
						<div class="line right"></div>
					</div>

					<div class="step-image" fxLayout="column" fxLayoutAlign="center center">
						<app-icon
							[icon]="'confirm'+(tracking.estados.confirmado ? (xs ? '_primary' : '') : (xs ? '' : '_primary'))"
							size="32">
						</app-icon>
					</div>

					<span style="font-family: open sans; font-size: 10px; font-weight: bold; ">PEDIDO <br> CONFIRMADO</span>

				</div>

				<div class="step" [ngClass]="{'active': tracking.estados.programado}"
					fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.5em">

					<span class="porcent">{{(tracking.estados.programado ? '100':'0')}}%</span>
					<div fxLayout="row" fxLayoutAlign="center center" class="full-width">
						<div class="line left"></div>
						<div class="circle"></div>
						<div class="line right"></div>
					</div>

					<div class="step-image" fxLayout="column" fxLayoutAlign="center center">
						<app-icon
							[icon]="'calendar'+(tracking.estados.programado? (xs ? '_primary' : '') : (xs ? '' : '_primary'))"
							size="32"></app-icon>
					</div>

					<span style="font-family: open sans; font-size: 10px; font-weight: bold; ">ASIGNADO <br> PROGRAMADO</span>

				</div>

				<div class="step" [ngClass]="{'active': tracking.estados.cargado || tracking.estados.entregado}"
					fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.5em">
					<span class="porcent">{{tracking.linea.despacho.porcentaje}}%</span>
					<div fxLayout="row" fxLayoutAlign="center center" class="full-width">
						<div class="line left"></div>
						<div class="circle"></div>
						<div class="line right"></div>
					</div>

					<div class="step-image" fxLayout="column" fxLayoutAlign="center center">
						<app-icon
							[icon]="'products'+(tracking.estados.cargado || tracking.estados.entregado ? (xs ? '_primary' : '') : (xs ? '' : '_primary'))"
							size="32"></app-icon>
					</div>

					<span style="font-family: open sans; font-size: 10px; font-weight: bold; ">PEDIDO <br /> DESPACHADO</span>

				</div>

				<div class="step" [ngClass]="{'active': tracking.factura.generado}"
					fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.5em">
					<span class="porcent">{{tracking.linea.facturado.porcentaje}}%</span>
					<div fxLayout="row" fxLayoutAlign="center center" class="full-width">
						<div class="line left"></div>
						<div class="circle"></div>
						<div class="line right hidden"></div>
					</div>

					<div class="step-image" fxLayout="column" fxLayoutAlign="center center">
						<app-icon
							[icon]="'dispatch'+(tracking.factura.generado ? (xs ? '_primary' : '') : (xs ? '' : '_primary'))"
							size="32"></app-icon>
					</div>

					<span>PEDIDO FACTURADO</span>

				</div>

			</div>-->


			<!--<div class="card" *ngIf="!loading">
				<div class="card-header border-0">
					DOCUMENTACIÓN
				</div>
				<div class="card-body">

					<div *ngIf="!loading" class="progress files pt-2" fxLayout="row" fxLayoutAlign="center start"
						style="height: 7rem;">

						<div class="step" [ngClass]="{'active': tracking.pedido.generado}" fxLayout="column"
							fxLayoutAlign="start center" fxLayoutGap="0.5em">

							<div class="step-image" fxLayout="column" fxLayoutAlign="center center"
								(click)="getDocuments('pedido')">
								<app-icon icon="order" size="32"></app-icon>
							</div>

							<span>PEDIDO</span>

						</div>

						<div class="step" [ngClass]="{'active': tracking.despacho.generado}" fxLayout="column"
							fxLayoutAlign="start center" fxLayoutGap="0.5em">

							<div class="step-image" fxLayout="column" fxLayoutAlign="center center"
								(click)="getDocuments('despacho')">
								<app-icon icon="dispatch" size="32"></app-icon>
							</div>

							<span>DESPACHO</span>

						</div>

						<div class="step" [ngClass]="{'active': tracking.factura.generado}" fxLayout="column"
							fxLayoutAlign="start center" fxLayoutGap="0.5em">

							<div class="step-image" fxLayout="column" fxLayoutAlign="center center"
								(click)="getDocuments('factura')">
								<app-icon icon="receipt" size="32"></app-icon>
							</div>

							<span>FACTURA</span>

						</div>

						<div class="step" [ngClass]="{'active': tracking.adicionales.generado}" fxLayout="column"
							fxLayoutAlign="start center" fxLayoutGap="0.5em">

							<div class="step-image" fxLayout="column" fxLayoutAlign="center center"
								(click)="getDocuments('adicionales')">
								<app-icon icon="docs" size="32"></app-icon>
							</div>

							<span>DOC. ADICIONALES</span>

						</div>

					</div>
				</div>
			</div>-->

		

	</div>


</mat-card>