import { Component, OnInit } from '@angular/core';
import { ActivationEnd } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MediaObserver } from '@angular/flex-layout';
import { RouterService } from '../../services/router.service';
import { Page } from '../../adapters/page';


export interface HomeSection {
	icon: string;
	text: string;
	path: string;
	module?: string;
	hidden?: boolean;
	disabled?: boolean;
}

@Component({
  selector: 'app-finances',
  templateUrl: './finances.component.html',
  styleUrls: ['./finances.component.scss']
})
export class FinancesComponent implements OnInit {

	year : number = (new Date()).getFullYear();

	username : string;

	sections : HomeSection[] = [];

	constructor(private routerService: RouterService,
				private authService: AuthService,
				private media: MediaObserver,
				private page: Page) {

		this.page.actionBarHidden = true;

		this.loadSections();
	}


	get router() {return this.routerService.router;}


	get xs() : boolean {
		return this.media.isActive('xs');
	}



	loadSections() {

		this.sections = [
			{icon: 'order_primary', text: 'Estado de cuenta', path: '/account', module: 'account'},
			{icon: 'docs_primary', text: 'Administrar factura y pagos', path: '/invoices', module: 'invoices'},
			{icon: 'money_primary', text: 'Planes de pago', path: '/plans', module: 'plans'},
			{icon: 'credits_primary', text: 'Gestiones crediticias', path: '/credits', module: 'credits'},
			{icon: 'receipt_primary', text: 'Órdenes de pago', path: '/orders', module: 'orders'}
		];
	}

	section(index: number) : HomeSection {

		const section = this.enabledSection(index);

		if (section.module)
		{
			const user = this.authService.user;

			section.disabled = (!user.admin && user.role != 'admin') && (user.modules == null || user.modules.indexOf(section.module) < 0);
		}

		return section;
	}

	enabledSection(index: number) : HomeSection {

		if (this.sections && this.sections.length > 0)
		{
			if (index < this.sections.length)
			{
				return this.sections[index];
			}
			else
			{
				const section = {...this.sections[0]};
				section.hidden = true;

				return section;
			}
		}
		else
		{
			return null;
		}
	}


	openSection(index: number) {

		const section = this.section(index);

		if (section.hidden || !section.path) return;

		this.router.navigate([section.path]);
	}



	ngOnInit() {

	}

}
