import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ClaimData, Claim } from '../classes/claim';
import { DatabaseService } from '../services/database.service';
import { StorageService } from '../services/storage.service';
import { SnackbarService } from '../services/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {

	constructor(private database: DatabaseService,
				private snackbar: SnackbarService,
				private storageService: StorageService) {

	}


	get(claimID: string) : Promise<Claim> {

		var promise = new Promise<Claim>((resolve, reject) => {

			this.database.get<Claim>('claims', claimID).then(claim => {

				resolve(claim);

			}).catch(reason => {

				reject(reason);
			})
		});

		return promise;
	}


	set(claim: Claim, silent?: boolean) : Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			const claimData : ClaimData = {...claim};

			this.database.set('claims', claim.id, claimData, silent ? null : {
				success: "Reclamo enviado",
				error: "Error al enviar el reclamo"
			}).then(result => {
				resolve(result);
			}).catch(reason => {
				reject(reason);
			});
		});

		return promise;
	}


	delete(claim: Claim, silent?: boolean) : Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			this.database.delete('claims', claim.id, silent ? null : {
				success: "Reclamo eliminado",
				error: "Error al eliminar reclamo"
			}).then(result => {
				resolve(result);
			}).catch(reason => {
				reject(reason);
			});
		});

		return promise;
	}
}
