<div *ngIf="!loading" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2em">

	<h4>{{offer ? 'Editar' : 'Nueva'}} oferta</h4>

	<div class="full-width" fxLayout="column" fxLayoutAlign="start start">

		<form class="full-width" [formGroup]="form" id="newOfferForm" (ngSubmit)="save()" autocomplete="off" fxLayout="column" fxLayoutAlign="start center">

			<mat-form-field class="primary full-width" appearance="outline" color="primary">
				<mat-label>TÍTULO</mat-label>
				<input matInput formControlName="title" maxlength="100" autocomplete="off">
				<mat-error *ngIf="form.get('title').hasError('required')">
					Título requerido
				</mat-error>
			</mat-form-field>

			<br/>

			<mat-form-field class="primary full-width" appearance="outline" color="primary">
				<mat-label>DESCRIPCIÓN</mat-label>
				<textarea matInput formControlName="description" maxlength="250" autocomplete="off" rows="3"></textarea>
				<mat-error *ngIf="form.get('description').hasError('required')">
					Descripción requerida
				</mat-error>
			</mat-form-field>

			<br/>

			<div class="full-width" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1em" fxLayout.xs="column">

				<mat-form-field class="primary button-suffix" appearance="outline" color="primary">
					<mat-label>FECHA DE INICIO</mat-label>
					<input matInput [matDatepicker]="datePicker" formControlName="date">
					<mat-datepicker-toggle matSuffix [for]="datePicker">
						<app-icon matDatepickerToggleIcon icon="calendar_primary" size="22"></app-icon>
					</mat-datepicker-toggle>
					<mat-datepicker #datePicker color="primary"></mat-datepicker>
				</mat-form-field>

				<mat-form-field class="primary button-suffix" appearance="outline" color="primary">
					<mat-label>VENCIMIENTO</mat-label>
					<input matInput [matDatepicker]="expirationPicker" formControlName="expiration">
					<mat-datepicker-toggle matSuffix [for]="expirationPicker">
						<app-icon matDatepickerToggleIcon icon="calendar_primary" size="22"></app-icon>
					</mat-datepicker-toggle>
					<mat-datepicker #expirationPicker color="primary"></mat-datepicker>
				</mat-form-field>
			</div>

			<br/>

			<mat-form-field *ngIf="!offer?.file" class="primary full-width" appearance="outline" color="primary">
				<mat-label>ARCHIVO</mat-label>
				<ngx-mat-file-input #fileInput formControlName="file" accept="application/pdf,image/*"></ngx-mat-file-input>
				<button mat-icon-button color="primary" matSuffix *ngIf="!fileInput.empty" (click)="fileInput.clear($event)">
					<mat-icon>clear</mat-icon>
				</button>
			</mat-form-field>


			<div *ngIf="offer?.file" class="file-container" fxLayout="column" fxLayoutAlign="start start">
				<mat-label>ARCHIVO</mat-label>
				<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em">

					<span>1 archivo {{extension}}</span>

					<button type="button" mat-button color="primary" (click)="download()"><img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">Descargar</button>
					<button type="button" mat-button color="primary" (click)="deleteFileDialog()"> <img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">Eliminar</button>
				</div>
			</div>


			<br/><br/>

			<mat-spinner *ngIf="saving" color="primary" diameter="40"></mat-spinner>

			<button *ngIf="!saving" class="main-button full-width" mat-flat-button color="primary" form="newOfferForm" type="submit">
				<img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">	GUARDAR
			</button>

		</form>

	</div>

</div>


<div *ngIf="loading" class="spinner-container" fxLayout="column" fxLayoutAlign="center center">
	<mat-spinner color="primary" diameter="40"></mat-spinner>
</div>

