import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserData, User } from 'src/app/classes/user';
//import { StorageService } from 'src/app/services/storage.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class UsersService {


	customers : BehaviorSubject<User[]> = new BehaviorSubject(null);


	constructor(private apiService: ApiService,
				private authService: AuthService,
				private snackbar: SnackbarService,
				//private storageService: StorageService,
				private http: HttpClient) {
	}



	users() : BehaviorSubject<User[]> {

		const bs = new BehaviorSubject<User[]>(null);

		this.apiService.getUsers().then(result => {

			if (result)
			{
				bs.next(result);
			}
		});

		return bs;
	}




	get() : Promise<User> {

		return this.apiService.getUser();
	}

	add(user: UserData, password: string, silent?: boolean) : Promise<any> {


		var promise = new Promise<any>((resolve, reject) => {


			const url = 'https://us-central1-multigroup-sa.cloudfunctions.net/createUserdos?user='+user.username+'&password='+password;

			this.http.get(url, {responseType: 'json'}).subscribe((res : any) => {

				if (res.ok)
				{
					const userID = res.uid;

					return this.apiService.setUser(userID, user).then(result => {
						this.snackbar.show('Usuario creado');
						resolve('Usuario creado');
					}).catch(reason => {
						reject(reason);
					});
				}
				else
				{
					this.snackbar.show(res.error);
					reject(res.error);
				}

			}, err => {
				reject(new Error(err));
				let errorMessage = this.authService.errorMessageWithCode(err.error.error.code);
				if (errorMessage == 'Error al iniciar'){
					errorMessage = err.error.error.message;
				}
				this.snackbar.show(errorMessage);
				reject(errorMessage);
			});
		});

		return promise;
	}


	set(user: User) : Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			var userData : UserData = {...user};

			return this.apiService.setUser(user.id, userData).then(result => {
				resolve(result);
			}).catch(reason => {
				reject(reason);
			});

		});

		return promise;
	}


	getImageUrl(user: User) : Promise<string> {

		var promise = new Promise<string>((resolve, reject) => {

			if (user.profileImagePath)
			{
				/*this.storageService.getUrl(user.profileImagePath).then(url => {

					if (url)
						resolve(url);
				});*/

				resolve(user.profileImageUrl);
			}
			else if (user.profileImageUrl)
			{
				resolve(user.profileImageUrl);
			}
			else
			{
				resolve(null);
			}
		});

		return promise;
	}
}
