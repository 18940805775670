import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Product } from '../../classes/product';
//import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-product-preview',
  templateUrl: './product-preview.component.html',
  styleUrls: ['./product-preview.component.scss']
})
export class ProductPreviewComponent implements OnInit {

	@Input('product') product : Product;

	@Input('confirm') confirm : Function;
	@Input('close') close : Function;
	@Input('errorMessage') errorMessage : string;

	@Input() loading : boolean;
	@Output() loadingChange = new EventEmitter<boolean>();

	setLoading(value: boolean) {
		this.loading = value;
		this.loadingChange.emit(this.loading);
	}

	constructor(
		//private storageService: StorageService
	) {

	}

	ngOnInit() {

	}


	download() {

		//this.storageService.downloadFile(this.product.file, this.product.title);
	}

}
