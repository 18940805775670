import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ViewChild } from '@angular/core';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

	@Input('docs') docs : {name: string, url: string}[] = [];

	@Input('confirm') confirm : Function;
	@Input('close') close : Function;
	@Input('errorMessage') errorMessage : string;

	@Input() loading : boolean;
	@Output() loadingChange = new EventEmitter<boolean>();

	setLoading(value: boolean) {
		this.loading = value;
		this.loadingChange.emit(this.loading);
	}

	constructor() {

	}

	ngOnInit() {

	}


	download(doc: {name: string, url: string}) {

		var a = document.createElement("a");
		a.href = doc.url;
		a.target = '_blank';
		a.download = doc.name;
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(doc.url);
		a.remove();
	}

}
