import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

	constructor() {

	}


	download(url: string, name?: string) {

		var a = document.createElement("a");
		a.href = url;
		a.target = '_blank';
		a.download = name;
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
		a.remove();
	}
}
