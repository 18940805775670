<div *ngIf="!loading" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2em">

	<h4>{{user.active ? 'Editar' : 'Habilitar'}} usuario</h4>

	<div class="full-width" fxLayout="column" fxLayoutAlign="start start">

		<form class="full-width" [formGroup]="form" id="newUserForm" (ngSubmit)="save()" autocomplete="off" fxLayout="column" fxLayoutAlign="start center">

			<mat-form-field class="primary" appearance="outline" color="primary">
				<mat-label>EMPRESAS</mat-label>
				<mat-select formControlName="companies" multiple>
					<mat-option *ngFor="let _company of companies" [value]="_company.id">{{_company.nombre}}</mat-option>
				</mat-select>
				<mat-error *ngIf="form.get('companies').hasError('required')">
					Empresa requerida
				</mat-error>
			</mat-form-field>

			<br/>

			<mat-form-field class="primary" appearance="outline" color="primary">
				<mat-label>MÓDULOS</mat-label>
				<mat-select formControlName="modules" multiple>
					<mat-option *ngFor="let _module of modules" [value]="_module.id">{{_module.name}}</mat-option>
				</mat-select>
				<mat-error *ngIf="form.get('modules').hasError('required')">
					Módulo requerido
				</mat-error>
			</mat-form-field>

			<br/><br/>

			<mat-spinner *ngIf="saving" color="primary" diameter="40"></mat-spinner>

			<div *ngIf="!saving" class="full-width" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1em">
				<button mat-button class="main-button" type="button" (click)="close()">Cancelar</button>
				<button class="main-button" mat-flat-button color="primary" form="newUserForm" type="submit">
					<img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">	{{user.active ? 'GUARDAR' : 'HABILITAR'}}
				</button>
			</div>

		</form>

	</div>

</div>


<div *ngIf="loading" class="spinner-container" fxLayout="column" fxLayoutAlign="center center">
	<mat-spinner color="primary" diameter="40"></mat-spinner>
</div>

