import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserData } from '../../classes/user';
import { AuthService } from '../../services/auth.service';
import { SnackbarService } from '../../services/snackbar.service';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent implements OnInit {

	@Input('confirm') confirm : Function;
	@Input('close') close : Function;
	@Input('errorMessage') errorMessage : string;

	@Input() loading : boolean;
	@Output() loadingChange = new EventEmitter<boolean>();

	setLoading(value: boolean) {
		this.loading = value;
		this.loadingChange.emit(this.loading);

		if (!this.changeDetectorRef['destroyed'])
			this.changeDetectorRef.detectChanges();
	}


	saving : boolean = false;


	form : FormGroup;

	constructor(private formBuilder: FormBuilder,
				private apiService : ApiService,
				private http: HttpClient,
				private changeDetectorRef: ChangeDetectorRef,
				private snackbar: SnackbarService) {

	}

	ngOnInit() {

		this.form = this.formBuilder.group({
			username: ['', [Validators.required]]
		});
	}


	save() {

		if (this.form.invalid)
		{
			Object.keys(this.form.controls).forEach(key => {
				this.form.controls[key].markAsTouched();
			});
			
			return;
		}

		this.setLoading(true);


		this.apiService.verifiUserName(this.form.get('username').value).then(result => {
			console.log("result", result)
			
			if (result)
				{
					const url = 'https://us-central1-multigroup-sa.cloudfunctions.net/resetPasswordtres?user='+result[0].id;

					this.http.get(url, {responseType: 'json'}).subscribe((res : any) => {

						if (res.ok)
						{
							this.snackbar.show("Te hemos enviado un correo para seguir el proceso");
							this.confirm();
						}
						else
						{
							this.snackbar.show("Error al recuperar contraseña");
							this.setLoading(false);
						}

					}, err => {
						console.log("err", err)
						this.snackbar.show("Error al recuperar contraseña");
						this.setLoading(false);
					});
				}
			else
				{
					//this.form.get('username').setErrors({invalid: true});
					this.snackbar.show("Usuario inválido");
					this.setLoading(false);
				}
			

		}, err => {
			this.snackbar.show("Error al recuperar contraseña");
			this.setLoading(false);
		});
	}

}
