import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent {

	@Input('docs') docs : {name: string, url: string}[] = [];

	@Input('confirm') confirm : Function;
	@Input('close') close : Function;
	@Input('errorMessage') errorMessage : string;

	@Input() loading : boolean;
	@Input() listLoading : number;
	@Output() loadingChange = new EventEmitter<boolean>();
	@Output() listLoadingChange = new EventEmitter<number>();

	setLoading(value: boolean, index: number) {
		this.loading = value;
		this.listLoading = index;
		this.loadingChange.emit(this.loading);
		this.listLoadingChange.emit(this.listLoading);
	}

	constructor(public api: ApiService,
		private http: HttpClient) {

	}

	async download(doc: {name: string, url: string, typeDownload:string}, index: number) {
		if (doc.typeDownload == 'DOWNLOAD'){
			this.setLoading(true, index)
			const token = localStorage.getItem('tokenGL');;
			await this.downloadAuth(doc.url, token);
		}else{
			var a = document.createElement("a");
			a.href = doc.url;
			a.target = '_blank';
			a.download = doc.name;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(doc.url);
			a.remove();
		}
		
		
	}

	downloadAuth(url: string, token?:string) {

		this.http.get(url, {responseType: "blob", headers: {'Accept': 'application/pdf', 'authorization': 'Bearer '+token }})
		.subscribe(blob => {
			//saveAs(blob, name);
			var _url = window.URL.createObjectURL(blob);
			window.open(_url, "_blank").focus();
			this.setLoading(false, 0)
		});

	}

}
