
<!-- <app-navbar bgcolor="primary" forceColor="true"></app-navbar> -->

<div class="message" ngClass.xs="xs" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.xs="center end">

	<h1>¡Gracias por tu registro<strong>{{username ? ' '+username : ''}}</strong>!</h1>

	<h2>El administrador de tu empresa debe asignarte roles para entrar a la aplicación...</h2>

	<img class="logo" src="assets/images/multi.png" alt="Multi" />

</div>