<div class="product-preview" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1em">

	<h4>{{product.title}}</h4>

	<br/>

	<div class="full-width" fxLayout="column" fxLayoutAlign="start start">
		<mat-label>DESCRIPCIÓN</mat-label>
		<p class="text">{{product.description}}</p>
	</div>
</div>