





<div class="login">
	<div class="login-left">
		<div class="login-info-left">
			<h1>
				<span class="title">BIENVENIDOS </span>
				<span class="subtitle">PORTAL DE CLIENTES</span>
			</h1>
			<p>¡Bienvenido al portal de clientes de nuestra empresa líder en acero!</p>
			<p>Aquí accederás a información detallada sobre nuestros productos y el estado de tus entregas.
			Contáctanos en nuestro whatsapp para cualquier pregunta o requerimiento especial.</p>
			<p>¡Gracias por confiar en nosotros y disfruta de todas las ventajas de nuestro portal!</p>
		</div>
	</div>
	<div class="login-right">
		<a href="https://api.whatsapp.com/send?phone=50223205555" target="_blank" class="whatsapp-icon-wrap">
			<img
			  class="whatsapp-stick-icon"
			  src="../../assets/images/whatsapp.png"
			  alt=""
			  srcset=""
			/>
		</a>
		<div class="login-form">
			<div class="login-form-inner">
				<div class="logo-box">
					<img src="../../assets/images/multi_red.png" alt="Logo" />
				</div>

				<div class="login-form-box">
					<h2>Login</h2>
					
						<!-- <mat-form-field class="full-width" appearance="outline" color="accent">
							<mat-label>USUARIO</mat-label>
							<input matInput formControlName="username" maxlength="100" autocomplete="off">
						</mat-form-field>
						
						<mat-form-field class="full-width" appearance="outline" color="accent">
							<mat-label>CONTRASEÑA</mat-label>
							<input matInput type="password" formControlName="password" maxlength="100" autocomplete="off">
						</mat-form-field> -->
						<!-- <form>
						<div class="input-wrap">
							<input type="text" placeholder="Usuario" />
						</div>

						<div class="input-wrap">
							<input type="text" placeholder="Contrasena" />
						</div>

						<div class="input-wrap normal-line">
							<a href="#" class="login-link">Recuperar Contrasena</a>
						</div>

						<div class="input-wrap normal-line">
							<button class="login-btn">
								<img src="../../assets/images/multi_arrow.png" />
								INGRESAR
							</button>
						</div>
					</form> -->
					<mat-spinner *ngIf="loading" color="red" diameter="40"></mat-spinner>
					
					
					<form *ngIf="!loading" class="full-width" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1.5em"
						[formGroup]="form" (ngSubmit)="formLogin()" autocomplete="off">
						
					<div class="input-wrap">
						<mat-form-field class="full-width" appearance="outline">
							
							<input style="height: 30px;" matInput formControlName="username" maxlength="100" autocomplete="off" placeholder="USUARIO">
							<mat-label style="font-size: 14px; margin-left: 5px; margin-bottom: 5px;" >USUARIO</mat-label>
							<mat-error *ngIf="form.get('username').hasError('required')">Usuario requerido</mat-error>
						</mat-form-field>
					</div>
					<div class="input-wrap">
						<mat-form-field class="full-width" appearance="outline">
							
							<input style="height: 30px;" matInput type="password" formControlName="password" maxlength="100" autocomplete="off" placeholder="CONTRASEÑA">
							<mat-label style="font-size: 14px; margin-left: 5px; margin-bottom: 10px;">CONTRASEÑA</mat-label>
							<mat-error *ngIf="form.get('password').hasError('required')">Contraseña requerida</mat-error>
						</mat-form-field>
					
					</div>
						<span ngClass.gt-xs="error" *ngIf="authService.loginStatus == 'Error'">
							{{authService.errorMessage}}
						</span>

						<p>Por favor, ingresa tu usuario y contraseña para poder tener acceso al sistema.</p>
						
						<div class="input-wrap normal-line">
							<a (click)="resetPasswordDialog()" class="login-link">Recuperar Contrasena</a>
						</div>

						
						<div class="input-wrap normal-line">
							<button class="login-btn" type="submit
							">
								<img src="../../assets/images/multi_arrow.png" />
								INGRESAR
							</button>
						</div>


					
						<!-- <button mat-flat-button type="submit">INGRESAR</button> -->
					
						<!-- <button mat-button type="button" (click)="resetPasswordDialog()">Recuperar contraseña</button> -->
					
					</form>

					<div class="after-login">
						<h3>NUEVOS USUARIOS</h3>
						<button class="login-btn" (click)="openRegistro();">
							<img src="../../assets/images/multi_arrow.png" />
							REGISTRO
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>