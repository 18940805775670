

<app-new-customer *ngIf="data.type == 'newCustomer'" [(loading)]="loading" [confirm]="confirmFunction" [close]="closeFunction" [errorMessage]="errorMessage" [user]="data.object?.user"></app-new-customer>

<app-new-offer *ngIf="data.type == 'newOffer'" [(loading)]="loading" [confirm]="confirmFunction" [close]="closeFunction" [errorMessage]="errorMessage" [offer]="data.object?.offer"></app-new-offer>

<app-new-product *ngIf="data.type == 'newProduct'" [(loading)]="loading" [confirm]="confirmFunction" [close]="closeFunction" [errorMessage]="errorMessage" [product]="data.object?.product"></app-new-product>

<app-product-preview *ngIf="data.type == 'productPreview'" [(loading)]="loading" [confirm]="confirmFunction" [close]="closeFunction" [errorMessage]="errorMessage" [product]="data.object?.product"></app-product-preview>

<app-offer-preview *ngIf="data.type == 'offerPreview'" [(loading)]="loading" [confirm]="confirmFunction" [close]="closeFunction" [errorMessage]="errorMessage" [offer]="data.object?.offer"></app-offer-preview>

<app-profile-edit *ngIf="data.type == 'editProfile'" [(loading)]="loading" [confirm]="confirmFunction" [close]="closeFunction" [errorMessage]="errorMessage"></app-profile-edit>


<app-user *ngIf="data.type == 'user'" [(loading)]="loading" [confirm]="confirmFunction" [close]="closeFunction" [errorMessage]="errorMessage" [user]="data.object?.user"></app-user>

<app-change-password *ngIf="data.type == 'changePassword'" [(loading)]="loading" [confirm]="confirmFunction" [close]="closeFunction" [errorMessage]="errorMessage"></app-change-password>

<app-reset-password *ngIf="data.type == 'resetPassword'" [(loading)]="loading" [confirm]="confirmFunction" [close]="closeFunction" [errorMessage]="errorMessage"></app-reset-password>


<app-documents *ngIf="data.type == 'documents'" [(loading)]="loading" [confirm]="confirmFunction" [close]="closeFunction" [errorMessage]="errorMessage" [docs]="data.object?.docs"></app-documents>


<div *ngIf="data.type == 'Text'">

	<h4 *ngIf="data.title">{{data.title}}</h4>

	<p class="text">{{data.text}}</p>
</div>

<div *ngIf="data.type == 'Alert' || data.type == 'Warn'" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1em">

	<h4>{{data.title ? data.title : 'Alerta'}}</h4>

	<div class="full-width" fxLayout="column" fxLayoutAlign="start center">

		<p *ngIf="!loading" class="text">{{data.text}}</p>

		<mat-spinner *ngIf="loading" diameter="40"></mat-spinner>
	</div>

	<div *ngIf="!loading && data.confirm" class="full-width" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1em">
		<button mat-button class="main-button" type="button" (click)="closeClick()">Cancelar</button>
		<button mat-flat-button class="main-button" type="button" [color]="data.type == 'Warn' ? 'primary' : 'primary'" (click)="confirm(data.object, true)"><img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">Confirmar</button>
	</div>

	<div *ngIf="!loading && !data.confirm" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1em">
		<button mat-flat-button type="button" class="main-button" [color]="data.type == 'Warn' ? 'primary' : 'primary'" (click)="closeClick()"> <img class="multi_arrow" [src]="'assets/images/multi_arrow.png'"> Aceptar</button>
	</div>
</div>