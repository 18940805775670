import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NumbersService {

	constructor() {

	}

	fixedNumber(num?: string | number, abs?: true) : string {

		if (num == null) return '0.00';

		var stringNum : string = '0.00';

		if (num.toString)
			stringNum = Math.abs((num as number)).toString();

		var result = (parseFloat(stringNum)).toLocaleString('es-GT', {minimumFractionDigits: 2, maximumFractionDigits: 2});

		return result;
	}
}
