import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { SnackbarService } from '../../services/snackbar.service';
import { DialogService } from '../../services/dialog.service';
import { DatesService } from '../../services/dates.service';
import { AuthService } from '../../services/auth.service';
import { NumbersService } from '../../services/numbers.service';
import { SessionService } from '../../services/session.service';
import { DownloadService } from '../../services/download.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatInput } from '@angular/material/input';
import { MediaObserver } from '@angular/flex-layout';
import { Purchase } from '../../classes/purchase';
import { Subscription, BehaviorSubject } from 'rxjs';
import { MatDatepicker } from '@angular/material/datepicker';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Page } from '../../adapters/page';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { Account } from 'src/app/classes/account';
import { Company } from 'src/app/classes/company';

@Component({
	selector: 'app-account',
	templateUrl: './account.component.html',
	styleUrls: ['./account.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('expand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	]
})
export class AccountComponent implements OnInit {

	year: number = (new Date()).getFullYear();

	loading: boolean = true;
	dataAccount: any;

	pdfSrc: String = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";

	displayedColumns: string[];
	dataSource: MatTableDataSource<Purchase> = new MatTableDataSource([]);

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	mediaSubscription: Subscription;

	societies: string
	societySelected: string
	expandedElement: Purchase;


	@ViewChild('dp', { static: true }) datepicker: MatDatepicker<Date>;
	@ViewChild('datepickerInput', { static: true }) datepickerInput: ElementRef;


	page: number = 0;
	pageSize: number = 10;



	monthDate: Date = new Date();


	filter: 'month' | '8days' = 'month';

	get user() { return this.authService.user; }

	constructor(public api: ApiService,
		private dialogService: DialogService,
		public authService: AuthService,
		public datesService: DatesService,
		public numbersService: NumbersService,
		private snackbar: SnackbarService,
		private changeDetectorRef: ChangeDetectorRef,
		public media: MediaObserver,
		private downloadService: DownloadService,
		private sessionService: SessionService,
		private _page: Page,
		private http: HttpClient) {

		this._page.actionBarHidden = true;

		this.loading = true;

		this.updateColumns();

		this.mediaSubscription = this.media.asObservable().subscribe(change => {
			this.updateColumns();
		});

		if (!this.changeDetectorRef['destroyed'])
			this.changeDetectorRef.markForCheck();
	}

	getUrl(server: string): any {
		const url = `${environment[server].protocol}://${environment[server].ip}/${environment[server].version}`;
		return url;
	}


	get xs(): boolean {
		return this.media.isActive('xs');
	}


	updateColumns() {

		if (this.media.isActive('gt-xs')) {
			this.displayedColumns = ['row_id', 'factura_numero', 'factura_fecha', 'factura_monto', 'pagos_realizados', 'saldo_pendiente', 'actions'];
		}
		else {
			this.displayedColumns = ['factura_numero', 'actions'];
		}
	}


	loadData() {

		this.updateColumns();

		this.api.purchases(this.monthDate.getFullYear(), this.monthDate.getMonth() + 1, "").then(result => {

			if (result) {
				var lastDate: Date;

				for (var purchase of result) {
					const purchaseDate = this.stringToDate(purchase.factura_fecha.toISOString());

					if (lastDate == null || lastDate.getTime() < purchaseDate.getTime()) {
						lastDate = purchaseDate;
					}
				}


				this.dataSource.data = result.filter(purchase => {

					if (this.filter == 'month') return true;

					if (lastDate) {
						const purchaseDate = this.stringToDate(purchase.factura_fecha.toISOString());

						return this.datesService.daysBetween(purchaseDate, lastDate) < 8;
					}

					return true;
				});

				this.loading = false;

				if (!this.changeDetectorRef['destroyed'])
					this.changeDetectorRef.markForCheck();
			}

		}).catch(reason => {

			this.loading = false;

			if (!this.changeDetectorRef['destroyed'])
				this.changeDetectorRef.markForCheck();
		});
	}


	stringToDate(string: string): Date {

		const doo = new Date(string);

		return new Date(doo.getTime() - doo.getTimezoneOffset() * -60000);
	}



	ngOnInit() {

		this.loadCompanies();
		/*if (this.paginator)
		{
			this.paginator._intl.itemsPerPageLabel = "Facturas por página:";
			this.paginator._intl.firstPageLabel = "Primera página";
			this.paginator._intl.previousPageLabel = "Página anterior";
			this.paginator._intl.nextPageLabel = "Página siguiente";
			this.paginator._intl.lastPageLabel = "Última página";

			this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {

				if (length == 0 || pageSize == 0) 
				{
					return `0 de ${length}`;
				}

				length = Math.max(length, 0);
				const startIndex = page * pageSize;
				const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
				return `${startIndex + 1} – ${endIndex} de ${length}`;
			};

			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		}


		const monthItem = this.sessionService.getItem('monthDate');

		if (monthItem)
			this.monthDate.setTime(JSON.parse(monthItem).date);


		this.filter = (this.sessionService.getItem('filter') as 'month' | '8days') || 'month';


		this.loadData();

		this.setInputValue();*/

		this.load_report();
		console.log("a", this.dataAccount)
	}


	ngOnDestroy() {

		if (this.mediaSubscription) this.mediaSubscription.unsubscribe();
	}


	trackByFn(index, item: Purchase) {
		return item.row_id;
	}


	chosenYearHandler(yearDate: Date) {

		this.monthDate.setFullYear(yearDate.getFullYear());
	}

	chosenMonthHandler(monthDate: Date, datepicker?: MatDatepicker<Date>) {

		this.monthDate.setMonth(monthDate.getMonth());
		this.monthDate.setFullYear(monthDate.getFullYear());

		this.sessionService.setItem('monthDate', JSON.stringify({ date: this.monthDate.getTime() }));

		if (datepicker) {
			datepicker.close();
			datepicker.select(this.monthDate);
		}


		this.setInputValue();

		this.loading = true;

		if (!this.changeDetectorRef['destroyed'])
			this.changeDetectorRef.markForCheck();

		this.loadData();
	}


	setInputValue() {

		const newValue = this.datesService.monthName(this.monthDate) + ' ' + this.monthDate.getFullYear();

		if (this.datepickerInput) {
			const input = (this.datepickerInput.nativeElement as MatInput);

			input.value = newValue;
		}
	}


	selectFilter(filter: 'month' | '8days') {

		if (this.loading) return;

		this.filter = filter;

		this.sessionService.setItem('filter', this.filter);

		this.loading = true;

		if (!this.changeDetectorRef['destroyed'])
			this.changeDetectorRef.markForCheck();

		this.loadData();
	}
	// async getTotal(param): Promise<number> {
	// 	console.log("paramm", param)
	// 	return new Promise<number>((resolve, reject) => {
	// 		this.http.get(param).subscribe((res: Account) => {
	// 			console.log("🚀 ~ file: account.component.ts:305 ~ AccountComponent ~ returnnewPromise<number> ~ res:", res)
	// 			if (res && res.currenciesIsoCodeSummaries && res.currenciesIsoCodeSummaries[1]) {
	// 				resolve(res.currenciesIsoCodeSummaries[1].summary.total);
	// 				this.dataAccount = `TOTAL: ${this.numbersService.fixedNumber(res.currenciesIsoCodeSummaries[1].summary.total)}`

	// 				return this.dataAccount
	// 			} else {
	// 				reject("No se encontró el total");
	// 				this.dataAccount = "TOTAL: 0.00"
	// 				return this.dataAccount
	// 			}
	// 		},
	// 			(error) => {
	// 				reject("Error" + error);
	// 			})
	// 	})
	// }

	companies: Company[] = [];
	companyCode!: string;
	moneda: string = '';
	loadCompanies() {

		this.api.company(this.authService.user.code).then(company => {
			if (company.moneda && company.moneda[0]) {
				this.moneda = company.moneda[0]['tipo']
			}
			this.companyCode = this.authService.user.code;
			if (company) {
				this.companies.push(company);

				for (var subcompany of (company.subempresas || [])) {
					if (this.authService.user.companies.includes(subcompany.id))
						this.companies.push(subcompany);
				}
			}
			console.log("🚀 ~ file: account.component.ts:340 ~ AccountComponent ~ this.api.company ~ company:", this.companies)
		})
	}

	async onSelectSociety(company: Company) {
		this.loading = true;
		console.log("🚀 ~ file: account.component.ts:335 ~ AccountComponent ~ onSelectSociety ~ company:", company)
		const client = await this.api.findClientById(Number(company.id));
		console.log("🚀 ~ file: account.component.ts:338 ~ AccountComponent ~ onSelectSociety ~ client:", client)
		this.companyCode = client.codigo;
		this.load_report(client.moneda);
	}
	load_report(currencyIsoCode?: string) {
		this.api.company(this.authService.user.code).then(async company => {
			// this.api.purchasesEstadoCuentaOC()
			// if (currencyIsoCode) company.moneda = currencyIsoCode;
			// else {
			// 	const client = await this.api.findClientById(Number(company.id));
			// 	console.log("🚀 ~ file: account.component.ts:349 ~ AccountComponent ~ this.api.company ~ client:", client.moneda)
			// 	company.moneda = client.moneda ?? company.moneda[0]['tipo'];
			// }
			console.log("usercode", this.authService.user)
			console.log('companys', company)
			const currentDate = new Date();
			const diffTimeUtc = currentDate.getTimezoneOffset();
			const code = company.id;
			const entidad = company['codigo_entidad']
			const url = this.getUrl('sapApiServerV3') + `/collection/account-statement/export-pdf?societyCode=${entidad}&clientCode=${this.companyCode ?? this.authService.user.code}&dateEnd=${currentDate.toISOString()}&currencyIsoCode=BOTH&diffTimeUtc=${diffTimeUtc}`;
			const urlTotal = this.getUrl('sapApiServerV3') + `/collection/account-statement?societyCode=${entidad}&clientCode=${this.companyCode ?? this.authService.user.code}&dateEnd=${currentDate.toISOString()}&currencyIsoCode=BOTH&diffTimeUtc=${diffTimeUtc}`;
			const accountStatement = await this.api.accountStatement(`?societyCode=${entidad}&clientCode=${this.companyCode ?? this.authService.user.code}&dateEnd=${currentDate.toISOString()}&currencyIsoCode=BOTH&diffTimeUtc=${diffTimeUtc}`);
			const currenciesTransactions = [...new Set([...accountStatement.currenciesIsoCodeTransactions.reduce((currenciesTransactions, currencyIsoCodeTransactions) => {
				return [...currenciesTransactions, currencyIsoCodeTransactions.currencyIsoCode]
			}, [])])];

			this.dataAccount = `Total: ${currenciesTransactions.join(',')} ${this.numbersService.fixedNumber((accountStatement.currenciesIsoCodeSummaries[0] && (
				accountStatement.currenciesIsoCodeSummaries[0].summary.current
				+ accountStatement.currenciesIsoCodeSummaries[0].summary.ninetyOneToOneHoundredTwenty
				+ accountStatement.currenciesIsoCodeSummaries[0].summary.oneHundredAndTwentyOne
				+ accountStatement.currenciesIsoCodeSummaries[0].summary.oneToThirtyOne
				+ accountStatement.currenciesIsoCodeSummaries[0].summary.sixtyToNinety
				+ accountStatement.currenciesIsoCodeSummaries[0].summary.thirtyOneToSixty
				+ accountStatement.currenciesIsoCodeSummaries[0].summary.interests
			)) ?? 0)}`;
			// this.getTotal(urlTotal)
			const name = "Estado de cuenta";

			console.log("url", url);
			this.http.get(url, {
				headers: {},

				responseType: "blob"
			}).subscribe((x: any) => {
				//var newBlob = new Blob([x], { type: "application/pdf" });
				const data = window.URL.createObjectURL(x);
				this.loading = false;
				this.pdfSrc = data;
				if (!this.changeDetectorRef['destroyed'])
					this.changeDetectorRef.markForCheck();

			})

		}).catch(reason => {
			console.log("🚀 ~ file: account.component.ts:375 ~ AccountComponent ~ this.api.company ~ reason:", reason)

			this.snackbar.show("Error en la descarga");
		});
	}

	download() {

		this.api.company(this.authService.user.code).then(async company => {
			const currentDate = new Date()
			const diffTimeUtc = currentDate.getTimezoneOffset();
			const code = company.id;
			const entidad = company['codigo_entidad']

			// const client = await this.api.findClientById(Number(company.id));
			// console.log("🚀 ~ file: account.component.ts:349 ~ AccountComponent ~ this.api.company ~ client:", client.moneda)
			// company.moneda = client.moneda ?? company.moneda[0]['tipo'];


			const url = this.getUrl('sapApiServerV3') + `/collection/account-statement/export-pdf?societyCode=${entidad}&clientCode=${this.companyCode ?? this.authService.user.code}&dateEnd=${currentDate.toISOString()}&currencyIsoCode=BOTH&diffTimeUtc=${diffTimeUtc}`;
			const name = "Estado de cuenta";

			this.downloadService.download(url, name);

		}).catch(reason => {

			this.snackbar.show("Error en la descarga");
		});
	}



	downloadxlsx() {

		this.api.company(this.authService.user.code).then(async company => {
			const currentDate = new Date();
			const diffTimeUtc = currentDate.getTimezoneOffset();

			const code = company.id;
			const entidad = company['codigo_entidad']

			// const client = await this.api.findClientById(Number(company.id));
			// console.log("🚀 ~ file: account.component.ts:349 ~ AccountComponent ~ this.api.company ~ client:", client.moneda)
			// company.moneda = client.moneda ?? company.moneda[0]['tipo'];

			const url = this.getUrl('sapApiServerV3') + `/collection/account-statement/export-excel?societyCode=${entidad}&clientCode=${this.companyCode ?? this.authService.user.code}&dateEnd=${currentDate.toISOString()}&currencyIsoCode=BOTH&diffTimeUtc=${diffTimeUtc}`;

			const name = "Estado de cuenta";

			this.downloadService.download(url, name);

		}).catch(reason => {

			this.snackbar.show("Error en la descarga");
		});
	}




}
