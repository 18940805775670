import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class TokenGLService {

	constructor(private http: HttpClient) {}

	getUrl(server: string): any {
		console.log('server server', server)
		const url = `${environment[server].protocol}://${environment[server].ip}/${environment[server].version}`;
		return url;
	}


	generate(token: string): Promise<string> {

		var promise = new Promise<string>((resolve, reject) => {
			var url = this.getUrl('apiAuth') + '/token-client/'
			var data = {idTokenFirebase: token}
			this.http.post<any>(url, data).subscribe((json: any) => {

				if (json.error) {
					reject();
				}
				else {
					resolve(json);
				}
			},
				(error: any) => {

					reject(error);
				});
		});

		return promise;
	}
}