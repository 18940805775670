import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';


export interface SnackbarMessages {
	success?: string;
	error?: string;
}


@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

	constructor(private snackbar: MatSnackBar) {

	}

	show(message: string) {

		this.snackbar.open(message, null, {
			duration: 3000,
			panelClass: ['app-snackbar']
		});
	}
}
