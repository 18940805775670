import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import {saveAs} from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

	constructor(private http: HttpClient) {

	}


	download(url: string, name?: string, token?:string, setLoading?) {

		this.http.get(url, {responseType: "blob", headers: {'Accept': 'application/pdf', 'authorization': 'Bearer '+token }})
		.subscribe(blob => {
			//saveAs(blob, name);
			var _url = window.URL.createObjectURL(blob);
			window.open(_url, "_blank").focus();
			setLoading();
		});

	}

	
}
