import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-combo-box',
  templateUrl: './combo-box.component.html',
  styleUrls: ['./combo-box.component.scss']
})
export class ComboBoxComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
