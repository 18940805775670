<mat-card class="custum-card">


	<!-- <app-navbar class="full-width" bgcolor="primary"></app-navbar> -->

	<h1>ESTADO DE CUENTA</h1>

	<div class="actions-bar" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="1em" fxLayout.xs="column">


		<h1>{{ this.dataAccount }} </h1>
		<mat-form-field>
			<mat-label>Sociedad</mat-label>
			<mat-select [(ngModel)]="societySelected" name="society">
				<mat-option (click)="onSelectSociety(society)" *ngFor="let society of companies" [value]="society.id">{{society.nombre}}</mat-option>
			</mat-select>
		</mat-form-field>

		<div fxFlex fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="1em"
			fxLayoutAlign.xs="space-evenly center">
		</div>

		<div>
			<mat-spinner *ngIf="loadingDowload == 1" diameter="40"></mat-spinner>
		</div>

		<button fxFlexAlign="center" mat-flat-button [color]="(xs ? 'default' : 'primary')"
			(click)="downloadxlsx()"><img class="multi_arrow" [src]="'assets/icons/icon_excel.png'">DESCARGAR</button>
		<button fxFlexAlign="center" mat-flat-button [color]="(xs ? 'default' : 'primary')" (click)="download()"><img
				class="multi_arrow" [src]="'assets/icons/pdf-icon.png'">DESCARGAR</button>

	</div>
	<br>


	<div class="purchase-table">

		<div class="custum-table" fxLayout="column">
			<br *ngIf="loading" />

			<mat-spinner *ngIf="loading" fxFlexAlign="center" color="primary" diameter="40"></mat-spinner>


			<pdf-viewer *ngIf="!loading" [src]="pdfSrc" [render-text]="true" [original-size]="false"
				style="width: 100%; height: 500px"></pdf-viewer>


			<mat-paginator fxFlexAlign="end" [ngClass]="{'hidden': loading || dataSource.data?.length < 1}"
				[pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="pageSize" [pageIndex]="page"></mat-paginator>

		</div>


	</div>

</mat-card>