import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { DialogService } from '../services/dialog.service';
import { SnackbarService } from '../services/snackbar.service';
//import { StorageService } from '../services/storage.service';
import { UsersService } from '../services/users.service';
import { MediaObserver } from '@angular/flex-layout';
import { Page } from '../adapters/page';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

	year : number = (new Date()).getFullYear();

	@ViewChild('imageInput', {static: true}) imageInput: ElementRef;

	photoUrl: string = "";

	defaultPhoto: string = "assets/images/profile.jpg";

	uploading : boolean = false;

	get user() {return this.authService.user;}

	constructor(public authService: AuthService,
				public dialogService: DialogService,
				//public storageService: StorageService,
				public usersService: UsersService,
				private changeDetectorRef: ChangeDetectorRef,
				private snackbar: SnackbarService,
				private media: MediaObserver,
				private page: Page) {

		this.page.actionBarHidden = true;
		
	}

	get xs() : boolean {
		return this.media.isActive('xs');
	}

	ngOnInit() {

		this.getImageUrl();
	}

	getImageUrl() {

		this.uploading = true;

		if (this.authService.user.profileImagePath)
		{
			this.uploading = false;
			/*this.storageService.getUrl(this.authService.user.profileImagePath).then(url => {

				if (url)
				{
					this.uploading = false;
					this.photoUrl = url;
				}
				else
				{
					this.uploading = false;
					this.photoUrl = this.defaultPhoto;
				}
			}).catch(reason => {
				this.uploading = false;
				this.photoUrl = this.defaultPhoto;
			});*/
		}
		else
		{
			this.authService.profileImageUrl().then(url => {

				if (url)
				{
					this.uploading = false;
					this.photoUrl = url;
				}
				else
				{
					this.uploading = false;
					this.photoUrl = this.defaultPhoto;
				}
			}).catch(reason => {
				this.uploading = false;
				this.photoUrl = this.defaultPhoto;
			});
		}
	}


	imageInputClick() {
		this.imageInput.nativeElement.click();
	}


	uploadImage(event) {

		this.uploading = true;

		if (event.target.files.length > 0)
		{
			var file: File = event.target.files[0];

			var ext = file.name.substr(file.name.lastIndexOf('.') + 1);

			const path = 'Profiles/'+this.authService.user.id+'.'+ext;

			this.uploading = false;

			/*this.storageService.uploadImage(path, file, 1200).then(uploadTask => {

				uploadTask.task.then(result => {

					const user = this.authService.user;

					user.profileImagePath = path;

					this.usersService.set(user).then(result => {

						this.authService.userBS.next(user);

						this.getImageUrl();
						this.snackbar.show("Foto cambiada");

						this.storageService.setUploadingBS(false, user.id);
						this.uploading = false;
					}).catch(reason => {

						this.snackbar.show("Error al cambiar foto");
						this.uploading = false;
					});

				}).catch(reason => {

					this.snackbar.show("Error al cambiar foto");
					this.uploading = false;
				});

			}).catch(reason => {

				this.snackbar.show("Error al cambiar foto");
				this.uploading = false;
			});*/
		}
	}



	editProfileDialog() {

		this.dialogService.openDialog({
			title: 'Editar perfil',
			type: 'editProfile',
			confirm: this.editProfile.bind(this)
		});
	}

	editProfile() {

		this.dialogService.closeDialog();

		this.changeDetectorRef.detectChanges();
	}


	resetPasswordDialog() {

		this.dialogService.openDialog({
			title: 'Cambiar contraseña',
			type: 'changePassword',
			confirm: this.resetPassword.bind(this)
		});
	}

	resetPassword() {

		this.dialogService.closeDialog();

		this.changeDetectorRef.detectChanges();
	}

}
