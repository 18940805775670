import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DatesService {


	days : string[] = [
		'Domingo',
		'Lunes',
		'Martes',
		'Miércoles',
		'Jueves',
		'Viernes',
		'Sábado'
	];

	months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
		"Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
	];


	hours : number[] = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23];



	intervals : string[] = ["Día", "Semana", "Mes"];

	get interval() : string {
		return localStorage.getItem('interval') || this.intervals[0];
	}

	set interval(value: string) {

		if (this.intervals.indexOf(value) >= 0)
			localStorage.setItem('interval', value);
	}




	constructor() { }



	formattedDate(_date: any, showDay?: boolean) : string {

		if (!_date)
			return "";

		var date : Date = _date;

		if (_date.getTime == null)
			date = (_date as firebase.firestore.Timestamp).toDate();

		var dd : number = date.getDate();
		var mm : number = date.getMonth() + 1; //January is 0!
		var yy : string = date.getFullYear().toString().substr(-2);

		var _dd : string = dd.toString();
		var _mm : string = mm.toString();

		if (dd < 10)
			_dd = '0' + dd;

		if (mm < 10)
			_mm = '0' + mm;


		var formattedDate : string = _dd + '/' + _mm + '/' + yy;

		if (showDay)
			formattedDate = this.days[date.getDay()] + ' ' + formattedDate;

		return formattedDate;
	}
	

	formattedTime(_date: any, showDay?: boolean) : string {

		if (!_date)
			return "";

		var date : Date = _date;

		if (_date.getTime == null)
			date = (_date as firebase.firestore.Timestamp).toDate();


		var hours : number = date.getHours();
		var minutes : number = date.getMinutes();

		var _hours = hours.toString();
		var _minutes = minutes.toString();

		if (_hours.length == 1)
			_hours = '0' + _hours ;

		if (_minutes.length == 1)
			_minutes = '0' + _minutes;


		var formattedTime : string = this.formattedDate(date, showDay) + ' ' + _hours + ':' + _minutes;

		return formattedTime;
	}



	formattedHour(_date: any) : string {

		if (!_date)
			return "";

		var date : Date = _date;

		if (_date.getTime == null)
			date = (_date as firebase.firestore.Timestamp).toDate();

		var hours : number = date.getHours();
		var minutes : number = date.getMinutes();


		var _hours = hours.toString();

		if (_hours.length == 1)
			_hours = '0' + _hours;


		var _minutes = minutes.toString();

		if (_minutes.length == 1)
			_minutes = '0' + _minutes;

		var formattedHour : string = _hours + ':' + _minutes;

		return formattedHour;
	}


	formattedMinutes(minutes: number) : string {

		const hours = Math.floor(minutes / 60);
		const _minutes = minutes - (hours * 60);

		if (_minutes > 1)
		{
			if (hours > 1)
			{
				return hours + " horas " + _minutes + " minutos";
			}
			else if (hours > 0)
			{
				return hours + " hora " + _minutes + " minutos";
			}
			else
			{
				return _minutes + " minutos";
			}
		}
		else if (_minutes > 0)
		{
			if (hours > 1)
			{
				return hours + " horas " + _minutes + " minuto";
			}
			else if (hours > 0)
			{
				return hours + " hora " + _minutes + " minuto";
			}
			else
			{
				return _minutes + " minuto";
			}
		}
		else
		{
			if (hours > 1)
			{
				return hours + " horas";
			}
			else if (hours > 0)
			{
				return hours + " hora";
			}
		}

		return minutes + " minutos";
	}



	minutesBetween(startDate: Date, endDate: Date) : number {
		var millisecondsPerMinute = 60 * 1000;
		return Math.abs(this.treatAsUTC(endDate) - this.treatAsUTC(startDate)) / millisecondsPerMinute;
	}



	treatAsUTC(date: Date) : any {
		var result = new Date(date);
		result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
		return result;
	}

	daysBetween(startDate: Date, endDate: Date) : number {
		var millisecondsPerDay = 24 * 60 * 60 * 1000;
		return Math.abs(this.treatAsUTC(endDate) - this.treatAsUTC(startDate)) / millisecondsPerDay;
	}


	dayAbbreviation(day: Date) : string {

		return this.days[day.getDay()].substring(0, 3).toUpperCase();
	}

	monthName(date: Date) : string {
		return this.months[date.getMonth()];
	}

	monthAbbreviation(date: Date) : string {

		return this.months[date.getMonth()].substring(0, 3).toLowerCase();
	}


	formatAMPM(hour: number) : string {

		var ampm = hour >= 12 ? 'pm' : 'am';
		
		var _hour = hour % 12;

		_hour = _hour ? _hour : 12; // the hour '0' should be '12'

		var strTime = _hour + ' ' + ampm;

		return strTime;
	}



	getMonday(date: Date) : Date {

		var _date = new Date(date);

		var day = _date.getDay(),
		diff = _date.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday

		return new Date(_date.setDate(diff));
	}


	getSunday(date: Date) : Date {

		var _date = new Date(date);

		var day = _date.getDay(),
		diff = _date.getDate() - day + (day == 0 ? 0 : 7);

		return new Date(_date.setDate(diff));
	}


	getFirstMonthDay(date: Date) : Date {

		var _date = new Date(date), y = date.getFullYear(), m = date.getMonth();

		return new Date(y, m, 1);
	}


	getLastMonthDay(date: Date) : Date {

		var _date = new Date(date), y = date.getFullYear(), m = date.getMonth();

		return new Date(y, m + 1, 0);
	}
}
