import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ViewChild } from '@angular/core';
import { DatesService } from '../../services/dates.service';

@Component({
  selector: 'app-select-date',
  templateUrl: './select-date.component.html',
  styleUrls: ['./select-date.component.scss']
})
export class SelectDateComponent implements OnInit {

	@Input('date') date : Date = new Date();

	@Input('confirm') confirm : Function;
	@Input('close') close : Function;
	@Input('errorMessage') errorMessage : string;

	@Input() loading : boolean;
	@Output() loadingChange = new EventEmitter<boolean>();

	setLoading(value: boolean) {
		this.loading = value;
		this.loadingChange.emit(this.loading);
	}


	pickerDate : Date;


	constructor(private datesService: DatesService) {

	}

	get year() {return this.date.getFullYear().toString();}
	get month() {return (this.date.getMonth() + 1).toString();}
	get day() {return (this.date.getDate() + 1).toString();}

	ngOnInit() {


	}


	onDateChanged(event) {

		this.pickerDate = event.value;
	}


	save() {

		this.confirm(this.pickerDate);
	}

}
