<mat-card class="custum-card">
  <h1>Contactos</h1>

  <br *ngIf="loading" />

  <p class="empty-text" *ngIf="!loading && data?.length < 1">
    No hay contactos
  </p>

  <mat-spinner *ngIf="loading" fxFlexAlign="center" color="primary" diameter="40"></mat-spinner>


  <div class="sales-team-wrap" [ngClass]="{'hidden': loading || data?.length < 1}">

    <div *ngFor="let f of vendedores" class="sales-team-box">
      <div class="img-sales-content">
        <img class="sales-img" src="{{f.photo}}" />
      </div>
      <div class="sales-team-info">
        <p class="sales-name">
          {{f.nombre}}
        </p>
        <p class="sales-info">Puesto</p>
        <ul>
          <li>
            <span>
              <img class="image-arrow" src="/assets/images/multi_arrow.png" />
            </span>
            <span class="sales-contact">{{f.position}}</span>
          </li>
        </ul>
        <p class="sales-info">Correo Electronico</p>
        <ul>
          <li>
            <span>
              <img class="image-arrow" src="/assets/images/multi_arrow.png" />
            </span>
            <a href="mailto:{{f.email}}"><span class="sales-contact">{{f.email}}</span></a>
          </li>
        </ul>
        <p class="sales-info" *ngIf="f.telefono_movil">Telefono</p>
        <ul *ngIf="f.telefono_movil">
          <li>
            <span>
              <img class="image-arrow" src="/assets/images/multi_arrow.png" />
            </span>
            <a target="_blank" href="https://api.whatsapp.com/send?phone={{phone}}{{f.telefono_movil}}"><span
                class="sales-contact">+ ({{phone}}) {{f.telefono_movil}}</span></a>
          </li>
        </ul>
      </div>
    </div>

  </div>
</mat-card>