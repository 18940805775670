import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { StateSharedService } from 'src/app/services/state-shared.service';
import { EventEmitter } from 'stream';


@Component({
    selector: 'app-element-skeleton',
    templateUrl: './element-skeleton.component.html',
    styleUrls: ['./element-skeleton.component.scss']
})
export class ElementSkeletonComponent implements OnInit {
    // @Input('diameter') diameter: number = 40;
    @Input('width') width: number = 40; // px
    @Input('height') height: number = 40; // px
    @Input('radius') radius: string = '0'; // px
    @Input('padding') padding: string = '0'; // px
    @Input('margin') margin: string = '0'; // px
    @Input('background-color') backgroundColor: string = '#707070';
    @Input('tag') tag: string;

    loading$ = new BehaviorSubject(false);
    constructor(
        private stateSharedService: StateSharedService<any>,
        private doms: DomSanitizer
    ) { }

    // async onClick() {
    //     this.loading$.next(true);
    //     console.log("🚀 ~ file: element-loading.component.ts:30 ~ ElementLoading ~ onClick ~ this.fetch:", this.fetch)
    //     // await this.fetch();
    //     await new Promise((resolve, reject) => {
    //         setTimeout(() => {
    //             resolve(true);
    //         }, 3000);
    //     });
    //     this.loading$.next(false);
    //     console.log("🚀 ~ file: element-loading.component.ts:32 ~ ElementLoading ~ onClick ~ this.loading:", this.loading$.getValue())
    //     console.log("🚀 ~ file: element-loading.component.ts:34 ~ ElementLoading ~ onClick ~ this.loading:", this.loading$.getValue())
    // }

    ngOnInit() { 
        this.stateSharedService.get(this.tag).subscribe((isLoading)=>{
            this.loading$.next(isLoading);
        });
    }

    generateSkeleton() {
        let radius: any = this.radius.split(',');
        if (Array.isArray(radius) == false) radius = radius + 'px';
        else if (radius.length == 1) radius = radius + 'px';
        else radius = radius.join('px ');

        let padding: any = this.padding.split(',');
        if (Array.isArray(padding) == false) padding = padding + 'px';
        else if (padding.length == 1) padding = padding + 'px';
        else padding = padding.join('px ');

        let margin: any = this.margin.split(',');
        if (Array.isArray(margin) == false) margin = margin + 'px';
        else if (margin.length == 1) margin = margin + 'px';
        else margin = margin.join('px ');

        const style = `
        background-color: ${this.backgroundColor};
        background-size: ${this.width}px ${this.height}px;
        width: ${this.width}px; 
        height: ${this.height}px;
        background-position: 0 0;
        border-radius: ${this.radius.length == 0 ? '0px' : radius};
        padding: ${this.padding.length == 0 ? '0px' : padding};
        margin: ${this.margin.length == 0 ? '0px' : margin};
        animation-name: gradient;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        `;
        return this.doms.bypassSecurityTrustStyle(style);
    }


}
