import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { DialogService } from '../../services/dialog.service';
import { DatesService } from '../../services/dates.service';
import { AuthService } from '../../services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MediaObserver } from '@angular/flex-layout';
import { Shopping, ShoppingPerson } from '../../classes/shopping';
import { Subscription, BehaviorSubject } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Page } from '../../adapters/page';


@Component({
  selector: 'app-shopping',
  templateUrl: './shopping.component.html',
  styleUrls: ['./shopping.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('expand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ShoppingComponent implements OnInit {

	year : number = (new Date()).getFullYear();

	loading : boolean = true;

	displayedColumns: string[];

	vendedores: MatTableDataSource<ShoppingPerson> = new MatTableDataSource([]);
	facturadores: MatTableDataSource<ShoppingPerson> = new MatTableDataSource([]);

	mediaSubscription : Subscription;


	expandedElement : ShoppingPerson;


	constructor(public api: ApiService,
				private dialogService: DialogService,
				public authService: AuthService,
				public datesService: DatesService,
				private changeDetectorRef: ChangeDetectorRef,
				public media: MediaObserver,
				private _page: Page) {

		this._page.actionBarHidden = true;

		this.loading = true;

		this.updateColumns();

		this.mediaSubscription = this.media.asObservable().subscribe(change => {
			this.updateColumns();
		});

		this.changeDetectorRef.markForCheck();
	}

	get xs() : boolean {
		return this.media.isActive('xs');
	}


	updateColumns() {

		if (this.media.isActive('gt-xs'))
		{
			this.displayedColumns = ['nombre', 'email', 'telefono_fijo', 'extension', 'telefono_movil'];
		}
		else
		{
			this.displayedColumns = ['nombre'];
		}
	}


	loadData() {

		this.updateColumns();

		this.api.shopping().then(result => {

			if (result)
			{
				this.vendedores.data = result.vendedores;
				this.facturadores.data = result.facturadores;

				this.loading = false;

				this.changeDetectorRef.markForCheck();
				this.changeDetectorRef.detectChanges();
			}
		});
	}



	ngOnInit() {


		this.loadData();
	}


	ngOnDestroy() {

		if (this.mediaSubscription) this.mediaSubscription.unsubscribe();
	}


	trackByFn(index, item) {
		return item.id;
	}

}
