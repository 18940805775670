import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface DialogData {
	type: string;
	title?: string;
	text?: string;
	confirm?: Function;
	close?: Function;
	object?: any;
}


@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})

export class DialogComponent implements OnInit {

	loading : boolean;

	errorMessage : string;

	file : File;

	constructor(private formBuilder: FormBuilder,
				public dialogRef: MatDialogRef<DialogComponent>,
				@Inject(MAT_DIALOG_DATA) public data: DialogData) {


		var afterClosedSubscription = dialogRef.afterClosed().subscribe(observer => {

			if (data.close && this.confirmCalled != true)
			{
				data.close(data.object ? data.object : null);
				this.confirmCalled = false;
			}

			afterClosedSubscription.unsubscribe();
		});
	}

	ngOnInit() {
		this.confirmFunction = this.confirm.bind(this);
		this.closeFunction = this.closeClick.bind(this);

		this.confirmCalled = false;
	}


	confirmFunction : Function;
	closeFunction : Function;


	confirmCalled : boolean = false;


	confirm(param?: any, loading?: boolean, form?: FormGroup) {

		if (form && !form.valid)
			return;

		if (loading)
			this.loading = true;

		if (this.data.confirm)
		{
			this.confirmCalled = true;
			this.data.confirm(param);
		}
	}


	closeClick() {

		this.dialogRef.close();
	}

}
