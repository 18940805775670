<div class="home finances" ngClass.xs="xs">


	<!-- <app-navbar class="full-width" bgcolor="primary"></app-navbar> -->

	<div class="row">
		<div class="col-lg-7 ">
			<div class="row">
				<div class="col-lg-4 borderRight margintopbottom">
					<div class="section" [ngClass]="{'hidden': section(0)?.hidden, 'disabled': section(0)?.disabled}"
						fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.75em" (click)="openSection(0)">
						<div class="icon-border" fxLayout="column" fxLayoutAlign="center center">
							<div class="icon-container" fxLayout="column" fxLayoutAlign="center center">
								<app-icon [icon]="section(0)?.icon" [size]="xs ? 2.5 : 4.5" sizeUnit="em"></app-icon>
							</div>
						</div>
						<span class="title">{{section(0)?.text}}</span>
					</div>
				</div>
				<div class="col-lg-4 borderRight margintopbottom">
					<div class="section" [ngClass]="{'hidden': section(2)?.hidden, 'disabled': section(2)?.disabled}"
						fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.75em" (click)="openSection(2)">
						<div class="icon-border" fxLayout="column" fxLayoutAlign="center center">
							<div class="icon-container" fxLayout="column" fxLayoutAlign="center center">
								<app-icon [icon]="section(2)?.icon" [size]="xs ? 2.5 : 4.5" sizeUnit="em"></app-icon>
							</div>
						</div>
						<span class="title">{{section(2)?.text}}</span>
					</div>

				</div>
				<div class="col-lg-4 borderRight margintopbottom">
					<div class="section"
						[ngClass]="{'hidden': section(4)?.hidden, 'disabled': section(4)?.disabled, 'big': sections?.length < 6}"
						fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.75em" (click)="openSection(4)">
						<div class="icon-border" fxLayout="column" fxLayoutAlign="center center">
							<div class="icon-container" fxLayout="column" fxLayoutAlign="center center">
								<app-icon [icon]="section(4)?.icon"
									[size]="xs ? (sections?.length < 6 ? 3.5 : 2.5) : 4.5" sizeUnit="em"></app-icon>
							</div>
						</div>
						<span class="title">{{section(4)?.text}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-5 ">
			<div class="row">
				<div class="col-lg-6 borderRight margintopbottom">
					<div class="section" [ngClass]="{'hidden': section(1)?.hidden, 'disabled': section(1)?.disabled}"
						fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.75em" (click)="openSection(1)">
						<div class="icon-border" fxLayout="column" fxLayoutAlign="center center">
							<div class="icon-container" fxLayout="column" fxLayoutAlign="center center">
								<app-icon [icon]="section(1)?.icon" [size]="xs ? 2.5 : 4.5" sizeUnit="em"></app-icon>
							</div>
						</div>
						<span class="title">{{section(1)?.text}}</span>
					</div>
				</div>
				<div class="col-lg-6 margintopbottom">
					<div class="section" [ngClass]="{'hidden': section(3)?.hidden, 'disabled': section(3)?.disabled}"
						fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.75em" (click)="openSection(3)">
						<div class="icon-border" fxLayout="column" fxLayoutAlign="center center">
							<div class="icon-container" fxLayout="column" fxLayoutAlign="center center">
								<app-icon [icon]="section(3)?.icon" [size]="xs ? 2.5 : 4.5" sizeUnit="em"></app-icon>
							</div>
						</div>
						<span class="title">{{section(3)?.text}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- <div fxFlex class="grid" ngClass.gt-xs="mat-elevation-z6" fxLayout="row" fxLayoutAlign="space-evenly stretch" fxLayout.xs="column">

		<div fxLayout="column" fxLayoutAlign="space-around center" fxLayout.xs="row">

			


		</div>

		<div *ngIf="section(4)?.hidden != true" fxLayout="column" [fxLayoutAlign]="sections?.length > 5 ? 'space-around center' : 'center center'" fxLayout.xs="row">

			

			 <div *ngIf="sections?.length > 5" class="section" [ngClass]="{'hidden': section(5)?.hidden, 'disabled': section(5)?.disabled}" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.75em" (click)="openSection(5)">
				<div class="icon-border" fxLayout="column" fxLayoutAlign="center center">
					<div class="icon-container" fxLayout="column" fxLayoutAlign="center center">
						<app-icon [icon]="section(5)?.icon" [size]="xs ? 2.5 : 4.5" sizeUnit="em"></app-icon>
					</div>
				</div>
				<span class="title">{{section(5)?.text}}</span>
			</div> 

		</div>

		<div fxLayout="column" fxLayoutAlign="space-around center" fxLayout.xs="row">



		</div>

	</div>  -->

</div>