import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivationEnd } from '@angular/router';
import { RouterService } from '../../services/router.service';
import { Claim } from '../../classes/claim';
import { ClaimsService } from '../../services/claims.service';
import { StorageService } from '../../services/storage.service';
import { DatabaseService } from '../../services/database.service';
import { SnackbarService } from '../../services/snackbar.service';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';
import { FileInput } from 'ngx-material-file-input';
import { MediaObserver } from '@angular/flex-layout';
import { Page } from '../../adapters/page';


@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.scss']
})
export class ClaimComponent implements OnInit {

	year : number = (new Date()).getFullYear();

	order: string;

	claim : Claim;

	loading : boolean;

	form : FormGroup;

	types : string[] = ['Devolución', 'Mal estado', 'Incumplimiento de normas', 'Entrega fuera de tiempo', 'No es lo que solicité', 'Otras causas'];

	constructor(private routerService: RouterService,
				private api: ApiService,
				private formBuilder: FormBuilder,
				private storageService: StorageService,
				private database: DatabaseService,
				private snackbar: SnackbarService,
				private authService: AuthService,
				public claimsService: ClaimsService,
				private media: MediaObserver,
				private _page: Page) {

		this._page.actionBarHidden = true;

		this.loading = true;

		this.router.events.subscribe(event => {

			if (event instanceof ActivationEnd)
			{
				this.order = event.snapshot.url[event.snapshot.url.length - 1].path;

				this.claimsService.get(this.order).then(claim => {

					if (claim)
					{
						this.claim = claim;
					}

				}).finally(() => {

					this.createForm();

					this.loading = false;
				});
			}
		});
	}


	get router() {return this.routerService.router;}


	get xs() : boolean {
		return this.media.isActive('xs');
	}


	createForm() {

		if (this.claim)
		{
			this.form = this.formBuilder.group({
				type: [this.claim.type, [Validators.required]],
				comment: [this.claim.comment, [Validators.required]],
				file: [null, []]
			});
		}
		else
		{
			this.form = this.formBuilder.group({
				type: [null, [Validators.required]],
				comment: [null, [Validators.required]],
				file: [null, []]
			});
		}
	}


	download() {

		this.storageService.downloadFile(this.claim.file, this.order);
	}



	save() {

		if (this.form.invalid)
		{
			return;
		}

		this.loading = true;

		this.api.company(this.authService.user.code).then(company => {

			const fileInput : FileInput = this.form.get('file').value;

			const claim : Claim = {
				id: this.order,
				user: this.authService.user.id,
				company: company.nombre,
				type: this.form.get('type').value,
				comment: this.form.get('comment').value
			};


			if (fileInput && fileInput.files && fileInput.files.length > 0)
			{
				const file = fileInput.files[0];
				const extension = file.name.substring(file.name.lastIndexOf('.'));

				const path : string = 'Claims/'+this.order+extension;

				this.storageService.uploadFile(path, file).then(result => {

					claim.file = path;

					this.claimsService.set(claim).then(result => {
						this.claim = claim;
					}).finally(() => {
						this.loading = false;
					});

				}).catch(reason => {

					this.snackbar.show('Error al subir archivo');
					this.loading = false;
				});
			}
			else
			{
				this.claimsService.set(claim).then(result => {
					this.claim = claim;
				}).finally(() => {
					this.loading = false;
				});
			}

		}).catch(reason => {

			this.snackbar.show('Error al enviar reclamo');
			this.loading = false;
		});
	}


	typeChanded(event) {

		this.form.get('type').setValue(this.types[event]);
	}


	ngOnInit() {

	}

}
