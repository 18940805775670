import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Product, ProductData } from '../../classes/product';
import { DialogService } from '../../services/dialog.service';
//import { StorageService } from '../../services/storage.service';
import { SnackbarService } from '../../services/snackbar.service';
import { FileInput } from 'ngx-material-file-input';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.component.html',
  styleUrls: ['./new-product.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewProductComponent implements OnInit {

	@Input('product') product : Product;

	@Input('confirm') confirm : Function;
	@Input('close') close : Function;
	@Input('errorMessage') errorMessage : string;

	@Input() loading : boolean;
	@Output() loadingChange = new EventEmitter<boolean>();

	setLoading(value: boolean) {
		this.loading = value;
		this.loadingChange.emit(this.loading);
	}


	saving : boolean = false;


	form : FormGroup;

	constructor(private formBuilder: FormBuilder,
				private dialogService: DialogService,
				private apiService: ApiService,
				//private storageService: StorageService,
				private snackbar: SnackbarService) {

	}

	ngOnInit() {

		if (this.product)
		{
			this.form = this.formBuilder.group({
				title: [this.product.title, [Validators.required]],
				description: [this.product.description, [Validators.required]],
				file: [this.product.file, [Validators.required]],
				image: [this.product.image, [Validators.required]]
			});
		}
		else
		{
			this.form = this.formBuilder.group({
				title: [null, [Validators.required]],
				description: [null, [Validators.required]],
				file: [null, [Validators.required]],
				image: [null, [Validators.required]],
			});
		}
	}


	save() {
		console.log('this.form.invalid', this.form)
		if (this.form.invalid)
		{
			Object.keys(this.form.controls).forEach(key => {
				this.form.controls[key].markAsTouched();
			});
			
			return;
		}

		this.saving = true;

		const productID : string = this.product ? this.product.id : null;

		const product : ProductData = {
			title: this.form.get('title').value,
			description: this.form.get('description').value,
			file: this.form.get('file').value,
			image: this.form.get('image').value,
		};

		if (productID){
			this.apiService.setProduct(productID,product).then(result => {
				this.dialogService.closeDialog();
			}).catch(() => {
				this.saving = false;
			});
		}else{
			this.apiService.addProduct(product).then(result => {
				this.dialogService.closeDialog();
			}).catch(() => {
				this.saving = false;
			});
		}
		
	}

}
