import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { DatesService } from '../../services/dates.service';
import { SnackbarService } from '../../services/snackbar.service';
import { AuthService } from '../../services/auth.service';
import { NumbersService } from '../../services/numbers.service';
import { SessionService } from '../../services/session.service';
import { RouterService } from '../../services/router.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatInput } from '@angular/material/input';
import { MediaObserver } from '@angular/flex-layout';
import { Purchase } from '../../classes/purchase';
import { Subscription } from 'rxjs';
import { MatDatepicker } from '@angular/material/datepicker';
import { SelectionModel } from '@angular/cdk/collections';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Page } from '../../adapters/page';
import { environment } from 'src/environments/environment';
import { StateSharedService } from 'src/app/services/state-shared.service';
import { MatDialog } from '@angular/material/dialog';
import { QuestionSupportInvoicesDialog } from './component/question-support-invoices';
import { Company } from 'src/app/classes/company';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'app-invoices',
	templateUrl: './invoices.component.html',
	styleUrls: ['./invoices.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	animations: [
		trigger('expand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	]
})
export class InvoicesComponent implements OnInit {

	societies: string
	societySelected: string
	year: number = (new Date()).getFullYear();

	loading: boolean = true;


	loadingDowload: boolean = false;

	checkSelected: boolean = true;

	maxDate: Date = new Date();

	minDate: Date = new Date(((new Date()).getFullYear() - 5), 0, 1)

	moneda: string = ""

	displayedColumns: any[];

	subtotalData = [];
	subtotal: number = 0

	dataSource: MatTableDataSource<Purchase> = new MatTableDataSource([]);

	selection = new SelectionModel<Purchase>(true, []);

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	mediaSubscription: Subscription;

	expandedElement: Purchase;


	@ViewChild('dp', { static: true }) datepicker: MatDatepicker<Date>;
	@ViewChild('datepickerInput', { static: true }) datepickerInput: ElementRef;


	page: number = 0;
	pageSize: number = 10;

	monthDate: Date;

	companies: Company[] = [];
	companyCode: string;


	filter: 'month' | '8days' = 'month';

	form: FormGroup
	constructor(public api: ApiService,
		private routerService: RouterService,
		private formBuilder: FormBuilder,
		public authService: AuthService,
		public numbersService: NumbersService,
		public datesService: DatesService,
		private snackbar: SnackbarService,
		private changeDetectorRef: ChangeDetectorRef,
		public media: MediaObserver,
		private sessionService: SessionService,
		private _page: Page,
		private stateSharedService: StateSharedService<any>,
		public dialog: MatDialog,
		private http: HttpClient) {

		this._page.actionBarHidden = true;

		this.updateColumns();

		this.form = this.formBuilder.group({ search: ['', []] });

		this.mediaSubscription = this.media.asObservable().subscribe(change => {
			this.updateColumns();
		});

		if (!this.changeDetectorRef['destroyed'])
			this.changeDetectorRef.markForCheck();
	}


	get router() { return this.routerService.router; }


	get xs(): boolean {
		return this.media.isActive('xs');
	}


	updateColumns() {

		if (this.media.isActive('gt-xs')) {
			this.displayedColumns = ['select', 'factura_numero', 'oc', 'factura_fecha', 'valor', 'saldo_pendiente', 'actions'];
		}
		else {
			this.displayedColumns = ['select', 'factura_numero', 'oc', 'actions'];
		}
	}

	// compare(a, b) {
	// 	if (a.dias_atraso > b.dias_atraso) {
	// 		return -1;
	// 	}
	// 	if (a.dias_atraso < b.dias_atraso) {
	// 		return 1;
	// 	}
	// 	return 0;
	// }

	stringToDate(string: string): Date {

		const doo = new Date(string);

		return new Date(doo.getTime() - doo.getTimezoneOffset() * -60000);
	}

	stringToNumber(string: string) {
		const parse: number = parseInt(string, 10)
		return parse
	}

	goToPage(event: MouseEvent, orderItem: Purchase) {
		const openNewTab = event.ctrlKey || event.metaKey;

		// Verificar si se hizo clic con el botón central (scroll) para abrir en nueva pestaña
		const openNewTabWithCenter = event.button === 1;

		// Si se cumple alguna de las condiciones anteriores, abrir en nueva pestaña
		if (openNewTab || openNewTabWithCenter) {
			const url = location.origin + '/invoices/' + orderItem.pedido + '/' + orderItem.factura_sap;
			window.open(url, '_blank');
		} else {
			this.stateSharedService.set('filter', {
				month: this.monthDate,
				page: this.paginator.pageIndex,
			});
			this.router.navigate(['/invoices/' + orderItem.pedido + '/' + orderItem.factura_sap])
		}

	}

	filterMontDate: any;

	ngOnInit() {
		this.loadCompanies();
		this.monthDate = new Date()
		if (this.paginator) {
			this.paginator._intl.itemsPerPageLabel = "Compras por página:";
			this.paginator._intl.firstPageLabel = "Primera página";
			this.paginator._intl.previousPageLabel = "Página anterior";
			this.paginator._intl.nextPageLabel = "Página siguiente";
			this.paginator._intl.lastPageLabel = "Última página";

			this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {

				if (length == 0 || pageSize == 0) {
					return `0 de ${length}`;
				}

				length = Math.max(length, 0);
				const startIndex = page * pageSize;
				const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
				return `${startIndex + 1}  ${endIndex} de ${length}`;
			};

			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string) => {
				switch (sortHeaderId) {
					case 'factura_fecha':
						return Number(data['dias_atraso']); // Utiliza la propiedad 'valor' para ordenar por edad
					default:
						return data[sortHeaderId];
				}
			};
		}

		this.filter = (this.sessionService.getItem('filter') as 'month' | '8days') || 'month';

		this.loading = true;

		this.api.company().then(result => {
			if (result.moneda && result.moneda[0]) {
				this.moneda = result.moneda[0]['tipo']
			}
		});
	}

	onLoadDocuments(clientCode?: string){
		this.api.purchasesEstadoCuenta(clientCode).then(result => {
			if (result) {
				this.dataSource.data = result['pedidos'];

				const today = new Date();
				const ordersDateStringToMs = this.dataSource.data.map((order) => {
					const date = new Date(order['factura_vencimiento']).getTime();
					return date;
				});
				const dateMsStart = new Date(Math.min(...ordersDateStringToMs));
				const dateMsEnd = new Date(Math.max(...ordersDateStringToMs));
				// this.stateSharedService.set('data', this.dataSource.data);

				if (ordersDateStringToMs.length >= 1) this.api.purchasesEstadoCuentaOC(dateMsStart, dateMsEnd).then((resultOC) => {
					this.dataSource.data = this.dataSource.data.map((item) => {
						const oc = resultOC['oc'][item.pedido];
						return {
							...item,
							oc,
						}
					});
					this.selection.clear();
					// this.stateSharedService.set('data', this.dataSource.data);
				});
				else this.dataSource.data = [];

				this.loading = false;
			}
		});
	}

	ngAfterViewInit() {
		this.stateSharedService.get('filter').subscribe((result) => {
			if (result) {
				this.monthDate = result.month;
				setTimeout(() => {
					// Inicializar el paginador en la página deseada (por ejemplo, página 2)
					this.paginator.pageIndex = result.page;
				});

				if (this.monthDate) this.filterMonth(this.monthDate);
				this.setInputValue();
			}
			this.stateSharedService.destroy('filter');
		});
	}


	ngOnDestroy() {
		this.monthDate = null
		if (this.mediaSubscription) this.mediaSubscription.unsubscribe();
	}


	trackByFn(index, item: Purchase) {
		return item.row_id;
	}


	chosenYearHandler(yearDate: Date) {
		if (this.monthDate == null) {
			var day = new Date();
			this.monthDate = new Date(yearDate.getFullYear(), day.getMonth(), day.getDate());
		} else {
			this.monthDate.setFullYear(yearDate.getFullYear());
		}
	}

	chosenMonthHandler(monthDate: Date, datepicker: MatDatepicker<Date>) {

		this.monthDate.setMonth(monthDate.getMonth());
		this.monthDate.setFullYear(monthDate.getFullYear());

		this.sessionService.setItem('monthDate', JSON.stringify({ date: this.monthDate.getTime() }));

		if (datepicker) {
			datepicker.close();
			datepicker.select(this.monthDate);
		}

		this.filterMonth(monthDate);
		this.setInputValue();

		if (!this.changeDetectorRef['destroyed'])
			this.changeDetectorRef.markForCheck();


		// this.loadData("");
	}


	setInputValue() {
		let newValue = "";

		if (this.monthDate != null) {
			newValue = this.datesService.monthName(this.monthDate) + ' ' + this.monthDate.getFullYear();
		}

		if (this.datepickerInput) {
			const input = (this.datepickerInput.nativeElement as MatInput);
			input.value = newValue;
		}
	}

	filterTodayString: string | undefined;
	async filterToday() {
		this.filterReset();
		this.filterTodayString = new Date().toISOString();
		this.onFilter();
	}
	filterExpiredtodayString: string | undefined;
	async filterExpiredtoday() {
		this.filterReset();
		this.filterExpiredtodayString = new Date().toDateString()
		this.onFilter();
	}

	filterMonthCurrentString: string | undefined;
	filterMonthCurrent() {
		this.filterReset();
		this.filterMonthCurrentString = new Date().toISOString();
		this.onFilter();
	}

	filterMonthLastString: string | undefined;
	filterMonthLast() {
		this.filterReset();
		this.filterMonthLastString = new Date().toISOString();
		this.onFilter();
	}

	filterMonthString: string | undefined;
	filterMonth(dateSelected: Date) {
		this.filterReset();
		this.filterMonthString = dateSelected.toISOString();
		this.onFilterDateFac();
	}

	showDaysPastDue(days: number) {
		if (days <= 0) return 0;
		else return days;
	}

	filterField: string | undefined;
	filterValue(value: string | null) {
		console.log('filterValue');
		if (value == null) {
			this.monthDate = null;
			if (this.datepickerInput) {
				const input = (this.datepickerInput.nativeElement as MatInput);
				input.value = '';
			}
			this.onResetAndFilter();
		};
		this.filterField = value;
		this.onFilter();
	}

	onResetAndFilter() {
		this.filterReset();
		this.onFilter();
	}

	selectFilter() {
		this.monthDate = null;

		if (this.datepicker) {
			this.datepicker.select(this.monthDate);
			this.setInputValue();
		}

		if (!this.changeDetectorRef['destroyed'])
			this.changeDetectorRef.markForCheck();
	}




	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	masterToggle() {
		this.isAllSelected() ?
			this.selection.clear() :
			this.dataSource.data.forEach(row => this.selection.select(row));

	}


	generateSubtotal() {
		this.selection.selected
		const plus = this.selection.selected.reduce((a, b) => { return a + b.saldo_pendiente }, 0);
		let parse: number = plus * -1
		this.subtotal = parseFloat(parse.toFixed(2))
	}


	clickCheck() {
		this.checkSelected = true

	}

	async dowload_docs() {
		if (this.selection.selected.length >= 1) {
			if(this.selection.selected.length >= 6)
				return this.snackbar.show("Solo se permite seleccionar 5 facturas");

			this.loadingDowload = true;
			this.loading = false;

			let purchases = ""
			let facturas = ""
			let facturasSap = [];
			this.selection.selected.forEach(p => {
				purchases += p.pedido + ","
				facturas += p.factura_numero + ","
				facturasSap.push(p.factura_sap);
			});

			const name = "adjuntos";
			const company = await this.api.company();
			const email = this.authService.user?.email;

			const entidad = company['codigo_entidad']
			const linkDownload = `${this.getUrl('gcServerV3PRD')}/invoice-support-files?number=${facturasSap.join(',')}&societyCode=${entidad}&clientCode=${this.companyCode}`;
			const linkEmail = `${this.getUrl('gcServerV3PRD')}/invoice-support-files/email?number=${facturasSap.join(',')}&societyCode=${entidad}&clientCode=${this.companyCode}&email=${email}`;
			this.openQuestionSupportInvoicesDialog({ linkDownload, linkEmail, name, email })


		} else {
			this.snackbar.show("Seleccione facturas");
		}
	}

	openQuestionSupportInvoicesDialog(data: { name: string, linkDownload: string, linkEmail: string, email: string }): void {
		const dialogRef = this.dialog.open(QuestionSupportInvoicesDialog, {
			data,
		});

		dialogRef.afterClosed().subscribe(result => {
			const token = localStorage.getItem('tokenGL');;

			if (result) {
				this.loadingDowload = false;
				const options = {
					method: 'POST',
				};
				fetch(data.linkEmail, options);
			}
			else this.downloadAuth(data.linkDownload, token);
			this.selection.clear();
		});
	}

	downloadAuth(url: string, token?:string) {
		this.http.get(url, {responseType: "blob", headers: {'Accept': 'application/pdf', 'authorization': 'Bearer '+token }})
		.subscribe(blob => {
			var _url = window.URL.createObjectURL(blob);
			window.open(_url, "_blank").focus();
			this.loadingDowload = false;
		});

	}

	getUrl(server: string): any {
		const url = `${environment[server].protocol}://${environment[server].ip}/${environment[server].version}`;
		return url;
	}




	
	loadCompanies() {

		this.api.company().then(company => {
			if (company.moneda && company.moneda[0]) {
				this.moneda = company.moneda[0]['tipo']
			}
			//this.companyCode = this.authService.user.code;
			if (company) {
				if (this.authService.user.role == 'admin'){
					this.companies.push(company);
					for (var subcompany of (company.subempresas || [])) {
						this.companies.push(subcompany);
					}

				}else{
					if (this.authService.user.companies.includes(company.id)){
						this.companies.push(company);
					}

					for (var subcompany of (company.subempresas || [])) {
						if (this.authService.user.companies.includes(subcompany.id))
							this.companies.push(subcompany);
					}
				}

				this.companyCode = this.companies[0].codigo;
				this.societySelected = this.companies[0].id;

				this.onLoadDocuments(this.companies[0].codigo);
			}
		})
	}

	async onSelectSociety(company: Company) {
		this.companyCode = company.codigo;
		this.onFilter();

		this.onLoadDocuments(company.codigo);

	}

	filterReset = () => {
		this.filterField = undefined;
		this.filterMonthString = undefined;
		this.filterMonthCurrentString = undefined;
		this.filterMonthLastString = undefined;
		this.filterTodayString = undefined;
		this.filterExpiredtodayString = undefined;
	}
	async onFilter() {
		const filterDateMonth = (dateField, dateString: string | undefined) => {
			if (!dateString) return true;
			const date = new Date(dateString);
			const dueDate = new Date(dateField);
			const condition = dueDate.getMonth() == date.getMonth() && dueDate.getFullYear() == date.getFullYear();
			return condition;
		}

		const filterDateMonthLast = (dataField, dateString: string | undefined) => {
			if (!dateString) return true;
			const date = new Date(dateString);
			const dueDate = new Date(dataField);
			const condition = dueDate <= date || (dueDate.getMonth() == date.getMonth() && dueDate.getFullYear() == date.getFullYear());
			return condition;
		}

		const filterDateToday = (dataField, dateString: string | undefined) => {
			if (!dateString) return true;
			const date = new Date(dateString);
			const dueDate = new Date(dataField);
			const condition = dueDate.getTime() <= date.getTime();
			return condition;
		}

		const filterExpiredtoday = (dataField, dateString: string | undefined) => {
			if (!dateString) return true;
			const date = new Date(dateString);
			const dueDate = new Date(dataField);
			const condition = date.getTime() >= dueDate.getTime()
			return condition
		}
		this.dataSource.filterPredicate = (data, filter) => {
			return true
				&& (this.companyCode ? data.codigo_cliente.indexOf(this.companyCode) != -1 : true)
				&& (!!this.filterField ? JSON.stringify(data).trim().toLowerCase().indexOf(this.filterField.trim().toLowerCase()) != -1 : true)
				&& filterDateMonth(data['factura_vencimiento'], this.filterMonthString)
				&& filterDateMonth(data['factura_vencimiento'], this.filterMonthCurrentString)
				// && filterDateMonthLast(data['factura_vencimiento'], this.filterMonthLastString)
				&& filterDateToday(data['factura_vencimiento'], this.filterTodayString)
				&& filterExpiredtoday(data['factura_vencimiento'], this.filterExpiredtodayString)
		}
		this.dataSource.filter = 'execute';
	}


	async onFilterDateFac() {
		const filterDateMonth = (dateField, dateString: string | undefined) => {
			if (!dateString) return true;
			const date = new Date(dateString);
			const dueDate = new Date(dateField);
			const condition = dueDate.getMonth() == date.getMonth() && dueDate.getFullYear() == date.getFullYear();
			return condition;
		}

		const filterDateToday = (dataField, dateString: string | undefined) => {
			if (!dateString) return true;
			const date = new Date(dateString);
			const dueDate = new Date(dataField);
			const condition = dueDate.getTime() <= date.getTime();
			return condition;
		}

		const filterExpiredtoday = (dataField, dateString: string | undefined) => {
			if (!dateString) return true;
			const date = new Date(dateString);
			const dueDate = new Date(dataField);
			const condition = date.getTime() >= dueDate.getTime()
			return condition
		}
		this.dataSource.filterPredicate = (data, filter) => {
			return true
				&& (this.companyCode ? data.codigo_cliente.indexOf(this.companyCode) != -1 : true)
				&& (!!this.filterField ? JSON.stringify(data).trim().toLowerCase().indexOf(this.filterField.trim().toLowerCase()) != -1 : true)
				&& filterDateMonth(data['factura_fecha'], this.filterMonthString)
				&& filterDateMonth(data['factura_fecha'], this.filterMonthCurrentString)
				// && filterDateMonthLast(data['factura_vencimiento'], this.filterMonthLastString)
				&& filterDateToday(data['factura_fecha'], this.filterTodayString)
				&& filterExpiredtoday(data['factura_fecha'], this.filterExpiredtodayString)
		}
		this.dataSource.filter = 'execute';
	}
}
