import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationExtras, NavigationEnd } from "@angular/router";
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestoreDocument, DocumentReference, DocumentSnapshot } from '@angular/fire/firestore';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DatabaseService } from './database.service';
import { UsersService } from './users.service';
import { User, UserData } from '../classes/user';
import { Location } from '@angular/common';

import * as firebase from 'firebase/app';
import 'firebase/auth';



export enum LoginStatus {
	ToDo = 'ToDo',
	Doing = 'Doing',
	Done = 'Done',
	Error = 'Error'
}


export interface AuthUserData {
	name: string;
	lastname: string;
	email: string;
	phone: string;
}



@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

	public loaded : BehaviorSubject<boolean> = new BehaviorSubject(false);

	private state : any = null;

	public userBS : BehaviorSubject<User> = new BehaviorSubject<User>(null);

	get user() : User {
		return this.userBS.value;
	}

	loginStatus : LoginStatus = LoginStatus.ToDo;
	errorMessage : string = '';
	loginMessage : string = '';


	routerSubscription : Subscription;
	userSubscription : Subscription;
	authStateSubscription : Subscription;
	userDataSubscription : Subscription;
	companySubscription : Subscription;


	loginNextRoute : string = null;


	constructor(private auth: AngularFireAuth,
				private database: DatabaseService,
				private usersService: UsersService,
				private router: Router,
				private location: Location) {

		this.routerSubscription = router.events.subscribe((val) => {

			if (val instanceof NavigationEnd)
			{
				this.loginStatus = LoginStatus.ToDo;
				this.errorMessage = '';
			}
		});

		this.userSubscription = this.userBS.subscribe(user => {

			this.loaded.next(true);
			if (this.userSubscription) this.userSubscription.unsubscribe();
		});

		this.signOutFunction = this.signOut.bind(this);
	}


	ngOnDestroy() {

		if (this.routerSubscription) this.routerSubscription.unsubscribe();
		if (this.userSubscription) this.userSubscription.unsubscribe();
		if (this.authStateSubscription) this.authStateSubscription.unsubscribe();
		if (this.userDataSubscription) this.userDataSubscription.unsubscribe();
		if (this.companySubscription) this.companySubscription.unsubscribe();
	}


	get authenticated(): boolean {
		return this.state != null;
	}


	getAuthState() : Promise<any> {

		var promise = new Promise<any>((resolve) => {
			this.authStateSubscription = this.auth.authState.subscribe((auth) => {
				this.state = auth;
				if (this.authStateSubscription) this.authStateSubscription.unsubscribe();
				resolve(auth);
			});
		});

		return promise;
	}


	getUser(authUser?: firebase.User) : Promise<User> {

		var promise = new Promise<User>((resolve) => {

			if (authUser == null)
			{
				resolve(null);
			}
			else if (this.user && authUser.uid == this.user.id)
			{
				resolve(this.user);
			}
			else
			{
				this.database.get<User>('users', authUser.uid).then(user => {

					resolve(user);

				}).catch(reason => {
					resolve(null);
				});
			}
		});

		return promise;
	}


	getCurrentUser() : Promise<User> {

		var promise : Promise<User>;

		if (this.state)
		{
			promise = new Promise<User>((resolve) => {

				var authUser = this.auth.auth.currentUser;

				if (authUser)
				{
					this.getUser(authUser).then(function(user) {

						this.userBS.next(user);
						resolve(user);	

					}.bind(this));
				}
				else
				{
					this.userBS.next(null);
					resolve(null);
				}
			});
		}
		else
		{
			promise = new Promise<User>((resolve) => {

				this.getAuthState().then(function(auth) {

					var authUser = this.auth.auth.currentUser;

					if (authUser)
					{
						this.getUser(authUser).then(function(user) {

							this.userBS.next(user);
							resolve(user);

						}.bind(this));
					}
					else
					{
						this.userBS.next(null);
						resolve(null);
					}

				}.bind(this));
			});
		}

		return promise;
	}



	profileImageUrl() : Promise<string> {

		var promise = new Promise<string>((resolve, reject) => {

			const authUser = this.auth.auth.currentUser;

			if (authUser)
			{
				resolve(authUser.photoURL);
			}
			else
			{
				reject();
			};
		});

		return promise;
	}




	emailLogin(email: string, pass: string, keepSession: boolean): Promise<any> {

		this.loginStatus = LoginStatus.Doing;

		var promise = new Promise<any>((resolve, reject) => {

			this.auth.auth.signInWithEmailAndPassword(email, pass).then(function(userCredential) {
				console.log("userCredential", userCredential)
  				this.getUser(userCredential.user).then(function(user) {

  					if (user)
  					{
						if (user.active){
							this.auth.auth.setPersistence(keepSession ? 'local' : 'none');
							this.loginStatus = LoginStatus.Done;

							resolve(user);
						}else{
							this.loginStatus = LoginStatus.Error;
							this.errorMessage = "Usuario no activo";
							this.signOut()

							reject(this.errorMessage);
						}
  					}
  					else
  					{
  						this.loginStatus = LoginStatus.Error;
						this.errorMessage = "Usuario no encontrado";

						reject(this.errorMessage);
  					}

  				}.bind(this));

			}.bind(this)).catch(function(error) {

				this.loginStatus = LoginStatus.Error;
				this.errorMessage = this.errorMessageWithCode(error.code);

				reject(this.errorMessage);

			}.bind(this))

		});

		return promise;
	}





	emailSignUp(email: string, pass: string, keepSession?: boolean): Promise<any> {

		this.loginStatus = LoginStatus.Doing;

		var promise = new Promise<any>((resolve, reject) => {

			this.auth.auth.createUserWithEmailAndPassword(email, pass).then(function(result) {

				this.getCurrentUser().then(function(user) {

  					resolve('success');

  				}.bind(this));

			}.bind(this)).catch(function(error) {

				this.loginStatus = LoginStatus.Error;
				this.errorMessage = this.errorMessageWithCode(error.code);
				reject(this.errorMessage);

			}.bind(this))

		});

		return promise;
	}



	emailVerification(): Promise<any> {

		this.loginStatus = LoginStatus.Doing;

		var promise = new Promise<any>((resolve, reject) => {


			const authUser = this.auth.auth.currentUser;

			if (authUser)
			{
				if (authUser.emailVerified)
				{
					this.loginStatus = LoginStatus.Done;
					resolve("verified");
				}
				else
				{
					this.loginMessage = 'Esperando verificación de email';

					authUser.sendEmailVerification().then(result => {

						resolve("success");

					}).catch(reason => {

						this.loginStatus = LoginStatus.Error;
						this.errorMessage = this.errorMessageWithCode("default");
						reject(this.errorMessage);
					});
				}
			}
			else
			{
				this.loginStatus = LoginStatus.Error;
				this.errorMessage = this.errorMessageWithCode("default");
				reject(this.errorMessage);
			}
		});

		return promise;
	}


	getEmailVerification(): Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			this.resolveEmailVerification(resolve);
		});

		return promise;
	}


	resolveEmailVerification(resolve: Function) {

		const authUser = this.auth.auth.currentUser;

		if (authUser) 
		{
			authUser.reload().then(function() {

				if (authUser.emailVerified)
				{
					this.loginStatus = LoginStatus.Done;
					resolve("success");
				}
				else
				{
					setTimeout(function () {
						this.resolveEmailVerification(resolve);
					}.bind(this), 5000);
				}

			}.bind(this));

		}
		else
		{
			setTimeout(function () {
				this.resolveEmailVerification(resolve);
			}.bind(this), 5000);
		}
	}



	socialLogin(provider: string, keepSession: boolean): Promise<any> {

		this.loginStatus = LoginStatus.Doing;

		var promise = new Promise<any>((resolve, reject) => {

			var authProvider;

			if (provider == 'google')
				authProvider = new firebase.auth.GoogleAuthProvider();
			else if (provider == 'facebook')
				authProvider = new firebase.auth.FacebookAuthProvider();
			else if (provider == 'twitter')
				authProvider = new firebase.auth.TwitterAuthProvider();


			if (authProvider)
			{
				this.auth.auth.signInWithPopup(authProvider).then(function(result) {

	  				this.getCurrentUser().then(function(user) {

	  					this.auth.auth.setPersistence(keepSession ? 'local' : 'none');

	  					this.loginStatus = LoginStatus.Done;
	  					resolve(user);

	  				}.bind(this));

				}.bind(this)).catch(function(error) {

					this.loginStatus = LoginStatus.Error;
					this.errorMessage = this.errorMessageWithCode(error.code);

					reject(this.errorMessage);

				}.bind(this))
			}
			else
			{
				reject();
			}
		});

		return promise;
	}



	authUserData() : Promise<AuthUserData> {

		var promise = new Promise<AuthUserData>((resolve, reject) => {

			const authUser = this.auth.auth.currentUser;

			if (authUser)
			{
				var authUserData : AuthUserData = {
					name: '', lastname: '', email: '', phone: ''
				};

				if (authUser.displayName)
				{
					var displayName = authUser.displayName.trim();

					if (displayName.indexOf(' ') > 0)
					{
						authUserData.name = displayName.substr(0, displayName.indexOf(' '));

						authUserData.lastname = displayName.substr(displayName.indexOf(' ')+1);
					}
					else
					{
						authUserData.name = displayName;
					}
				}

				authUserData.email = authUser.email;
				authUserData.phone = authUser.phoneNumber;

				resolve(authUserData);
			}
			else
			{
				resolve(null);
			}
		});

		return promise;
	}




	register(user: UserData): Promise<string> {
		console.log("hola33333")

		this.loginStatus = LoginStatus.Doing;

		var promise = new Promise<string>((resolve, reject) => {

			const authUser = this.auth.auth.currentUser;

			if (authUser) {
				console.log("hola44444")


				const _user = {id: authUser.uid, active: true,  ...user};
				
				if (authUser.photoURL) _user.profileImageUrl = authUser.photoURL;
				console.log("hola55", _user)

				this.usersService.set(_user, true).then(result => {
					console.log("hol66", _user)
					this.loginStatus = LoginStatus.Done;
					resolve("success");

				});				

			}
			else
			{
				console.log("555")

				this.loginStatus = LoginStatus.Error;

				this.errorMessage = this.errorMessageWithCode("default");
				reject(this.errorMessage);
			}
		});

		return promise;
	}



	sendPasswordResetEmail(email: string, settings?: any) : Promise<void> {

		return this.auth.auth.sendPasswordResetEmail(email, settings);
	}


	changePassword(newPassword: string, oldPassword: string) : Promise<void> {

		var promise = new Promise<void>((resolve, reject) => {

			const authUser = this.auth.auth.currentUser;

			const email = authUser.email;

			if (authUser) {

				this.auth.auth.signOut().then(() => {

					this.emailLogin(email, oldPassword, true).then(result => {

						authUser.updatePassword(newPassword).then(result => {

							this.emailLogin(email, newPassword, true);

							resolve(result);
						}).catch(reason => {
							
							this.emailLogin(email, oldPassword, true);

							reject(reason);
						});
					}).catch(reason => {

						reject(reason);
					});
				}).catch(reason => {

					console.log(reason);
					reject(reason);
				});
			}
			else
			{
				reject();
			}
		});

		return promise;
	}



	signOutFunction : Function;

	signOut(): void {

		this.auth.auth.signOut().then(function(response) {
			this.router.navigate(['/login']).finally(() => {

				this.location.replaceState('/login');
				this.getUser();
			});

		}.bind(this)).catch(function(error) {

		}.bind(this));
	}







	errorMessageWithCode(code: string) : string {

		var message : string = "Error al iniciar";

		switch (code) {
			case "auth/invalid-email":
				message = "El usuario ingresado no es válido";
				break;

			case "auth/user-disabled":
				message = "El usuario ha sido deshabilitado";
				break;

			case "auth/user-not-found":
				message = "El usuario no se encuentra registrado";
				break;

			case "auth/wrong-password":
				message = "Contraseña incorrecta";
				break;

			case "auth/email-already-in-use":
				message = "El usuario ya se encuentra registrado";
				break;

			case "auth/operation-not-allowed":
				message = "Operación no permitida";
				break;

			case "auth/weak-password":
				message = "La contraseña es demasiado débil";
				break;
			
			default:
				message = "Error al iniciar";
				break;
		}

		return message;
	}
}
