<!-- 
<div class="home" ngClass.xs="xs" fxLayout="column" fxLayoutAlign="space-between center">


	


	<div fxFlex class="content coming-soon" fxLayout="column" fxLayoutAlign="start center">

		<h1>¡Próximamente!</h1>

		<p>Estamos trabajando en esta sección, pronto podrás acceder a nuevas funcionalidades.</p>

	</div>


</div> -->
<!-- <app-navbar class="full-width"></app-navbar> -->
<img style="width: 100%;" [src]="'assets/images/comingsoon.png'">