<div *ngIf="!loading" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2em">

	<h4>{{user ? 'Editar' : 'Nuevo'}} cliente</h4>

	<div class="full-width" fxLayout="column" fxLayoutAlign="start start">

		<form class="full-width" [formGroup]="form" id="newCustomerForm" (ngSubmit)="save()" autocomplete="off" fxLayout="column" fxLayoutAlign="start center">

			<div class="full-width" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1em" fxLayout.xs="column">

				<mat-form-field class="primary force-prefix" appearance="outline" color="primary">
					<mat-label>NOMBRE</mat-label>
					<app-icon icon="company_primary" matPrefix></app-icon>
					<input matInput formControlName="name" maxlength="100" autocomplete="off" (keyup)="changeUser($event, 'name')">
					<mat-error *ngIf="form.get('name').hasError('required')">
						Nombre requerido
					</mat-error>
				</mat-form-field>

				<mat-form-field class="primary force-prefix" appearance="outline" color="primary">
					<mat-label>CÓDIGO DE CLIENTE</mat-label>
					<app-icon icon="company_primary" matPrefix></app-icon>
					<input matInput formControlName="code" maxlength="100" autocomplete="off">
					<mat-error *ngIf="form.get('code').hasError('required')">
						Código requerido
					</mat-error>
					<mat-error *ngIf="form.get('code').hasError('invalid')">
						Código inválido
					</mat-error>
				</mat-form-field>

			</div>

			<br/>

			<div class="full-width" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1em" fxLayout.xs="column">

				<mat-form-field class="primary force-prefix" appearance="outline" color="primary">
					<mat-label>EMAIL</mat-label>
					<app-icon icon="email_primary" matPrefix></app-icon>
					<input matInput formControlName="email" maxlength="100" autocomplete="off">
					<mat-error *ngIf="form.get('email').hasError('required')">
						Email requerido
					</mat-error>
					<mat-error *ngIf="form.get('email').hasError('email')">
						Email inválido
					</mat-error>
				</mat-form-field>

				<mat-form-field class="primary force-prefix" appearance="outline" color="primary">
					<mat-label>USUARIO</mat-label>
					<app-icon icon="user_primary" matPrefix></app-icon>
					<input matInput formControlName="username" maxlength="100" autocomplete="off" (keyup)="changeUser($event, 'username')">
					<mat-error *ngIf="form.get('username').hasError('required')">
						Usuario requerido
					</mat-error>
					<mat-error *ngIf="form.get('username').hasError('pattern')">
						Usuario inválido
					</mat-error>
				</mat-form-field>
			</div>


			<br *ngIf="!user" />

			<div *ngIf="!user" class="full-width" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1em" fxLayout.xs="column">

				<mat-form-field class="primary force-prefix" appearance="outline" color="primary">
					<mat-label>CONTRASEÑA</mat-label>
					<app-icon icon="password_primary" matPrefix></app-icon>
					<input matInput formControlName="password" maxlength="100" autocomplete="off">
					<mat-error *ngIf="form.get('password').hasError('required')">
						Contraseña requerida
					</mat-error>
				</mat-form-field>

				<mat-form-field class="primary force-prefix" appearance="outline" color="primary">
					<mat-label>REPETIR CONTRASEÑA</mat-label>
					<app-icon icon="password_primary" matPrefix></app-icon>
					<input matInput formControlName="repeat" maxlength="100" autocomplete="off">
					<mat-error *ngIf="form.get('repeat').hasError('required')">
						Contraseña requerida
					</mat-error>
					<mat-error *ngIf="form.get('repeat').hasError('notSame')">
						La contraseña no coincide
					</mat-error>
				</mat-form-field>
			</div>

			<br/><br/>

			<mat-spinner *ngIf="saving" color="primary" diameter="40"></mat-spinner>

			<button *ngIf="!saving" class="main-button full-width" mat-flat-button color="primary" form="newCustomerForm" type="submit">
				<img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">GUARDAR
			</button>

		</form>

	</div>

</div>


<div *ngIf="loading" class="spinner-container" fxLayout="column" fxLayoutAlign="center center">
	<mat-spinner color="primary" diameter="40"></mat-spinner>
</div>

