export class SerializeHelper {

    static isUUID(uuid: string) {
        const uuidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
        return uuidPattern.test(uuid);
    }

    static objectToQueryParams(originObject: any, originPrefix = ''): string {
        function subObjectToQueryParams(object: any, prefix = ''): string {
            const queryParams = [];

            for (const key in object) {
                if (object.hasOwnProperty(key)) {
                    const value = object[key];
                    const paramName = prefix ? `${prefix}[${key}]` : key;

                    if (Array.isArray(value)) {
                        for (let i = 0; i < value.length; i++) {
                            const arrayParamName = `${paramName}[${i}]`;
                            if (value[i] !== undefined && value[i] !== null) {
                                if (typeof value[i] === 'object' && !(value[i] instanceof Date)) {
                                    queryParams.push(subObjectToQueryParams(value[i], arrayParamName));
                                } else if (value[i] instanceof Date) {
                                    // Si es una fecha, conviértela a formato ISO string
                                    queryParams.push(`${arrayParamName}=${encodeURIComponent(value[i].toISOString())}`);
                                } else {
                                    queryParams.push(`${arrayParamName}=${encodeURIComponent(value[i])}`);
                                }
                            }
                        }
                    } else if (typeof value === 'object' && value !== null) {
                        queryParams.push(subObjectToQueryParams(value, paramName));
                    } else if (value !== undefined && value !== null) {
                        if (value instanceof Date) {
                            // Si es una fecha, conviértela a formato ISO string
                            queryParams.push(`${paramName}=${encodeURIComponent(value.toISOString())}`);
                        } else {
                            queryParams.push(`${paramName}=${encodeURIComponent(value)}`);
                        }
                    }
                }
            }

            const result = queryParams.join('&');
            return result;
        }

        const queryParams = subObjectToQueryParams(originObject, originPrefix);
        return queryParams ? '?' + queryParams : '';
    }

    static searchProperty(object: Object, property: string): any[] {
        let results: any[] = [];

        function searchInObject(obj: Object) {
            for (const key in obj) {
                if (key === property && obj[key] != null) {
                    results.push(obj[key]);
                }
                else if (typeof obj[key] === 'object') {
                    searchInObject(obj[key]);
                }
            }
        }

        searchInObject(object);
        return results;
    }

    static search(arr, obj, key, value) {
        if (Array.isArray(obj)) {
            
            for (const item of obj) {
                this.search(arr, item, key, value);
            }
        } else if (typeof obj === "object") {
            for (const keyItem in obj) {
                if (keyItem == key && obj[keyItem] == value) {
                    arr.push(obj);
                }
                this.search(arr, obj[keyItem], key, value);
            }
        } else {
            const str = JSON.stringify(obj);
            const exists = str.indexOf('6000037606') != -1;
            if (exists) console.log('str', str);
        }
    }

    static replaceProperty(element, property, newValue) {
        if (Array.isArray(element)) element.map((item) => this.replaceProperty(item, property, newValue));
        else if (typeof element === 'object') {
            for (const key in element) {
                if (key == property && typeof element[key] === 'object' && !Array.isArray(element[key])) element[property] = { ...element[property], ...newValue };
                else if (key == property) element[property] = newValue;
                else this.replaceProperty(element[key], property, newValue);
            }
        }
    }

    static changeProperty(object: any, propertyOld: string, propertyNew: string | string[], cast: Function = (prop: any) => prop) {
        if (Array.isArray(propertyNew)) {
            propertyNew.forEach((property) => {
                object[property] = this.castToArray(object[propertyOld], cast);
            });
        } else {
            object[propertyNew] = this.castToArray(object[propertyOld] ?? object[propertyNew], cast);
        }
        delete object[propertyOld];
    }

    static castToArray(value: any, cast: Function = (prop: any) => prop) {
        if (Array.isArray(value)) {
            return value.map((item) => {
                return cast(item);
            });
        } else {
            return cast(value);
        }
    }

    static String(prop: any) { return `${prop}`; };
    static Number(prop: any) { return Number(prop); };
}

