<mat-card class="custum-card">


	<!-- <app-navbar class="full-width" bgcolor="primary"></app-navbar> -->
	<h1>SEGUIMIENTO DE COMPRAS</h1>

	<div class="purchase-table">

		
			

			<!--<div class="actions-bar" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="1em"
				fxLayout.xs="column">


				<mat-form-field ngClass.gt-xs="primary" ngClass.xs="full-width" appearance="outline"
					[color]="xs ? 'default': 'primary'">
					<input class="clickable" #datepickerInput matInput readonly [matDatepicker]="dp"
						placeholder="Seleccionar mes" (click)="$event.stopPropagation(); dp.open(); setInputValue();"
						(focusout)="setInputValue()">
					<mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
					<mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
						(monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
					</mat-datepicker>
				</mat-form-field>


				<div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="1em"
					fxLayoutAlign.xs="space-evenly center">
					<button mat-flat-button
						[color]="filter == 'month' ? (xs ? 'default' : 'primary') : (xs ? 'primary' : 'default')"
						(click)="selectFilter('month')">
						<img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">Último mes
					</button>

					<button mat-flat-button
						[color]="filter == '8days' ? (xs ? 'default' : 'primary') : (xs ? 'primary' : 'default')"
						(click)="selectFilter('8days')">
						Últimos 8 días
					</button>
				</div>

			</div>-->



		<div class="" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="25px" fxLayout.xs="column" fxLayout.sm="column">
			<mat-form-field ngClass.gt-xs="primary" ngClass.xs="width-date" appearance="outline"
				[color]="xs ? 'default': 'primary'">
				<input class="clickable" [max]="maxDate" [min] = "minDate" #datepickerInput matInput readonly [matDatepicker]="dp"
					placeholder="Seleccionar fecha" (click)="$event.stopPropagation(); dp.open(); setInputValue();"
					(focusout)="setInputValue()">
				<button *ngIf="monthDate" matSuffix mat-icon-button aria-label="Clear" (click)="selectFilter('allYear')">
					<mat-icon>close</mat-icon>
				</button>
				<mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
				<mat-datepicker #dp startView="multi-year"  (yearSelected)="chosenYearHandler($event)"
					(monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
				</mat-datepicker>
			</mat-form-field>

			<div class="custom-group-2">
				<div class="custom-group-1">
					<div class="item">
						<button mat-button class="home-btn bg-red" [color]="filter == 'month' ? (xs ? 'white' : 'primary') : (xs ? 'primary' : 'white')" (click)="selectFilter('month')">
							<img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">
							<span>ULTIMO MES</span>
						</button>
					</div>
					<div class="item">
						<button mat-button class="home-btn" [color]="filter == '8days' ? (xs ? 'white' : 'primary') : (xs ? 'primary' : 'white')" (click)="activePurchases()">
							<img src="/assets/images/refresh-icon.svg" />
							<span>COMPRAS ACTIVAS</span>
						</button>
					</div>
				</div>
				<div class="custom-group-1">
					<div class="item">
						<mat-form-field>
							<mat-label>Sociedades</mat-label>
							<mat-select [(ngModel)]="society" >
								<mat-option *ngFor="let sociedad of companies;" [value]="sociedad.id" (click)="onSelectSociety(society)">{{sociedad.nombre}}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="item">
						<mat-form-field ngClass.gt-xs="primary" ngClass.xs="full-width" appearance="outline"
							[color]="xs ? 'default': 'primary'" style="width: 200px">
							<mat-label>BUSCAR</mat-label>
							<input matInput maxlength="100" autocomplete="off" #searchInput
								(keyup)="filterValue($event.target.value)">
							<app-icon [icon]="'search'+(!xs ? '_primary': '')" matSuffix></app-icon>
						</mat-form-field>
					</div>
				</div>
			</div>

		</div>

		<div class="custum-table" fxLayout="column">

			<br *ngIf="loading" />


			<p class="empty-text" *ngIf="!loading && dataSource.data?.length < 1">
				No hay compras registradas
			</p>

			<mat-spinner *ngIf="loading" fxFlexAlign="center" color="primary" diameter="40"></mat-spinner>


			<table [ngClass]="{'hidden': loading || dataSource.data?.length < 1}" mat-table matSort
				[dataSource]="dataSource" class="full-width" multiTemplateDataRows>

				<ng-container matColumnDef="pedido">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Pedido</th>
					<td mat-cell *matCellDef="let purchase">
						<app-element-skeleton width="40" margin="5" height="20" radius="30" [tag]="'loading-'+purchase.pedido">
							<a (click)="goToPurcharse($event, purchase)" >{{purchase.pedido}}</a>
						</app-element-skeleton>
					</td>
				</ng-container>

				<ng-container matColumnDef="orden_compra">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Orden de Compra</th>
					<td mat-cell *matCellDef="let purchase">
						<app-element-skeleton width="40" margin="5" height="20" [tag]="'loading-'+purchase.pedido">
							{{purchase.orden_compra}}
						</app-element-skeleton>
					</td>
				</ng-container>

				<ng-container matColumnDef="empresa_nombre">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Empresa</th>
					<td mat-cell *matCellDef="let purchase">
						<app-element-skeleton width="80" margin="5" height="20" [tag]="'loading-'+purchase.pedido">
							{{purchase.empresa_nombre}}
						</app-element-skeleton>
					</td>
				</ng-container>

				<ng-container matColumnDef="fecha">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
					<td mat-cell *matCellDef="let purchase">
						<app-element-skeleton width="50" margin="5" height="20" [tag]="'loading-'+purchase.pedido">
							{{purchase.fecha.split('T')[0]}}
						</app-element-skeleton>
					</td>
				</ng-container>

				<ng-container matColumnDef="status">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
					<td mat-cell *matCellDef="let purchase">
						<app-element-skeleton width="50" margin="5" height="20" [tag]="'loading-'+purchase.pedido">
							<span  [class]="currentStateColor(purchase.tracking)">
								{{currentState(purchase.tracking)}}
							</span>
						</app-element-skeleton>

					</td>
				</ng-container>

				<ng-container matColumnDef="next">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Próximo estado</th>
					<td mat-cell *matCellDef="let purchase">
						<app-element-skeleton width="50" margin="5" height="20" [tag]="'loading-'+purchase.pedido">
							<span  [class]="nextStateColor(purchase.tracking)">
								{{nextState(purchase.tracking)}}
							</span>
						</app-element-skeleton>
					</td>
				</ng-container>


				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let purchase" class="last-column">

						<app-element-skeleton width="40" margin="5" height="20" radius="30" [tag]="'loading-'+purchase.pedido">
							<app-icon (click)="goToPurcharse($event, purchase)" icon="search_primary" size="16" class="clickable"></app-icon>
						</app-element-skeleton>
					</td>
				</ng-container>



				<ng-container matColumnDef="expanded">
					<td mat-cell *matCellDef="let purchase" [attr.colspan]="displayedColumns.length">
						<div class="element-detail" [@expand]="purchase == expandedElement ? 'expanded' : 'collapsed'"
							fxLayout="column">

							<br />

							<p><strong>Empresa: </strong>{{purchase.empresa_nombre}}</p>
							<p><strong>Fecha: </strong>{{purchase.fecha.split('T')[0]}}</p>
							<p><strong>Estado: </strong>{{purchaseStatus(purchase).current}}</p>
							<p><strong>Próximo estado: </strong>{{purchaseStatus(purchase).next}}</p>

						</div>
					</td>
				</ng-container>



				<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row *matRowDef="let purchase; columns: displayedColumns;" class="element-row"
					[class.expanded-row]="expandedElement === purchase"
					(click)="expandedElement = expandedElement === purchase || !xs ? null : purchase"></tr>
				<tr mat-row *matRowDef="let purchase; columns: ['expanded']" class="detail-row"></tr>

			</table>


			<mat-paginator fxFlexAlign="end" [ngClass]="{'hidden': loading || dataSource.data?.length < 1}"
				[pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="pageSize" [pageIndex]="page"></mat-paginator>

		</div>

	</div>


	
</mat-card>