import { Injectable } from '@angular/core';
import { DialogComponent, DialogData } from '../dialog/dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

	constructor(private dialog: MatDialog) {

	}

	isOpened : boolean;

	data : any;

	init(viewContainerRef: any) {

	}

	openDialog(data: DialogData) {

		const dialogRef = this.dialog.open(DialogComponent, {
			data: data,
			autoFocus: false,
			restoreFocus: false,
			maxHeight: '80vh'
		});
	}

	closeDialog() {
		this.dialog.openDialogs[this.dialog.openDialogs.length - 1].close();
	}

	closeAll() {
		this.dialog.closeAll();
	}
}
