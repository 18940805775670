import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Purchase, CommercialPurchase } from '../classes/purchase';
import { Company } from '../classes/company';
import { Shopping } from '../classes/shopping';
import { TrackingResponse } from '../classes/tracking';
import { AuthService } from './auth.service';
import { DatesService } from './dates.service';
import { environment } from 'src/environments/environment';
import { DocGCInterface, OrderInterface } from '../classes/document-logistic';
import { Account } from '../classes/account';
import { CollectionGoalInterface } from '../datasource/sap/v1/collection-goal/domain/interface/collection-goal.interface';
import { ResponseInterface } from '../datasource/api/domain/interface/response.interface';


@Injectable({
	providedIn: 'root'
})
export class ApiService {

	token: string = environment.tokenBass;

	customer: string;

	constructor(private http: HttpClient,
		private datesService: DatesService,
		private authService: AuthService) {

		this.authService.userBS.subscribe(user => {

			if (user) this.customer = user.code;
		});
	}


	get header(): any {

		const header = {
			headers: new HttpHeaders().set('Authorization', 'Token ' + this.token)
		};

		return header;
	}

	getUrl(server: string): any {
		console.log('server server', server)
		const url = `${environment[server].protocol}://${environment[server].ip}/${environment[server].version}`;
		return url;
	}


	purchases(year, month, filter): Promise<Purchase[]> {

		var date_i = this.begin(year, month)
		var date_f = this.end(year, month)

		if (filter == '8days') {
			date_i = this.hoyEightDay()
			date_f = this.hoy()
		}

		if (filter == 'allYear') {
			date_i = this.initYear()
			date_f = this.hoy()
		}

		var promise = new Promise<Purchase[]>((resolve, reject) => {

			this.company(this.authService.user.code).then(company => {

				var url = this.getUrl('baasBaasServer') + '/pedidos/tracking/finaciero/?inicio=' + date_i + '&final=' + date_f + '&empresas=' + this.companiesParam(company);
				this.http.get<any>(url, this.header).subscribe((json: any) => {

					if (json.error) {
						reject();
					}
					else {
						resolve(json);
					}
				},
					(error: any) => {

						reject(error);
					});

			}).catch(reason => {

				reject();
			});
		});

		return promise;
	}

	async purchasesEstadoCuenta(clientCode?: string): Promise<{ pedidos: Purchase[], oc: "" }> {
		const company = await this.company(this.authService.user.code);
		const entidad = company['codigo_entidad'];
		 
		if(!clientCode) clientCode = this.authService.user.code;
		var url = this.getUrl('sapApiServer') + `/collection_goal/recalculate?f[0][clientCode]=${clientCode}&f[0][entityCode]=${entidad}&f[0][audatDate]=${new Date().toISOString()}`;
		const response = await this.http.get<ResponseInterface<CollectionGoalInterface[]>>(url, { headers: this.header }).toPromise();
		const collectionGoalDocuments = response.data;
		const filteredDocuments = collectionGoalDocuments.filter((document) => document.documentType == "RV");
		const documents = filteredDocuments.map((document) => {
			const ncNumbers = document.collectionGoalList.filter((document) => document.documentType == 'NC').map((document) => document.sapNumber);
			const purchase: Purchase = {
				row_id: 0,
				nombre_sociedad: document.documentEntityCode,
				nombre_fiscal: document.clientName,
				factura_numero: document.fiscalNumber,
				factura_sap: document.sapNumber,
				factura_fecha: document.documentDate,
				factura_vencimiento: document.dueDate,
				dias_atraso: document.creditDays,
				pedido: document.orderNumber,
				factura_monto: Math.abs(document.nativeTotal),
				pagos_realizados: Math.abs(document.nativePayment),
				saldo_pendiente: Math.abs(document.nativeBalance) * -1,
				ncNumbers,
				codigo_cliente: String(Number(document.clientCode)),
				en_cxc: '',
				sociedad: '',
				tipo_documento: document.documentType,
				orderStatus: '',
				oc: '',
				documents: document.collectionGoalList
			}
			return purchase;
		});
		return { pedidos: documents, oc: "" };
	}

	purchasesEstadoCuentaOC(dateStart: Date, dateEnd: Date): Promise<Purchase[]> {
		const dateStartString = dateStart.toISOString().split('T')[0];
		const dateEndString = dateEnd.toISOString().split('T')[0];

		var promise = new Promise<Purchase[]>((resolve, reject) => {

			this.company(this.authService.user.code).then(company => {
				var url = this.getUrl('baasBaasServer') + '/pedidos/tracking/estado_cuenta/?inicio=' + dateStartString + '&final=' + dateEndString + '&empresas=' + this.companiesParam(company);
				this.http.get<any>(url, this.header).subscribe((json: any) => {

					if (json.error) {
						reject();
					}
					else {
						resolve(json);
					}
				},
					(error: any) => {
						reject(error);
					});

			}).catch(reason => {
				reject();
			});
		});

		return promise;
	}

	accountStatement(filter: any): Promise<Account> {
		var promise = new Promise<Account>((resolve, reject) => {

			this.company(this.authService.user.code).then(company => {
				var url = this.getUrl('sapApiServerV3') + '/collection/account-statement' + filter;
				this.http.get<any>(url, this.header).subscribe((json: any) => {

					if (json.error) {
						reject();
					}
					else {
						resolve(json);
					}
				},
					(error: any) => {
						reject(error);
					});

			}).catch(reason => {
				reject();
			});
		});

		return promise;
	}


	commercial(year, month, filter, clientCode?: string): Promise<CommercialPurchase[]> {

		var date_i = this.begin(year, month)
		var date_f = this.end(year, month)

		if (filter == '8days') {
			date_i = this.hoyEightDay()
			date_f = this.hoy()
		}

		if (filter == 'allYear') {
			date_i = this.initYear()
			date_f = this.hoy()
		}

		if (filter == '30days') {
			const current = new Date();
			const days30Ms = 1000 * 60 * 60 * 24 * 30;
			const days30Date = new Date(current.getTime() - days30Ms);
			date_i = this.stringDate(days30Date);
			date_f = this.stringDate(current);
		}

		var promise = new Promise<CommercialPurchase[]>((resolve, reject) => {
			if(!clientCode)clientCode=this.authService.user.code;
			console.log("🚀 ~ ApiService ~ promise ~ clientCode:", clientCode)
			this.company(clientCode).then(company => {

				var url = this.getUrl('baasBaasServer') + '/pedidos/tracking/comercial/?inicio=' + date_i + '&final=' + date_f + '&empresas=' + this.companiesParam(company);

				this.http.get<any>(url, this.header).subscribe((json: any) => {

					if (json.error) {
						reject();
					}
					else {
						resolve(json.pedidos);
					}
				},
					(error: any) => {

						reject(error);
					});

			}).catch(reason => {

				reject(reason);
			});
		});

		return promise;
	}



	tracking(order: string): Promise<TrackingResponse> {

		var promise = new Promise<TrackingResponse>((resolve, reject) => {
			var url: string = this.getUrl('baasRestApiServer') + '/sales/trackingportal/?pedido=' + order;

			this.http.get<any>(url, this.header).subscribe((json: any) => {
				resolve(json);
			},
				(error: any) => {
					reject(error);
				});
		});

		return promise;
	}
	documentLogistic(order: string): Promise<OrderInterface | undefined> {

		var promise = new Promise<OrderInterface>((resolve, reject) => {
			var url: string = this.getUrl('sapApiServerV4') + '/document-logistic?orderNumber=' + order;

			this.http.get<any>(url, this.header).subscribe((json: any) => {
				if (json.statusCode != 200) {
					reject(json.message);
					return;
				}

				resolve(json.data);
			},
				(error: any) => {
					error.message = error?.error?.message ?? error?.message;
					reject(error);
				});
		});

		return promise;
	}
	documentFlow(order: string): Promise<OrderInterface | undefined> {

		var promise = new Promise<OrderInterface>((resolve, reject) => {
			var url: string = this.getUrl('sapApiServerV4') + '/document-flow?orderNumber=' + order;

			this.http.get<any>(url, this.header).subscribe((json: any) => {
				if (json.statusCode != 200) {
					reject(json.message);
					return;
				}

				resolve(json.data);
			},
				(error: any) => {
					error.message = error?.error?.message ?? error?.message;
					reject(error);
				});
		});

		return promise;
	}

	findClientById(id: number): Promise<{ codigo: string, nombreFiscal: string, id: number, moneda: string }> {

		var promise = new Promise<{ codigo: string, nombreFiscal: string, id: number, moneda: string }>((resolve, reject) => {
			var url: string = this.getUrl('gcServer') + '/collection/clients/' + id;

			this.http.get<any>(url, this.header).subscribe((json: any) => {
				resolve(json);
			},
				(error: any) => {

					reject(error);
				});
		});

		return promise;
	}

	documentLogisticGC(order: string): Promise<DocGCInterface[] | undefined> {

		var promise = new Promise<DocGCInterface[]>((resolve, reject) => {
			var url: string = this.getUrl('gcServer') + '/successful-delivery?order=' + order;

			this.http.get<any>(url, this.header).subscribe((json: any) => {
				if (json.statusCode != 200) {
					reject(json.message);
					return;
				}

				resolve(json.data[0].attachments);
			},
				(error: any) => {
					error.message = error?.error?.message ?? error?.message;
					reject(error);
				});
		});

		return promise;
	}



	company(code: string): Promise<Company> {

		var promise = new Promise<Company>((resolve, reject) => {

			var url: string = this.getUrl('baasBaasServer') + '/cliente/detalle/?codigo=' + code + "&user=" + this.authService.user.username;

			this.http.get<any>(url, this.header).subscribe((json: any) => {
			console.log("🚀 ~ ApiService ~ this.http.get<any> ~ json:", json)


				if (json.message) {
					reject(new Error(json.message));
				}
				else if (json.data && json.data.length > 0) {
					resolve(json.data[0]);
				}
				else {
					reject(new Error('Ocurrio un error'));
				}
			},
				(error: any) => {
				console.log("🚀 ~ ApiService ~ promise ~ error:", error)

					reject(error);
				});
		});

		return promise;
	}


	companyAnonymous(code: string): Promise<Company> {

		var promise = new Promise<Company>((resolve, reject) => {

			var url: string = this.getUrl('baasBaasServer') + '/cliente/detalle/?codigo=' + code;

			this.http.get<any>(url, this.header).subscribe((json: any) => {


				if (json.message) {
					reject();
				}
				else if (json.data && json.data.length > 0) {
					resolve(json.data[0]);
				}
				else {
					reject();
				}
			},
				(error: any) => {

					reject(error);
				});
		});

		return promise;
	}

	companyInformation(code: string): Promise<Company> {

		var promise = new Promise<Company>((resolve, reject) => {

			var url: string = this.getUrl('baasBaasServer') + '/cliente/informacion/?empresa=' + code;

			this.http.get<any>(url, this.header).subscribe((json: any) => {


				if (json.message) {
					reject();
				}
				else if (json.data) {
					resolve(json.data);
				}
				else {
					reject();
				}
			},
				(error: any) => {

					reject(error);
				});
		});

		return promise;
	}


	shopping(code?: string): Promise<Shopping> {

		if (code) {
			return this._shopping(code);
		}
		else {
			var promise = new Promise<Shopping>((resolve, reject) => {

				this.company(this.authService.user.code).then(company => {

					if (company) {
						this._shopping(company.id).then(shopping => {

							resolve(shopping);

						}).catch(reason => {
							reject();
						});
					}
					else {
						reject();
					}

				}).catch(reason => {

					reject();
				});
			});

			return promise;
		}
	}

	_shopping(code: string): Promise<Shopping> {

		var promise = new Promise<Shopping>((resolve, reject) => {


			var url: string = this.getUrl('gescomBaasServer') + '/cliente/informacion/?empresa=' + code;

			this.http.get<any>(url, this.header).subscribe((json: any) => {

				console.log(json);


				if (json.error) {
					reject();
				}
				else if (json.data) {
					resolve(json.data);
				}
				else {
					reject();
				}
			},
				(error: any) => {

					console.log(error);

					reject(error);
				});
		});

		return promise;
	}


	begin(year, month): string {

		var date = new Date();

		date.setFullYear(year);
		date.setMonth(month - 1);

		const begin = this.stringDate(this.datesService.getFirstMonthDay(date));

		return begin;
	}

	end(year, month): string {

		var date = new Date();

		date.setFullYear(year);
		date.setMonth(month - 1);

		const end = this.stringDate(this.datesService.getLastMonthDay(date));

		return end;
	}

	hoy(): string {

		var date = new Date();
		const hoy = this.stringDate(date);

		return hoy;
	}

	hoyEightDay(): string {

		var date = new Date();
		var semanaEnMilisegundos = 1000 * 60 * 60 * 24 * 8;

		let resta = date.getTime() - semanaEnMilisegundos;
		let hoy_eight_day = new Date(resta);
		const hoy_eight_day_str = this.stringDate(hoy_eight_day);
		console.log("hoy_eight_day_str", hoy_eight_day)
		console.log("hoy_eight_day_str", hoy_eight_day_str)

		return hoy_eight_day_str;
	}

	initYear(): string {
		var hoy = new Date((new Date()).getFullYear(), 0, 1);
		const date = this.stringDate(hoy);

		return date;
	}

	companiesParam(company: Company): string {

		var companies = '';

		if (this.authService.user.admin || this.authService.user.role == 'admin') {
			companies += company.id;

			for (var _company of company.subempresas) {
				companies += ',%20' + _company.id;
			}
		}
		else {
			for (var _companyID of this.authService.user.companies || []) {
				companies += (companies == '' ? _companyID : ',%20' + _companyID);
			}
		}

		return companies;
	}

	dataDashboard(typeDashboard, fechaI, fechaF, year, yearMonth, month, yearOders, clientCode?: string): Promise<Purchase[]> {

		var promise = new Promise<Purchase[]>((resolve, reject) => {
			if(!clientCode) clientCode = this.authService.user.code
			var url = this.getUrl('gcServer') + '/customer-portal/dasboard?typeDashboard=' + typeDashboard + '&customerCode=' + clientCode + '&dateI=' + fechaI + '&dateF=' + fechaF + '&year=' + year + '&month=' + month + "&yearMonth=" + yearMonth + "&yearOders=" + yearOders
			this.http.get<any>(url, this.header).subscribe((json: any) => {

				if (json.error) {
					reject();
				}
				else {
					resolve(json.data);
				}
			},
				(error: any) => {

					reject(error);
				});
		});

		return promise;
	}



	stringDate(date: Date): string {

		return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
	}
}