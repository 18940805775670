import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { DialogData } from "src/app/dialog/dialog.component";

@Component({
    selector: 'question-support-invoices',
    templateUrl: 'question-support-invoices.html',
})
export class QuestionSupportInvoicesDialog {
    email: string = '';
    constructor(
        public dialogRef: MatDialogRef<QuestionSupportInvoicesDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) { 
        this.email = (this.data as any).email;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}