import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ApiService } from '../../services/api.service';
import { DialogService } from '../../services/dialog.service';
import { SessionService } from '../../services/session.service';
import { DatesService } from '../../services/dates.service';
import { AuthService } from '../../services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatInput } from '@angular/material/input';
import { MediaObserver } from '@angular/flex-layout';
import { CommercialPurchase, Purchase } from '../../classes/purchase';
import { Subscription, BehaviorSubject } from 'rxjs';
import { MatDatepicker } from '@angular/material/datepicker';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Page } from '../../adapters/page';
import { Router } from '@angular/router';
import { StateSharedService } from 'src/app/services/state-shared.service';
import { CursorError } from '@angular/compiler/src/ml_parser/lexer';
import { DataSource } from '@angular/cdk/collections';
import { Company } from 'src/app/classes/company';
import { SnackbarService } from 'src/app/services/snackbar.service';
@Component({
	selector: 'app-purchases',
	templateUrl: './purchases.component.html',
	styleUrls: ['./purchases.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	animations: [
		trigger('expand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],
})
export class PurchasesComponent implements OnInit {

	year: number = (new Date()).getFullYear();

	loading: boolean = true;

	displayedColumns: string[];
	dataSource: MatTableDataSource<CommercialPurchase> = new MatTableDataSource([]);
	societies: any[] = []
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	mediaSubscription: Subscription;


	expandedElement: CommercialPurchase;


	@ViewChild('dp', { static: true }) datepicker: MatDatepicker<Date>;
	@ViewChild('datepickerInput', { static: true }) datepickerInput: ElementRef;

	page: number = 0;
	pageSize: number = 10;

	maxDate: Date = new Date();
	minDate: Date = new Date((new Date()).getFullYear() - 10, 0, 1)

	monthDate: Date = new Date();
	society: string

	filter: 'month' | '8days' | '30days' = 'month';
	isFilterField: boolean = false;


	constructor(public api: ApiService,
		private dialogService: DialogService,
		public authService: AuthService,
		public datesService: DatesService,
		private changeDetectorRef: ChangeDetectorRef,
		public media: MediaObserver,
		private sessionService: SessionService,
		private _page: Page,
		private router: Router,
		private stateSharedService: StateSharedService<any>,
		private snackbar: SnackbarService,) {

		this._page.actionBarHidden = true;

		this.loading = true;

		this.updateColumns();

		this.mediaSubscription = this.media.asObservable().subscribe(change => {
			this.updateColumns();
		});

		if (!this.changeDetectorRef['destroyed'])
			this.changeDetectorRef.markForCheck();
	}

	get xs(): boolean {
		return this.media.isActive('xs');
	}


	updateColumns() {

		if (this.media.isActive('gt-xs')) {
			this.displayedColumns = ['pedido', 'orden_compra', 'empresa_nombre', 'fecha', 'status', 'next', 'actions'];
		}
		else {
			this.displayedColumns = ['pedido', 'actions'];
		}
	}


	loadData(filter) {

		this.updateColumns();

		this.api.commercial(filter == 'allYear' ? 0 : this.monthDate.getFullYear(), filter == 'allYear' ? 0 : (this.monthDate.getMonth() + 1), filter, this.companyCode).then(result => {

			if (result) {
				// let lastDate : Date;

				// for (var purchase of result)
				// {
				// 	const purchaseDate = this.stringToDate(purchase.fecha);

				// 	// if (lastDate == null || lastDate.getTime() < purchaseDate.getTime())
				// 	// {
				// 	// 	lastDate = purchaseDate;
				// 	// }
				// }


				this.dataSource.data = result.filter(purchase => {

					if (filter === 'month') {
						const thirtyDaysAgo = new Date();
						thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
						const purchaseDate = this.stringToDate(purchase.fecha);
						return [purchaseDate >= thirtyDaysAgo];
					}
					if (filter === 'allYear') {
						return result
					}
					// if (lastDate)
					// {
					// 	const purchaseDate = this.stringToDate(purchase.fecha);

					// 	return this.datesService.daysBetween(purchaseDate, lastDate) < 8;
					// }
					return true;
				}).map((data) => { data['tracking'] = []; return data; });
				this.loading = false;

				this.changeDetectorRef.markForCheck();
				this.changeDetectorRef.detectChanges();
			}
			this.filterSociety()

		}).catch(reason => {

			this.loading = false;

			this.changeDetectorRef.markForCheck();
			this.changeDetectorRef.detectChanges();
			this.snackbar.show(reason?.message ?? 'No se pudo capturar el error. Contacte a soporte.');
		});
	}

	stringToDate(string: string): Date {

		var _string: string = string;

		if (string.indexOf('T') >= 0) {
			_string = string.split('T')[0];
		}

		const doo = new Date(_string);

		return new Date(doo.getTime() - doo.getTimezoneOffset() * -60000);
	}

	ngAfterViewInit() {
		this.stateSharedService.get('filter').subscribe((result) => {
			if (result) {
				this.monthDate = result?.month ?? new Date();

				setTimeout(() => {
					// Inicializar el paginador en la página deseada (por ejemplo, página 2)
					this.paginator.pageIndex = result.page;
				});

				this.setInputValue();
				this.chosenYearHandler(this.monthDate);
				this.loadData("");
			} else {
				this.loadData("");
				this.setInputValue();
			}
			this.stateSharedService.destroy('filter');
		});
	}

	ngOnInit() {
		if (this.paginator) {
			this.paginator._intl.itemsPerPageLabel = "Compras por página:";
			this.paginator._intl.firstPageLabel = "Primera página";
			this.paginator._intl.previousPageLabel = "Página anterior";
			this.paginator._intl.nextPageLabel = "Página siguiente";
			this.paginator._intl.lastPageLabel = "Última página";

			this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {

				if (length == 0 || pageSize == 0) {
					return `0 de ${length}`;
				}

				length = Math.max(length, 0);
				const startIndex = page * pageSize;
				const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
				return `${startIndex + 1} – ${endIndex} de ${length}`;
			};

			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		}


		// const monthItem = this.sessionService.getItem('monthDate');

		// if (monthItem)
		// 	this.monthDate.setTime(JSON.parse(monthItem).date);


		// this.filter = (this.sessionService.getItem('filter') as 'month' | '8days') || 'month';

		this.dataSource.connect().subscribe((dataVisible) => {
			console.log("🚀 ~ this.dataSource.connect ~ this.isFilterField:", this.isFilterField)
			if (this.isFilterField == true) return;
			dataVisible.forEach((purcharse, index) => {
				if (purcharse && purcharse['tracking'] && purcharse['tracking'].length == 0) {
					this.stateSharedService.set(`loading-${purcharse.pedido}`, true);
					this.api.documentFlow(purcharse.pedido).then((order) => {
						const orderFind = this.dataSource.data.findIndex((orderFind) => orderFind.pedido == purcharse.pedido);
						this.dataSource.data[orderFind]['tracking'] = (order && order[0] && order[0].tracking) ?? [];
						this.stateSharedService.set(`loading-${purcharse.pedido}`, false);
					});
				}
			});
		})

		this.loadCompanies();
	}

	currentState(tracking: any[]) {
		const size = (tracking && tracking.length) ?? -1;
		for (let index = size; index >= 0; index--) {
			const track = tracking[index];
			if (track && track.isComplete == true) return track.name ?? '';
		}

		return '';
	}

	nextState(tracking: any[]) {
		const size = (tracking && tracking.length) ?? -1;
		for (let index = 0; index <= size; index++) {
			const track = tracking[index];
			if (track && track.isComplete == false) {
				const trackLast = tracking[tracking.length - 1];
				if (trackLast && trackLast.isComplete == true) return '';
				return track.name ?? '';
			}
		}

		return '';
	}

	currentStateColor(tracking: any[]) {
		const size = (tracking && tracking.length) ?? -1;
		for (let index = size; index >= 0; index--) {
			const track = tracking[index];
			if (track && track.isComplete == true) return [track.class];
		}

		return (tracking && tracking[0]) ?? 'Cargando...';
	}

	nextStateColor(tracking: any[]) {
		const size = (tracking && tracking.length) ?? -1;
		for (let index = 0; index <= size; index++) {
			const track = tracking[index];
			if (track && track.isComplete == false) {
				const trackLast = tracking[tracking.length - 1];
				if (trackLast && trackLast.isComplete == true) return '';
				return [track.class];
			}
		}

		return '';
	}

	ngOnDestroy() {
		if (this.mediaSubscription) this.mediaSubscription.unsubscribe();
	}

	trackByFn(index, item: CommercialPurchase) {
		return item.id;
	}

	async filterSociety() {
		const getSocieties = this.dataSource.filteredData.map(names => names.empresa_nombre)
		const filterData = await this.uniqBySociety(getSocieties)
		return filterData

	}
	async uniqBySociety(array) {
		for (const item of array) {
			if (!this.societies.includes(item)) {
				this.societies.push(item);
			}
		}
		return this.societies;
	}
	async filterbySocieties(param) {
		const data = this.dataSource.filteredData.filter(sociedad => sociedad.empresa_nombre == param)
		this.dataSource.data = data;

	}
	companies: Company[] = [];
	companyCode!: string;
	loadCompanies() {

		this.api.company(this.authService.user.code).then(company => {
			this.companyCode = this.authService.user.code;
			if (company) {
				this.companies.push(company);

				for (var subcompany of (company.subempresas || [])) {
					if (this.authService.user.companies.includes(subcompany.id))
						this.companies.push(subcompany);
				}
			}

			this.companies.push({
				id: '0',
				nombre: 'Todas',
				region: ''
			})
		})
	}

	async onSelectSociety(company: Company) {
		this.isFilterField = false;
		if (company.id != '0') {
			const client = await this.api.findClientById(Number(company.id));
			this.companyCode = client.codigo;
		} else {
			this.companyCode = undefined;
		}

		if(!this.monthDate) this.monthDate = new Date(); 
		this.api.commercial(this.monthDate.getFullYear(), this.monthDate.getMonth(), 'allYear', this.companyCode).then(async response => {
			if (response) {
				this.loading = false;

				const ordersActive = response.filter((state) => state.estados.entregado === false).map((data) => { data['tracking'] = []; return data; })
				console.log("🚀 ~ file: purchases.component.ts:464 ~ this.api.commercial ~ ordersActive:", ordersActive)
				const ordersSkeleton = ordersActive.map((order) => {
					this.stateSharedService.set(`loading-${order.pedido}`, true);
					order.tracking = [{
						isComplete: false,
					}];
					return order;
				});
				this.dataSource.data = ordersSkeleton;
				const dataActive = [];
				ordersActive.map(async (order) => {
					const orderSap = (await this.api.documentFlow(order.pedido))[0];
					order['tracking'] = orderSap.tracking;
					dataActive.push(order);
					this.dataSource.data = dataActive;
					this.stateSharedService.set(`loading-${order.pedido}`, false);
					return order;
				})


			}
			this.loading = false;
			this.changeDetectorRef.markForCheck();
			this.changeDetectorRef.detectChanges();
		}).catch((error) => {
			this.snackbar.show(error?.message ?? 'No se pudo capturar el error. Contacte a soporte.');
		})
	}

	chosenYearHandler(yearDate: Date) {
		this.isFilterField = false;
		if (this.monthDate == null) {
			var day = new Date();
			this.monthDate = new Date(yearDate.getFullYear(), day.getMonth(), day.getDate());
		} else {
			this.monthDate.setFullYear(yearDate.getFullYear());
		}

	}

	chosenMonthHandler(monthDate: Date, datepicker?: MatDatepicker<Date>) {
		this.isFilterField = false;
		this.monthDate.setMonth(monthDate.getMonth());
		this.monthDate.setFullYear(monthDate.getFullYear());

		this.sessionService.setItem('monthDate', JSON.stringify({ date: this.monthDate.getTime() }));

		if (datepicker) {
			datepicker.close();
			datepicker.select(this.monthDate);
		}

		this.setInputValue();

		this.loading = true;

		this.changeDetectorRef.markForCheck();
		this.changeDetectorRef.detectChanges();

		this.loadData("");
	}


	setInputValue() {
		this.isFilterField = false;
		let newValue = ''

		if (this.monthDate != null) {
			newValue = this.datesService.monthName(this.monthDate) + ' ' + this.monthDate.getFullYear();
		}

		if (this.datepickerInput) {
			const input = (this.datepickerInput.nativeElement as MatInput);
			input.value = newValue;
		}
	}


	selectFilter(filter: 'month' | '8days' | 'allYear') {
		this.isFilterField = false;
		if (this.loading) return;

		//this.filter = filter;

		//this.sessionService.setItem('filter', this.filter);

		this.loading = true;

		this.changeDetectorRef.markForCheck();

		if (filter == '8days') {
			this.monthDate = new Date();
		} else if (filter == 'month') {
			var day = new Date();
			// this.monthDate = new Date(day.getFullYear(),day.getMonth() == 0 ? 11 : (day.getMonth() - 1), day.getDate());
			this.monthDate = new Date(day.getFullYear(), day.getMonth(), day.getDay())
		} else {
			this.monthDate = null;
		}

		if (this.datepicker) {
			this.datepicker.select(this.monthDate);
			this.setInputValue();
		}

		setTimeout(() => {
			this.loadData(filter);
		});
	}
	activePurchases() {
		this.isFilterField = false;
		this.loading = true
		let currentYear = new Date()
		if (!this.monthDate) {
			this.monthDate = currentYear
		}
		this.api.commercial(this.monthDate.getFullYear(), this.monthDate.getMonth(), 'allYear', this.companyCode).then(async response => {
			if (response) {
				this.loading = false;

				const ordersActive = response.filter((state) => state.estados.entregado === false).map((data) => { data['tracking'] = []; return data; })
				console.log("🚀 ~ file: purchases.component.ts:464 ~ this.api.commercial ~ ordersActive:", ordersActive)
				const ordersSkeleton = ordersActive.map((order) => {
					this.stateSharedService.set(`loading-${order.pedido}`, true);
					order.tracking = [{
						isComplete: false,
					}];
					return order;
				});
				this.dataSource.data = ordersSkeleton;
				const dataActive = [];
				ordersActive.map(async (order) => {
					const orderSap = (await this.api.documentFlow(order.pedido))[0];
					order['tracking'] = orderSap.tracking;
					dataActive.push(order);
					this.dataSource.data = dataActive;
					this.stateSharedService.set(`loading-${order.pedido}`, false);
					return order;
				})


			}
			this.loading = false;
			this.changeDetectorRef.markForCheck();
			this.changeDetectorRef.detectChanges();
		}).catch((error) => {
			this.snackbar.show(error?.message ?? 'No se pudo capturar el error. Contacte a soporte.');
		})

	}

	loadingLocal = false;

	async goToPurcharse(event: MouseEvent, orderItem: Purchase) {
		const openNewTab = event.ctrlKey || event.metaKey;

		// Verificar si se hizo clic con el botón central (scroll) para abrir en nueva pestaña
		const openNewTabWithCenter = event.button === 1;

		// Si se cumple alguna de las condiciones anteriores, abrir en nueva pestaña
		if (openNewTab || openNewTabWithCenter) {
			this.stateSharedService.set(`loading-${orderItem.pedido}`, true);
			await this.api.documentFlow(String(orderItem.pedido));
			this.stateSharedService.set(`loading-${orderItem.pedido}`, false);
			this.stateSharedService.set(`filter`, {
				month: this.monthDate,
				page: this.page,
			});

			const url = location.origin + '/purchases/' + orderItem.pedido;
			window.open(url, '_blank');
		} else {
			this.stateSharedService.set(`loading-${orderItem.pedido}`, true);
			await this.api.documentFlow(String(orderItem.pedido));
			this.stateSharedService.set(`loading-${orderItem.pedido}`, false);
			this.stateSharedService.set(`filter`, {
				month: this.monthDate,
				page: this.page,
			});

			this.router.navigate(['/purchases/' + orderItem.pedido]);
		}
	}


	purchaseStatus(purchase: CommercialPurchase): { current: string, next: string } {

		const values: string[] = ['Confirmado', 'Programado', 'Cargado', 'En ruta', 'Entregado', '-'];

		var index = 0;

		if (purchase.estados.confirmado)
			index = 0;

		if (purchase.estados.programado)
			index = 1;

		if (purchase.estados.cargado)
			index = 2;

		if (purchase.estados.ruta)
			index = 3;

		if (purchase.estados.entregado)
			index = 4;


		return {
			current: values[index],
			next: values[index + 1]
		};

	}

	filterValue(value: string | null) {
		this.isFilterField = true;
		this.dataSource.filterPredicate = (data, filter) => {
			return JSON.stringify(data).trim().toLowerCase().indexOf(filter.trim().toLowerCase()) != -1;
		}
		this.dataSource.filter = value;
	}
}
